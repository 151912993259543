import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const CRHeading = () => {
    const { t } = useTranslation();

    return (
        <>
            <Suspense fallback={<Loader />}>
                <div className="section-title" align="center">
                    <h2>{t("crHead")}</h2>
                </div>
            </Suspense>
        </>
    );
};

export default CRHeading;
