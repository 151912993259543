import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import axios from "axios";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment
} from "@mui/material";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import { makeStyles } from "@mui/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ViewActivityFormCRSPL from "./ViewActivityFormCRSPL";
import AssignActivityFormCRSPL from "./AssignActivityFormCRSPL";
//import WysiwygOutlinedIcon from "@mui/icons-material/WysiwygOutlined";
//import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
//import AddTaskOutlinedIcon from "@mui/icons-material/AddTaskOutlined";
import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import PageHeader from "../../components/PageHeader";
//import { Paper } from "@mui/material";
//import PropTypes from "prop-types";

const BASE_URL = `${ConfigData.protoipaport}/assignedusers`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: "100%"
  }
}));

const headCells = [
  { id: "regId", label: "Registration Id" },
  { id: "userName", label: "Username" },
  { id: "emailId", label: "Email Id" },
  { id: "phone", label: "Phone" },
  { id: "gender", label: "Gender" },
  { id: "age", label: "Age" },
  { id: "actions", label: "Actions", disableSorting: true }
];

export default function ViewAllUsersCRSPL({ assigneduserid }) {
  const classes = useStyles();

  //CRSPL Concerned patients data
  const [records, setRecords] = useState([]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  //Current user id
  const currcrspluserid = localStorage.getItem("userid");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //State for checking the active button clicked for an userid
  const [active, setActive] = useState(false);

  //State for checking the assign button clicked for an userid
  const [assign, setAssign] = useState(false);

  //Temporary user id stored for getting all the activity records for a particular patient.
  const [tempId, setTempId] = useState(0);

  //Temporary user name stored for getting all the activity records for a particular patient.
  const [tempUsername, setTempUsername] = useState("");

  //Creating axios object for getting all concerned patients data for a particular CRSPL
  const axiosGetConAllUserData = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Concerned all user Records
  useEffect(() => {
    const getConAllUserRecords = async () => {
      const res = await axiosGetConAllUserData.get(
        `/getconalluserrecords/${currcrspluserid}`
      );

      const getCAURec = await res.data;
      setRecords(await getCAURec);
      //setRecords(getAllUserdata);
    };
    getConAllUserRecords();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.assignedUsers.toLowerCase().includes(target.value)
          );
      }
    });
  };

  const handleShowHistory = (e, userid, username) => {
    //alert(userid);
    setTempId(userid);
    setTempUsername(username);
    setActive(true);
  };

  const handleAssignActivities = (e, userid, username) => {
    //alert(userid);
    setTempId(userid);
    setTempUsername(username);
    setAssign(true);
  };

  return (
    <>
      {active ? (
        <ViewActivityFormCRSPL
          assigneduserid={tempId}
          assignedusername={tempUsername}
        />
      ) : assign ? (
        <AssignActivityFormCRSPL
          assigneduserid={tempId}
          assignedusername={tempUsername}
        />
      ) : (
        <div>
          <PageHeader
            title="Assigned Patients"
            subTitle="Please select the user to check the activity history or assign the exercise plan here"
            icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
          />
          <Toolbar>
            <Controls.Input
              label="Search Activities"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon //color="secondary"
                    />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />
          </Toolbar>{" "}
          <TblContainer>
            <TblHead />

            <TableBody>
              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.regId}</TableCell>
                  <TableCell>{item.userName}</TableCell>
                  <TableCell>{item.emailId}</TableCell>
                  <TableCell>{item.phone}</TableCell>
                  <TableCell>{item.gender}</TableCell>
                  <TableCell>{item.age}</TableCell>
                  <TableCell>
                    {/* <Controls.Button
                  color="primary"
                  onClick={() => {
                    //openInPopup(item)
                  }}
                >  */}
                    {/* <WysiwygOutlinedIcon fontSize="small" /> */}
                    {/* </Controls.Button> */}
                    <Controls.Button
                      startIcon={<VisibilityOutlinedIcon />}
                      type="submit"
                      color="primary"
                      onClick={(event) => {
                        handleShowHistory(
                          event,
                          `${item.assignedUserId}`,
                          `${item.userName}`
                        );
                        //alert(item.assigneduserid);
                      }}
                      text="Show"
                      size="small"
                    />
                    &nbsp;
                    <Controls.Button
                      //startIcon={<AddTaskOutlinedIcon />}
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      type="submit"
                      color="info"
                      onClick={(event) => {
                        handleAssignActivities(
                          event,
                          `${item.assignedUserId}`,
                          `${item.userName}`
                        );
                        //alert(item.assigneduserid);
                      }}
                      text="Assign"
                      size="small"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </div>
      )}
    </>
  );
}
