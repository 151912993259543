import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

function createData(name, trigly, totChol, hdlChol, ldlChol) {
  return { name, trigly, totChol, hdlChol, ldlChol };
}

const KYHHealth = () => {
  const { t } = useTranslation(["translation", "kyhh"]);

  const rows = [
    createData(t("table.r2c1", { ns: "kyhh" }), t("table.r2c2", { ns: "kyhh" }), t("table.r2c3", { ns: "kyhh" }), t("table.r2c4", { ns: "kyhh" }), t("table.r2c5", { ns: "kyhh" })),
    createData(t("table.r3c1", { ns: "kyhh" }), t("table.r3c2", { ns: "kyhh" }), t("table.r3c3", { ns: "kyhh" }), t("table.r3c4", { ns: "kyhh" }), t("table.r3c5", { ns: "kyhh" })),
    createData(t("table.r4c1", { ns: "kyhh" }), t("table.r4c2", { ns: "kyhh" }), t("table.r4c3", { ns: "kyhh" }), t("table.r4c4", { ns: "kyhh" }), t("table.r4c5", { ns: "kyhh" })),
    createData(t("table.r5c1", { ns: "kyhh" }), t("table.r5c2", { ns: "kyhh" }), t("table.r5c3", { ns: "kyhh" }), t("table.r5c4", { ns: "kyhh" }), t("table.r5c5", { ns: "kyhh" })),
    createData(t("table.r6c1", { ns: "kyhh" }), t("table.r6c2", { ns: "kyhh" }), t("table.r6c3", { ns: "kyhh" }), t("table.r6c4", { ns: "kyhh" }), t("table.r6c5", { ns: "kyhh" })),
  ];

  return (
    <Suspense fallback={<Loader />}>

      <>
        <section id="features" className="features">
          <div className="container">
            <div className="section-title">
              <h2>{t("header.h2", { ns: "kyhh" })}</h2>
              <p>
                {t("para.p1", { ns: "kyhh" })}
              </p>
            </div>

            <div className="row no-gutters">
              <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                <div className="content d-flex flex-column justify-content-center">
                  <div className="row">
                    <div className="col-md-12 icon-box" data-aos="fade-up">
                      <i className="bx bx-receipt"></i>
                      <h4>{t("header.h4a", { ns: "kyhh" })}</h4>
                      <p>
                        {t("para.p2", { ns: "kyhh" })}
                      </p>
                    </div>

                    <div
                      className="col-md-12 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <i className="bx bx-shield"></i>
                      <h4>{t("header.h4b", { ns: "kyhh" })}</h4>
                      <h6>{t("header.h6a", { ns: "kyhh" })}</h6>
                      <p>
                        {t("para.p3", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p4", { ns: "kyhh" })}
                      </p> <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6b", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p5", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p6", { ns: "kyhh" })}
                      </p><br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6c", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p7", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p8", { ns: "kyhh" })}
                      </p><br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6d", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p9", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p10", { ns: "kyhh" })}
                      </p><br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6e", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p11", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p12", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p13", { ns: "kyhh" })}
                      </p><br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6f", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p14", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p15", { ns: "kyhh" })}
                      </p>
                      <p>
                        {t("para.p16", { ns: "kyhh" })}
                      </p>
                      <p align="center">
                        {t("para.p17", { ns: "kyhh" })}
                      </p><br/>

                      <div
                        //className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                        data-aos="fade-left"
                        data-aos-delay="100"
                        align="center"
                      >
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                              <TableRow>
                                <TableCell>{t("table.r1c1", { ns: "kyhh" })}</TableCell>
                                <TableCell align="left">{t("table.r1c2", { ns: "kyhh" })}</TableCell>
                                <TableCell align="left">{t("table.r1c3", { ns: "kyhh" })}</TableCell>
                                <TableCell align="left">{t("table.r1c4", { ns: "kyhh" })}</TableCell>
                                <TableCell align="left">{t("table.r1c5", { ns: "kyhh" })}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow
                                  key={row.name}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.name}
                                  </TableCell>
                                  <TableCell align="left">{row.trigly}</TableCell>
                                  <TableCell align="left">{row.totChol}</TableCell>
                                  <TableCell align="left">{row.hdlChol}</TableCell>
                                  <TableCell align="left">{row.ldlChol}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                      </div>
                      <br/>
                      <p align="center">
                        {t("para.p18", { ns: "kyhh" })}
                      </p><br/>
                      <h6 align="center">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6g", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p19", { ns: "kyhh" })}
                      </p><br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6h", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p20", { ns: "kyhh" })}
                      </p>
                      <p>{t("para.p21", { ns: "kyhh" })}</p>
                      <p>{t("para.p22", { ns: "kyhh" })}</p>
                      <p>{t("para.p23", { ns: "kyhh" })}</p>
                      <p>{t("para.p24", { ns: "kyhh" })}</p>
                      <p>{t("para.p25", { ns: "kyhh" })}</p>
                      <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6i", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p26", { ns: "kyhh" })}
                      </p><br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6j", { ns: "kyhh" })}
                      </h6>
                      <p>
                        {t("para.p27", { ns: "kyhh" })}

                      </p>
                      <p>
                        {t("para.p28", { ns: "kyhh" })}

                      </p>
                      <p>
                        {t("para.p29", { ns: "kyhh" })}
                      </p>

                      <p>{t("para.p30", { ns: "kyhh" })}</p>
                      <p>{t("para.p31", { ns: "kyhh" })}</p>

                      <p>{t("para.p32", { ns: "kyhh" })}</p>
                      <br/>

                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6k", { ns: "kyhh" })}<p>{t("para.p33", { ns: "kyhh" })}</p>
                      </h6>
                      <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6l", { ns: "kyhh" })}<p>{t("para.p34", { ns: "kyhh" })}</p>
                      </h6>
                      <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6m", { ns: "kyhh" })}<p>{t("para.p35", { ns: "kyhh" })}</p>
                      </h6>
                      <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6n", { ns: "kyhh" })}<p>{t("para.p36", { ns: "kyhh" })}</p>
                      </h6>
                      <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6o", { ns: "kyhh" })}
                      </h6>
                      <p>{t("para.p37", { ns: "kyhh" })}</p>
                      <p>{t("para.p38", { ns: "kyhh" })}</p>
                      <p>{t("para.p39", { ns: "kyhh" })}</p>
                      <p>{t("para.p40", { ns: "kyhh" })}</p>
                      <br/>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("header.h6p", { ns: "kyhh" })}
                      </h6>
                      <p>{t("para.p41", { ns: "kyhh" })}</p>
                      <p>{t("para.p42", { ns: "kyhh" })}</p>
                    </div>

                    <div
                      className="col-md-12 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <i className="bx bx-atom"></i>
                      <h4>
                        {t("header.h4c", { ns: "kyhh" })}
                      </h4>
                      <p>
                        {t("para.p43", { ns: "kyhh" })}
                      </p>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href="https://qrisk.org"
                          onClick={() =>
                            window.open(
                              "https://qrisk.org",
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          https://qrisk.org
                        </a>
                      </h6>
                      <h6>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href="https://www.mdcalc.com/framingham-coronary-heart-disease-risk-score"
                          onClick={() =>
                            window.open(
                              "https://www.mdcalc.com/framingham-coronary-heart-disease-risk-score",
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          https://www.mdcalc.com/framingham-coronary-heart-disease-risk-score
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <img src="assets/img/features.svg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
    </Suspense>

  );
};

export default KYHHealth;
