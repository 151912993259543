import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import axios from "axios";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment
} from "@mui/material";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import { makeStyles } from "@mui/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const BASE_URL = `${ConfigData.protoipaport}/activitiesdiary`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: "100%"
  }
}));

const headCells = [
  { id: "currDateTime", label: "Date/Time" },
  { id: "activitiesType", label: "Activity" },
  { id: "actFreq", label: "Frequency" },
  { id: "peakHR", label: "PeakHR" },
  { id: "peakBorgRPE", label: "Peak RPE/BORG" },
  { id: "minDuration", label: "Duration(min)" },
  { id: "comments", label: "Others", disableSorting: true }
];

export default function ViewActivityForm() {
  const classes = useStyles();

  //Activities plan assigned data
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Reading activities plan by user id
  const axiosGetActivitiesPlan = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Activities Plan Assigned
  useEffect(() => {
    const getactivityplanrecords = async () => {
      const wStatus = "Active";
      const res = await axiosGetActivitiesPlan.get(
        `/getassignedactivitiesuid/${userid}/${wStatus}`
      );

      const getActPlanRec = await res.data;
      setRecords(await getActPlanRec);
    };
    getactivityplanrecords();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.activitiesType.toLowerCase().includes(target.value)
          );
      }
    });
  };

  return (
    <>
      <Toolbar>
        <Controls.Input
          label="Search Activities"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon //color="secondary"
                />
              </InputAdornment>
            )
          }}
          onChange={handleSearch}
        />
      </Toolbar>

      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                {new Date(item.currDateTime).toLocaleDateString("en-IN")}
              </TableCell>
              <TableCell>{item.activitiesType}</TableCell>
              <TableCell>{item.actFreq}</TableCell>
              <TableCell>{item.peakHR}</TableCell>
              <TableCell>{item.peakBorgRPE}</TableCell>
              <TableCell>{item.minDuration}</TableCell>
              <TableCell>{item.comments}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}
