import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const RiskFactors = () => {
    const { t } = useTranslation(["translation", "lwhd"]);

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">

                                        <div className="col-md-12 icon-box" data-aos="fade-up">

                                            <div
                                                className="col-md-12 icon-box"
                                                data-aos="fade-up"
                                            >
                                                <i className="bx bx-heart"></i>
                                                 {/* <i className="bx bx-shield"></i> */}
                                                 {/* <i className="bx bx-key"></i> */}
                                                <h4>
                                                    {t("header.h4f", { ns: "lwhd" })}
                                                </h4>
                                                <p>
                                                    {t("para.p67", { ns: "lwhd" })}
                                                </p>
                                            </div>

                                            <div
                                                className="col-md-12 icon-box"
                                                data-aos="fade-up"
                                            >
                                                {/* <i className="bx bx-receipt"></i> */}

                                                <h5>{t("header.h5c11a", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p69", { ns: "lwhd" })} </p>
                                                <p> {t("para.p70", { ns: "lwhd" })} </p>

                                                <h5>{t("header.h5c11b", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p71", { ns: "lwhd" })} </p>
                                                <p> {t("para.p72", { ns: "lwhd" })} </p>

                                                <h5>{t("header.h5c11c", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p73", { ns: "lwhd" })} </p>
                                                <p> {t("para.p74", { ns: "lwhd" })} </p>

                                                <h5>{t("header.h5c11d", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p75", { ns: "lwhd" })} </p>
                                                <p> {t("para.p76", { ns: "lwhd" })} </p>

                                                <h5>{t("header.h5c11e", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p77", { ns: "lwhd" })}</p>
                                                <p> {t("para.p78", { ns: "lwhd" })} </p>
                                                <p> {t("para.p79", { ns: "lwhd" })} </p>

                                                <h5>{t("header.h5c11f", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p80", { ns: "lwhd" })} </p>
                                                <p> {t("para.p81", { ns: "lwhd" })} </p>
                                                <p> {t("para.p82", { ns: "lwhd" })} </p>
                                            </div>

                                            <i className="bx bx-link"></i>
                                            <h4>
                                                {t("header.h4g", { ns: "lwhd" })}
                                            </h4>
                                            <h5>

                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://vimeo.com/906768181/f9fa075558"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://vimeo.com/906768181/f9fa075558",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p68", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense>
    );
};

export default RiskFactors;
