import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const ExerciseInst = () => {
    const { t } = useTranslation(["translation", "crinfo"]);

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">
                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                        >
                                            <i className="bx bx-receipt"></i>
                                            <h4>
                                                {t("header.h4c", { ns: "crinfo" })}
                                            </h4>
                                            <p>
                                                {t("para.p12", { ns: "crinfo" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >
                                            <i className="bx bx-cube-alt"></i>
                                            <h4>
                                                {t("header.h4d", { ns: "crinfo" })}
                                            </h4>
                                            <p>
                                                {t("para.p13", { ns: "crinfo" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="200"
                                        >
                                            <i className="bx bx-star"></i>
                                            <h4>
                                                {t("header.h4e", { ns: "crinfo" })}
                                            </h4>
                                            <p>
                                                {t("para.p14", { ns: "crinfo" })}
                                            </p>
                                            <p>
                                                {t("para.p15", { ns: "crinfo" })}
                                            </p>
                                            <p>
                                                {t("para.p16", { ns: "crinfo" })}
                                            </p>
                                            <p>
                                                {t("para.p17", { ns: "crinfo" })}
                                            </p>
                                            <p>
                                                {t("para.p18", { ns: "crinfo" })}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense>
    );
};

export default ExerciseInst;
