import { Suspense } from "react";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { Container, Row, Col } from "react-bootstrap";
import CRHead from "./CRHeading";
import IsItSafeToEx from "./IsItSafeToEx";
import CIDriveWithMyCon from "./CIDriveWithMyCon";
import CISAfterHA from "./CISAfterHA";
import DINTHeartMedFE from "./DINTHeartMedFE";
//import Accordion from '@mui/material/Accordion';
//import AccordionSummary from '@mui/material/AccordionSummary';
//import AccordionDetails from '@mui/material/AccordionDetails';
//import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const RQHeartHealth = () => {
  const { t } = useTranslation(["translation", "rqhh"]);
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Suspense fallback={<Loader />}>
      <>
        <section id="contact" className="contact">
          <div className="section-title" align="center">
            <h2><CRHead /></h2>
          </div>

          <Container className="container">
            <Row className="row">
              <Menu />

              <Col className="col-lg-9 info">
                <section id="rqhh">
                  <div className="section-title" align="center">
                    <h3>{t("header.h2", { ns: "rqhh" })}</h3>
                  </div>
             
                  <div>
                    <Accordion  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3a", { ns: "rqhh" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {<IsItSafeToEx />}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion  expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3b", { ns: "rqhh" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        {<CIDriveWithMyCon />}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion  expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3c", { ns: "rqhh" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          <CISAfterHA />
                        }
                      </AccordionDetails>
                    </Accordion>
                    <Accordion  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3d", { ns: "rqhh" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        {
                          <DINTHeartMedFE />
                        }
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    </Suspense>

  );
};
export default RQHeartHealth;
