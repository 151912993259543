import React, { useEffect, useRef, useState } from "react";
import { over } from "stompjs";
import SockJS from "sockjs-client";
//import sendIcon from "../images/send.svg";
import "./ChatRoom.css";
import * as Icon from "react-bootstrap-icons";
import axios from "axios";
//import ApiService from "./ApiService";
import ConfigData from "./config/Config.json";
import Button from "react-bootstrap/esm/Button";
//import ProgressBar from "react-bootstrap/esm/ProgressBar";
import Badge from "@mui/material/Badge";


const BASE_URL = `${ConfigData.protoipaport}/checkfileattachalgo`; // create/upload attachments
const DURL = `${ConfigData.protoipaport}/checkfileattachalgo`; // downloading attachments
const DELURL = `${ConfigData.protoipaport}/checkfileattachalgo`; // delete attachments
const GETASSIGNEDUSERSURL = `${ConfigData.protoipaport}/assignedusers`; // get chat users as well as CRSPL Users
const GETUSERCHATHISTORY = `${ConfigData.protoipaport}/checkfileattachalgo`; // get user chat history

var stompClient = null;
var badgeCount = [];   /* Global Badge counter */
var unreadMessageCounter = [];  /* Global unread message counter */
//var unreadMC = [];  /* Global unread message counter */

const ChatRoom = () => {
  const messageEl = useRef(null);

  const fileRef = useRef(null);

  const [pBarValue, setPBarValue] = useState("0");
  const [privateChats, setPrivateChats] = useState(new Map());
  const [publicChats, setPublicChats] = useState([]);
  const [chatUsers, setChatUsers] = useState([]);
  const [chatCrsplUsers, setChatCrsplUsers] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);

  const [state, setState] = useState({ file: null });
  const [filesLength, setFilesLength] = useState(0);
  const [buttonCaption, setButtonCaption] = useState("Attach");

  const curruser = localStorage.getItem("username");
  const userrole = localStorage.getItem("roles");
  const curruserid = localStorage.getItem("userid");

  const [tab, setTab] = useState("CHATROOM");
  const [historyFlag, setHistoryFlag] = useState(false);
  const [scrollFlag, setScrollFlag] = useState(false);

  //const [unreadMessageCounter, setUnreadMessageCounter] = useState([]);

  //To initialize the global badge variables
  function setGlobalAndLocalBadgeVariables() {
    var allBadgeVars = [...privateChats.keys()].map((c, i) => {
      return 0;
    });
    badgeCount = allBadgeVars;
    unreadMessageCounter = allBadgeVars;
    // alert("Global badgeCount is: " + badgeCount);
    //alert("Global unreadMessageCounter is: " + unreadMessageCounter);
  }

  //To increase the badge counter value by 0ne
  var indexBadge = 0;
  function getBadgeCounterIndex(name) {
    // alert("indexBadge entered is: " + indexBadge);
    //alert("badgeCount is: " + badgeCount);
    indexBadge = [...privateChats.keys()].map((c, i) => {
      if (c === name) {
        // Increment the clicked counter based on badgeCount flag value
        var temp;
        if (badgeCount[i] === 1) {
          unreadMessageCounter[i] = 0;
          temp = (++unreadMessageCounter[i]);
          badgeCount[i] = 0;
        } else {
          temp = (++unreadMessageCounter[i]);
        }
        return temp;
      } else {
        // The rest haven't changed
        return unreadMessageCounter[i];
      }
    });
    unreadMessageCounter = indexBadge;
    //setUnreadMessageCounter(indexBadge);

    //alert("badgeCount is: " + badgeCount);
    // alert("indexBadge is: " + indexBadge);
    // alert("nextCounter is: " + unreadMC);
    indexBadge = 0;
  }

  //To increase the badge counter value by 0ne
  // var indexBadge = 0;
  // function getBadgeCounterIndex(name) {
  //   // alert("indexBadge entered is: " + indexBadge);
  //   // alert("badgeCount is: " + badgeCount);
  //   indexBadge = [...privateChats.keys()].map((c, i) => {
  //     if (c === name) {
  //       // Increment the clicked counter based on badgeCount flag value
  //       var temp;
  //       if (badgeCount[i] === 1) {
  //         unreadMC[i] = 0;
  //         temp = (++unreadMC[i]);
  //         badgeCount[i] = 0;
  //       } else {
  //         temp = (++unreadMC[i]);
  //       }
  //       return temp;
  //     } else {
  //       // The rest haven't changed
  //       return unreadMC[i];
  //     }
  //   });
  //   unreadMC = indexBadge;
  //   setUnreadMessageCounter(indexBadge);
  //   // alert("badgeCount is: " + badgeCount);
  //   // alert("indexBadge is: " + indexBadge);
  //   // alert("nextCounter is: " + unreadMC);
  //   indexBadge = 0;
  // }

  //To set the value of the badge to zero when the user name is clicked
  function handleClickToZeroBadge(index) {
    // alert("unreadMessageCounter is: " + unreadMessageCounter);
    // alert("index is: " + index);
    // alert("badgeCount is: " + badgeCount);
    var zeroCounter = [...unreadMessageCounter].map((c, i) => {
      if (i === index) {
        unreadMessageCounter[index] = 0;
        badgeCount[index] = 1;
        // Set the clicked counter to zero
        return 0;
      } else {
        // No need to change anything
        return c;
      }
    });
    unreadMessageCounter = zeroCounter;
    //setUnreadMessageCounter(zeroCounter);

    // alert("zeroCounter is: " + zeroCounter);
    //alert("unreadMessageCounter is: " + unreadMessageCounter);
    zeroCounter = null;
  }

  const [userData, setUserData] = useState({
    username: curruser,
    receivername: "",
    fileid: "",
    filename: "",
    connected: false,
    message: "Type here..."
  });

  const token = localStorage.getItem("token");

  const authAxiosUploadAttachments = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const authAxiosDownloadAttachment = axios.create({
    baseURL: DURL,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const authAxiosGetConAllUsers = axios.create({
    baseURL: GETASSIGNEDUSERSURL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const authAxiosDeleteAttachments = axios.create({
    baseURL: DELURL,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const authAxiosGetUserChatHistory = axios.create({
    baseURL: GETUSERCHATHISTORY,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  //Tab Index
  // useEffect(() => {
  //   const getTabIndex = async () => {
  //     setTabIndex(tabIndex);
  //   };
  //   getTabIndex();
  // }, [tabIndex]);

  //chatUsers
  // useEffect(() => {
  //   const getConAllUsers = async () => {
  //     // using axios
  //     const res = await authAxiosGetConAllUsers.get(userData.username);

  //     const getCU = await res.data;

  //     setChatUsers(await getCU);
  //   };
  //   getChatUsers();
  // }, []);

  // useEffect(() => {
  //   console.log(userData);
  // }, [userData]);


  useEffect(() => {
    if (messageEl.current) {
      messageEl.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  }, [privateChats, scrollFlag]);

  // useEffect(() => {
  //   if (messageEl.current) {
  //     // const trg = messageEl.current.target.event;
  //     // trg.scroll({ top: trg.scrollHeight, behavior: "smooth" });
  //     messageEl.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end",
  //       inline: "nearest"
  //     });
  //   }
  // }, [userData, privateChats, scrollFlag]);

  // useEffect(() => {
  //   if (messageEl) {
  //     window.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (messageEl.current) {
  //     messageEl.current.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  //   }
  // }, [userData.message]);

  /* Get User previous Chat History here */
  const getUserChatHistory = async (e) => {
    e.preventDefault();
    if (!historyFlag) {
      try {
        localStorage.removeItem("message");

        await authAxiosGetUserChatHistory
          .get(`/getuserchathistory/${userData.username}`) //To get the chat history of a particular user or patient
          .then(
            (response) => {
              chatHistory.push(response.data);
              setChatHistory([...chatHistory]);

              // console.log(
              //   "\n=========== Chat Users History Data Starts==============="
              // );
              // console.log(chatHistory);
              // console.log(
              //   "=========== Chat Users History Data Ends ===============\n"
              // );

              /* Loading Chat history */
              chatHistory.forEach((element) => {
                element.forEach((payloadData) => {
                  if (
                    privateChats.get(payloadData.senderName) ||
                    privateChats.get(payloadData.receiverName)
                  ) {
                    if (privateChats.get(payloadData.senderName)) {
                      privateChats
                        .get(payloadData.senderName)
                        .push(payloadData);
                    }
                    if (privateChats.get(payloadData.receiverName)) {
                      privateChats
                        .get(payloadData.receiverName)
                        .push(payloadData);
                    }
                    setPrivateChats(new Map(privateChats));
                    setHistoryFlag(true);
                  }
                });
              });
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in loading Chat User history....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              alert("Users Chat History Loading Failure!");
            }
          );
        //console.log(JSON.stringify(localStorage.getItem("token")));
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
      }
    } else {
      alert("Chat History already loaded.");
    }
  };

  /* Get Concerned All Users Here Assigned to a particular CRSPL */
  const getConAllUsers = async (e) => {
    e.preventDefault();
    try {
      localStorage.removeItem("message");

      await authAxiosGetConAllUsers
        .get(`/getconcrspluserrecords/${curruserid}`) //To get the crspl for this user
        .then(
          (response) => {
            localStorage.setItem("message", response.data);
            chatUsers.push(response.data);
            setChatUsers([...chatUsers]);
            // console.log("\n=========== Chat Users Data Starts===============");
            // console.log(chatUsers);
            // console.log("=========== Chat Users Data Ends ===============\n");
          },
          (error) => {
            if (error.response) {
              console.log(error.response.data);
              const scode = error.response.status;
              if (scode === 401 || scode === 403 || scode === 404) {
                localStorage.setItem(
                  "message",
                  "Error in loading Chat Users....."
                );
              }
              console.log(error.response.status);
              console.log(error.response.headers);
            }
            console.log("Error is below: ");
            console.log(error);
            console.log(error.data.message);
            alert("Chat User (CRSPL) Loading Failure!");
          }
        );
      console.log(JSON.stringify(localStorage.getItem("token")));
    } catch (err) {
      console.log(err.response);
      console.log(err.message);
    }
  };

  /* Get Concerned CRSPL User Here under which a particular User is Assigned */
  const getConCrsplUsers = async (e) => {
    e.preventDefault();
    try {
      localStorage.removeItem("message");

      await authAxiosGetConAllUsers
        .get(`/getconalluserrecords/${curruserid}`) // To get all the assigned users for this crspl
        .then(
          (response) => {
            localStorage.setItem("message", response.data);

            chatCrsplUsers.push(response.data);
            setChatCrsplUsers([...chatCrsplUsers]);

            // console.log("\n=========== Chat Users Data Starts===============");
            // console.log(chatCrsplUsers);
            // console.log("=========== Chat Users Data Ends===============\n");
          },
          (error) => {
            if (error.response) {
              console.log(error.response.data);
              const scode = error.response.status;
              if (scode === 401 || scode === 403 || scode === 404) {
                localStorage.setItem(
                  "message",
                  "Error in loading Chat Users....."
                );
              }
              console.log(error.response.status);
              console.log(error.response.headers);
            }

            console.log("Error is below: ");
            console.log(error);
            console.log(error.data.message);
            alert("Chat Users (Patients) List Loading Failure!");
          }
        );
      console.log(JSON.stringify(localStorage.getItem("token")));
    } catch (err) {
      console.log(err.response);
      console.log(err.message);
    }
  };

  const connect = (e) => {
    userrole === "ROLE_PATIENT" ? getConAllUsers(e) : getConCrsplUsers(e);
    let Sock = new SockJS(`${ConfigData.protoipaport}/ws`);
    stompClient = over(Sock);
    stompClient.connect({}, onConnected, onError);
  };

  const onConnected = () => {
    setUserData({ ...userData, connected: true });

    /* Public message receiving Channel - Subscription is no more required. */
    //stompClient.subscribe("/chatroom/public", onPublicMessageReceived);

    /* Private joining message receiving Channel */
    stompClient.subscribe(
      "/user/" + userData.username + "/privatejoining",
      onPrivateJoiningMessageReceived
    );

    /* Private message receiving Channel */
    stompClient.subscribe(
      "/user/" + userData.username + "/private",
      onPrivateMessageReceived
    );
    userJoin();
  };

  const userJoin = () => {
    var chatMessage = {
      senderName: userData.username,
      receiverName: userData.username,
      message: userData.message,
      status: userrole === "ROLE_PATIENT" ? "JOIN" : "JOIN_CRSPL"
    };

    /* Public Joining Message - No more required */
    //stompClient.send("/app/message", {}, JSON.stringify(chatMessage));

    /* Private Joining Message */
    stompClient.send(
      "/app/private-joining-message", // Will go to springboot backend server from here
      {},
      JSON.stringify(chatMessage)
    );
  };

  const downloadResource = async (e, fileId, fileName) => {
    e.preventDefault();
    try {

      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      await authAxiosDownloadAttachment.get(`/downloadca/${fileId}`).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          //alert(url);
          const link = document.createElement("a");
          link.href = url;
          //const fileName = `${+new Date()}.pdf`; // whatever your file name .
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);

          link.remove(); // you need to remove that elelment which is created before.
          //console.log(response.data);
          localStorage.setItem("message", response.data);
          //alert("Download Success!");
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem("message", "Downloading.....");
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          alert("File Download Failure!");
        }
      );
      //console.log(JSON.stringify(localStorage.getItem("token")));
    } catch (err) {
      console.log(err.response);
      console.log(err.message);
    }
  };

  // const setPrvDataPatient = async (payloadData) => {
  //   /* Setting all the concerned chatUsers into privateChats here */
  //   chatUsers.forEach((element) => {
  //     element.forEach((data) => {
  //       privateChats.set(data.userName, []);
  //     });
  //   });
  //   setPrivateChats(new Map(privateChats));
  // };

  // const setPrvDataCrspl = async (payloadData) => {
  //   /* Setting the concerned chatCrsplUsers into privateChats here */
  //   chatCrsplUsers.forEach((element) => {
  //     element.forEach((data) => {
  //       privateChats.set(data.assignedUsers, []);
  //     });
  //   });
  //   setPrivateChats(new Map(privateChats));
  // };

  const setPrvChatsPatient = async (payloadData) => {
    // privateChats.get(payloadData.senderName) === undefined
    //   ? setPrvDataPatient(payloadData)
    //   : alert("No function to call at this situation: Data Patient");

    if (!privateChats.get(payloadData.senderName)) {
      /* Setting all the concerned chatUsers (crspl assigned to this patient) into privateChats here */
      chatUsers.forEach((element) => {
        element.forEach((data) => {
          privateChats.set(data.userName, []);
        });
      });
      setPrivateChats(new Map(privateChats));
    } else {
      alert("Sender Name is already present in private Chat: PATIENT");
    }

    /* To set global and local badge variables for patient */
    setGlobalAndLocalBadgeVariables();
  };

  const setPrvChatsCrspl = async (payloadData) => {
    // privateChats.get(payloadData.senderName) === undefined
    //   ? setPrvDataCrspl(payloadData)
    //   : alert("No function to call at this situation: Data Crspl");

    if (!privateChats.get(payloadData.senderName)) {
      /* Setting the concerned chatCrsplUsers (users assigned to this crspl) into privateChats here */
      chatCrsplUsers.forEach((element) => {
        element.forEach((data) => {
          privateChats.set(data.assignedUsers, []);
        });
      });
      setPrivateChats(new Map(privateChats));
    } else {
      alert("Sender Name is already present in private Chat: CRSPL");
    }

    /* To set global and local badge variables for crspl */
    setGlobalAndLocalBadgeVariables();
  };

  const onPrivateJoiningMessageReceived = (payload) => {
    // JSON String to Java Script Object
    var payloadData = JSON.parse(payload.body);
    //alert(payloadData.status);
    //alert(payloadData.senderName);
    // alert(payloadData.fileName);
    // alert(payloadData.fileId);
    // alert(userData.filename);
    // alert(userData.fileid);

    switch (payloadData.status) {
      case "JOIN":
        userrole === "ROLE_PATIENT"
          ? setPrvChatsPatient(payloadData)
          : alert("Nothing to call here: Joining Patient"); //at CRSPL is alerted when Patient is connecting
        break;
      case "JOIN_CRSPL":
        userrole === "ROLE_CRSPL"
          ? setPrvChatsCrspl(payloadData)
          : alert("Nothing to call here: Joining CRSPL"); //at Patient is alerted when CRSPL is connecting
        break;
      case "MESSAGE": //only for public chatroom messages received
        publicChats.push(payloadData);
        setPublicChats([...publicChats]);
        break;
      case "MSG_ATTACH": //only for public chatroom attachment messages received
        publicChats.push(payloadData);
        setPublicChats([...publicChats]);
        break;
      default:
        break;
    }
  };

  const onPrivateMessageReceived = (payload) => {
    var payloadData = JSON.parse(payload.body);
    if (privateChats.get(payloadData.senderName)) {
      privateChats.get(payloadData.senderName).push(payloadData);
      setPrivateChats(new Map(privateChats));
      getBadgeCounterIndex(payloadData.senderName);
      //alert("Value of Tab: " + payloadData.senderName);
      //alert("Returned Index is: " + indexBadge);
      //handleClickToIncrementBadge(indexBadge);
      //setUnreadMessageCounter(++unreadMessageCounter[key]);
    } else {
      // let list = [];
      // list.push(payloadData);
      // privateChats.set(payloadData.senderName, list);
      // setPrivateChats(new Map(privateChats));
    }
  };

  //selecting the files to be attached in a state
  let formData = new FormData();
  const selectChatAttachments = async (e) => {
    setState({
      file: null
    });
    //console.log(e.target.files, "All Files Selected");
    //console.log(e.target.files[0], "File From 0 Index only");

    if (e.target && e.target.files[0]) {
      setState({
        file: e.target.files[0]
      });
    }
    setFilesLength(e.target.files.length);
  };

  // attaching or uploading the selected files
  // const sendChatAttachments = async (e) => {
  //   //formData.append("file", e.target.files[0]);
  //   formData.append("file", state.file);
  //   //print form data array
  //   console.log([...formData]);
  //   //posting formData
  //   await authAxiosUploadAttachments
  //     .post("/uploadca", formData)
  //     .then((res) => {
  //       //alert("Attachment Created Successfully!");
  //       console.log(res);
  //       setState({
  //         file: null
  //       });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  //To Insert/Upload the attachments in db if any files are selected
  const sendChatAttachments = async (e) => {
    if (filesLength !== 0 && fileRef.current.value !== "") {
      // appending the state to formData
      formData.append("file", state.file);
      console.log([...formData]);

      e.preventDefault();

      //linking the progress bar positively
      const config = {
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setPBarValue(percentCompleted);
          //pBarRef.current.setAttribute("value", percentCompleted);
          // bar.previousElementSibling.textContent = "${percentCompleted}%";
          // if (percentCompleted === 100) {
          //   bar.previousElementSibling.textContent = "Upload complete!";
          // }
        }
      };

      if (buttonCaption === "Attach") {
        //alert("Entered Attach");
        try {
          localStorage.removeItem("message");

          await authAxiosUploadAttachments
            .post("/uploadca", formData, config)
            .then(
              //use config function for progress bar here
              (response) => {
                localStorage.setItem("message", response.data);

                const response_array = response.data.downloadURL.split("downloadca/"); //split the downloadURL

                // setUserData({ ...userData, filename: response.data.fileName }); //file name
                // setUserData({ ...userData, fileid: response_array[1] }); //file id

                userData.filename = response.data.fileName; //file name
                userData.fileid = response_array[1]; //file id

                // alert("userData.filename: " + userData.filename);
                // alert("userData.fileid: " + userData.fileid);
               console.log("\n======================================== response_array: "+ response_array);
               console.log("\n======================================== response_array[0]: "+ response_array[0]);
               console.log("\n======================================== response_array[1]: "+ response_array[1]);
               //console.log("\n\n"+response);
               //alert("\nresponse_array[1] value is : "+response_array[1]);

                // setState({
                //   file: null
                // });
                setButtonCaption("Delete");
                alert("Attachment Created Successfully!");
              },
              (error) => {
                if (error.response) {
                  console.log(error.response.data);
                  const scode = error.response.status;
                  if (scode === 401 || scode === 403 || scode === 404) {
                    localStorage.setItem(
                      "message",
                      "Error in sending Attachment....."
                    );
                  }
                  console.log(error.response.status);
                  console.log(error.response.headers);
                }
                //clearing the file input and other elements
                fileRef.current.value = "";
                setPBarValue(0);
                userData.fileid = "";
                userData.filename = "";
                setFilesLength(0);

                console.log("Error is below: ");
                console.log(error);
                console.log(error.data.message);
                alert("Error in creating the Attachment. Attachment Could not be created!");
              }
            );
          console.log(JSON.stringify(localStorage.getItem("token")));
        } catch (err) {
          console.log(err.response);
          console.log(err.message);
        }
      } else {
        //alert("Entered Delete");
        try {
          localStorage.removeItem("message");

          await authAxiosDeleteAttachments.delete(`/deleteca/${userData.fileid}`).then(
            //use config function for progress bar here
            (response) => {
              localStorage.setItem("message", response.data);

              const response_data = response.data;

              // setUserData({ ...userData, filename: response.data.fileName }); //file name
              // setUserData({ ...userData, fileid: response_array[4] }); //file id

              userData.filename = ""; //file name
              userData.fileid = ""; //file id
              setPBarValue(0);
              // alert("userData.filename: " + userData.filename);
              // alert("userData.fileid: " + userData.fileid);

              console.log(response);

              // setState({
              //   file: null
              // });
              setButtonCaption("Attach");
              //alert("Attachment Deleted Successfully!");
              alert(response_data);
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in deleting the Attachment....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              //clearing the file input and other elements
              fileRef.current.value = "";
              setPBarValue(0);
              userData.fileid = "";
              userData.filename = "";
              setFilesLength(0);

              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              alert("Error in deleting the attachment. Attachment Could not be deleted!");
            }
          );
          console.log(JSON.stringify(localStorage.getItem("token")));
        } catch (err) {
          console.log(err.response);
          console.log(err.message);
        }
      }
    } else {
      alert("Please choose the file to be attached.");
    }
  };

  /* const onFileChangeHandler = async (e) => {
    alert("1");
    e.preventDefault();
    alert("2");
    // this.setState({
    //   selectedFile: e.target.files[0]
    // });

    alert(e.target.files.length);
    let files = e.target.files[0];
    console.warn("Data Files", files);
    this.setState({ file: files });

    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      console.warn("File Data in Base64: ", e.target.result);
      const formData = { file: e.target.result };
      return authAxiosUploadAttachments
        .post("/uploadca", formData)
        .then((response) => console.warn("Result: ", response));
    };

    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("file", e.target.files[i]);
    }
    alert("3");
    ApiService.upload(BASE_URL, formData, authAxiosUploadAttachments).then(
      (res) => {
        console.log(res.data);
        alert("File uploaded successfully.");
      }
    );
    alert("4");
  }; */

  // const uploadResource = async (e, fileId, fileName) => {
  //   e.preventDefault();
  //   try {
  //     //alert("Sending GET Request to get the resource, if authorized.");
  //     localStorage.removeItem("message");
  //     await authAxios.get(fileId).then(
  //       (response) => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         //alert(url);
  //         const link = document.createElement("a");
  //         link.href = url;
  //         //const fileName = `${+new Date()}.pdf`; // whatever your file name .
  //         link.setAttribute("download", fileName);
  //         document.body.appendChild(link);
  //         link.click();

  //         // clean up "a" element & remove ObjectURL
  //         document.body.removeChild(link);
  //         URL.revokeObjectURL(url);

  //         link.remove(); // you need to remove that elelment which is created before.
  //         console.log(response.data);
  //         localStorage.setItem("message", response.data);
  //         alert("Download Success!");
  //       },
  //       (error) => {
  //         if (error.response) {
  //           console.log(error.response.data);
  //           const scode = error.response.status;
  //           if (scode === 401 || scode === 403 || scode === 404) {
  //             localStorage.setItem("message", "Downloading.....");
  //           }
  //           console.log(error.response.status);
  //           console.log(error.response.headers);
  //         }
  //         console.log("Error is below: ");
  //         console.log(error);
  //         console.log(error.data.message);
  //         alert("Download Failure!");
  //       }
  //     );
  //     console.log(JSON.stringify(localStorage.getItem("token")));
  //   } catch (err) {
  //     console.log(err.response);
  //     console.log(err.message);
  //   }
  // };

  const onError = (err) => {
    console.log(err);
  };

  const handleMessage = (event) => {
    const { value } = event.target;
    setUserData({ ...userData, message: value });
  };

  /* This function is not used anymore because it belongs to the public chatroom which is not active at present anymore */
  const sendPublicValue = (e) => {
    //a
    if (userData.message === "Type here..." || userData.message === "") {
      alert("Please type a message to continue.");
    } else {
      //b
      if (stompClient) {
        //c
        if (fileRef.current.value === "" && filesLength === 0) {
          //only Message code
          var chatMessage = {
            senderName: userData.username,
            message: userData.message,
            status: "MESSAGE",
            fileId: userData.fileid,
            fileName: userData.filename
          };
          //console.log(chatMessage);
          // console.log(
          //   JSON.stringify(chatMessage),
          //   "This is JS Script stringify object Data sended."
          // );

          //clearing the file input and other elements
          fileRef.current.value = "";
          setPBarValue(0);
          userData.fileid = "";
          userData.filename = "";
          setFilesLength(0);
          setButtonCaption("Attach");

          //JavaScript Object toJSON String
          stompClient.send("/app/message", {}, JSON.stringify(chatMessage));

          setUserData({ ...userData, message: "" });
          setUserData({ ...userData, fileid: "" });
          setUserData({ ...userData, filename: "" });
        } else {
          //d
          if (userData.filename === "" && userData.fileid === "") {
            alert("Please, attach the selected file.");
          } else {
            //e
            var chatMessageWA = {
              senderName: userData.username,
              message: userData.message,
              status: "MSG_ATTACH",
              fileId: userData.fileid,
              fileName: userData.filename
            };

            //JavaScript Object toJSON String
            stompClient.send("/app/message", {}, JSON.stringify(chatMessageWA));

            //clearing the file input and other elements
            fileRef.current.value = "";
            setPBarValue(0);
            userData.fileid = "";
            userData.filename = "";
            setFilesLength(0);
            setButtonCaption("Attach");

            setUserData({ ...userData, message: "" });
            setUserData({ ...userData, fileid: "" });
            setUserData({ ...userData, filename: "" });
          } //e
        } //d
      } //c
    } //b
  }; //a


  const clearSendBox = (e) => {
    if (userData.message === "Type here...") {
      e.target.value = "";
    }
  }

  const sendPrivateValue = (e) => {
    //a
    //(userData.message === "Type here..." || userData.message === "") 
    if ((userData.message === "Type here..." || userData.message === "") & (filesLength === 0 && fileRef.current.value === "")) {
      alert("Please type a message to continue.");
    } else {
      //b
      if (stompClient) {
        //c
        if (fileRef.current.value === "" && filesLength === 0) {
          //d
          var chatMessage = {
            senderName: userData.username,
            receiverName: tab,
            message: userData.message,
            status: "MESSAGE",
            fileId: userData.fileid,
            fileName: userData.filename
          };

          if (userData.username !== tab) {
            privateChats.get(tab).push(chatMessage);
            setPrivateChats(new Map(privateChats));
          }

          stompClient.send(
            "/app/private-message",
            {},
            JSON.stringify(chatMessage)
          );

          //clearing the file input and other elements
          fileRef.current.value = "";
          setPBarValue(0);
          userData.fileid = "";
          userData.filename = "";
          userData.message = "";
          setFilesLength(0);
          setButtonCaption("Attach");

          setUserData({ ...userData, message: "" });
          setUserData({ ...userData, fileid: "" });
          setUserData({ ...userData, filename: "" });
        } else {
          //d
          if (userData.filename === "" && userData.fileid === "") {
            alert("Please, attach the selected file.");
          } else {
            //e
            if (userData.message === "Type here...") {
              userData.message = "";
            }
            var chatMessageWA = {
              senderName: userData.username,
              receiverName: tab,
              message: userData.message,
              status: "MSG_ATTACH",
              fileId: userData.fileid,
              fileName: userData.filename
            };
         alert(chatMessageWA);
         console.log(chatMessageWA);
         alert(userData.fileid);
            if (userData.username !== tab) {
              privateChats.get(tab).push(chatMessageWA);
              setPrivateChats(new Map(privateChats));
            }
            stompClient.send(
              "/app/private-message",
              {},
              JSON.stringify(chatMessageWA)
            );

            //clearing the file input and other elements
            fileRef.current.value = "";
            setPBarValue(0);
            userData.fileid = "";
            userData.filename = "";
            userData.message = "";
            setFilesLength(0);
            setButtonCaption("Attach");

            setUserData({ ...userData, message: "" });
            setUserData({ ...userData, fileid: "" });
            setUserData({ ...userData, filename: "" });
          } //e
        } //d
      } //c
    } //b
  }; //a

  /* const handleUsername = (event) => {
     const { value } = event.target;
     setUserData({ ...userData, username: value });
   }; */

  const registerUser = (e) => {
    connect(e);
  };

  return (
    <div className="container">
      {userData.connected ? (
        <div className="chat-box">
          <div className="member-list">
            <ul className="member-list-scrollable">
              {/* <li
                onClick={() => {
                  setTab("CHATROOM");
                }}
                className={`member ${tab === "CHATROOM" && "active"}`}
              >
                Chatroom
              </li> */}
              {[...privateChats.keys()].map((name, index) => (
                <li
                  onClick={(event) => {
                    setTab(name);
                    !historyFlag
                      ? getUserChatHistory(event)
                      : //alert("History already loaded!");
                      !scrollFlag
                        ? setScrollFlag(true)
                        : setScrollFlag(false);
                    handleClickToZeroBadge(index);
                  }}
                  className={`member ${tab === name && "active"}`}
                  key={index}
                >
                  <Badge
                    badgeContent={unreadMessageCounter[index]}
                    color="error"
                    max={9999}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left"
                    }}
                  >
                    {name}
                  </Badge>
                </li>
              ))}
            </ul>
          </div>

          {tab === "CHATROOM" && (
            <div className="chat-content">
              <div className="messages">
                <ul className="chat-messages">
                  {publicChats.map((chat, index) => (
                    <li
                      className={`message ${chat.senderName === userData.username && "self"
                        }`}
                      key={index}
                      ref={messageEl}
                    >
                      {chat.senderName !== userData.username && (
                        <div className="avatar">{chat.senderName}</div>
                      )}

                      {chat.status !== "MSG_ATTACH" && (
                        <div className="message-data">{chat.message}</div>
                      )}

                      {chat.status === "MSG_ATTACH" && (
                        <div className="message-data">
                          {" "}
                          {chat.fileName}
                          <Icon.Paperclip
                            className="message-data-attachment"
                            color="Red"
                            size={35}
                            type="file"
                            title="Download Attachment"
                            onClick={(event) =>
                              downloadResource(
                                event,
                                chat.fileId,
                                chat.fileName
                              )
                            }
                            style={{ margin: "1px" }}
                          />{" "}
                          {chat.message}
                        </div>
                      )}

                      {chat.senderName === userData.username && (
                        <div className="avatar self">{chat.senderName}</div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="send-message">
                <input
                  type="text"
                  className="input-message"
                  // placeholder="enter the message"
                  id={userData.message}
                  value={userData.message}
                  onChange={handleMessage}
                  onClick={(e) => (e.target.value = "")}
                />
                <button
                  type="button"
                  className="send-button"
                  onClick={(e) => sendPublicValue(e)}
                >
                  send
                </button>
              </div>

              <div>
                <input
                  type="file"
                  name="fileBox"
                  id="fileBox"
                  ref={fileRef}
                  //multiple
                  // accept="image/*"
                  style={{ margin: "10px" }}
                  onChange={(e) => selectChatAttachments(e)}
                />
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={(e) => sendChatAttachments(e)}
                >
                  {buttonCaption}
                </Button>
                &nbsp;&nbsp;
                <progress
                  id="progres-bar"
                  value={pBarValue}
                  max="100"
                ></progress>
                {/* <ProgressBar
                  now={pBarValue}
                  variant="warning"
                  style={{ width: "35%", height: "15px" }}
                  label={`${pBarValue}%`}
                /> */}
                &nbsp;&nbsp;
                <label for="progress-bar">{pBarValue}%</label>
              </div>
            </div>
          )}
          {tab !== "CHATROOM" && (
            <div className="chat-content">
              <div className="messages" >
                <ul className="chat-messages" ref={messageEl}>
                  {[...privateChats.get(tab)].map((chat, index) => (
                    <li
                      className={`message ${chat.senderName === userData.username && "self"
                        }`}
                      key={index}
                      ref={messageEl}
                    >
                      {chat.senderName !== userData.username && (
                        <div className="avatar">{chat.senderName}</div>
                      )}

                      {chat.status !== "MSG_ATTACH" && (
                        <div className="message-data">{chat.message}</div>
                      )}

                      {chat.status === "MSG_ATTACH" && (
                        <div className="message-data">
                          {" "}
                          {chat.fileName}
                         
                            <Icon.Paperclip
                              className="message-data-attachment"
                              color="Red"
                              size={35}
                              type="file"
                              title="Download Attachment"
                              onClick={(event) =>
                                downloadResource(
                                  event,
                                  chat.fileId,
                                  chat.fileName
                                )
                              }
                              style={{ margin: "1px" }}
                            />{" "}
                            {chat.message}
                        </div>
                      )}

                      {chat.senderName === userData.username && (
                        <div className="avatar self">{chat.senderName}</div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="send-message">
                <input
                  type="text"
                  className="input-message"
                  // placeholder="enter the message"
                  id={userData.message}
                  value={userData.message}
                  onChange={handleMessage}
                  //onClick={(e) => (e.target.value = "")}
                  onClick={(e) => clearSendBox(e)}
                />
                <button
                  type="button"
                  className="send-button"
                  onClick={(e) => sendPrivateValue(e)}
                >
                  send
                </button>
              </div>
              <div>
                <input
                  type="file"
                  name="fileBox"
                  id="fileBox"
                  ref={fileRef}
                  //multiple
                  //accept="image/*"
                  style={{ margin: "10px" }}
                  onChange={(e) => selectChatAttachments(e)}
                />
                <Button
                  variant="outline-dark"
                  size="sm"
                  onClick={(e) => sendChatAttachments(e)}
                >
                  {buttonCaption}
                </Button>
                &nbsp;&nbsp;
                <progress
                  id="progres-bar"
                  value={pBarValue}
                  max="100"
                ></progress>
                {/* <ProgressBar
                  now={pBarValue}
                  variant="warning"
                  style={{ width: "35%", height: "15px" }}
                  label={`${pBarValue}%`}
                /> */}
                &nbsp;&nbsp;
                <label for="progress-bar">{pBarValue}%</label>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="register" align="center">
          <input
            id="user-name"
            placeholder="Logging in to Chat"
            name="userName"
            value="Are you sure you want to Login to Chat?"
            // onChange={handleUsername}
            margin="normal"
            readonly
          />
          <button type="button" onClick={(e) => registerUser(e)}>
            connect
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatRoom;
