import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const SOHAilment = () => {
  const { t } = useTranslation(["translation", "soha"]);

  return (
    <Suspense fallback={<Loader />}>
      <>
        <section id="features" className="features">
          <div className="container">
            <div className="section-title">
              <h2>{t("header.h2", { ns: "soha" })}</h2>
            </div>

            <div className="row no-gutters">
              <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                <div className="content d-flex flex-column justify-content-center">
                  <div className="row">
                    <div className="col-md-12 icon-box" data-aos="fade-up">
                      <i className="bx bx-receipt"></i>
                      <h4>{t("header.h2", { ns: "soha" })}</h4>
                      <p>
                        {t("para.p1", { ns: "soha" })}
                      </p>
                      <p>
                        {t("para.p2", { ns: "soha" })}
                      </p>
                      <p>
                        {t("para.p3", { ns: "soha" })}
                      </p>
                      <p>
                        {t("para.p4", { ns: "soha" })}
                      </p>
                      <p>
                        {t("para.p5", { ns: "soha" })}
                      </p>
                      <p>
                        {t("para.p6", { ns: "soha" })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <img src="assets/img/features.svg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
    </Suspense>
  );
};

export default SOHAilment;
