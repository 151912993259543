import React, { useState, Suspense } from "react";
import { Grid, Slide } from "@mui/material";
import axios from "axios";
import { useForm, Form } from "../../components/useForm";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
//import IconButton from "@mui/material/IconButton";
//import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
//import * as employeeService from "../../services/employeeService";

function createData(description, quantity) {
  return { description, quantity };
}

const initialFValues = {
  usid: 0,
  dateTime: new Date().toLocaleString(),
  //dateTime: new Date(),
  smk: "false",
  alcScreen1: 0,
  alcScreen2: 0,
  alcScreen3: 0,
  alcFullScreen4: 0,
  alcFullScreen5: 0,
  alcFullScreen6: 0,
  alcFullScreen7: 0,
  alcFullScreen8: 0,
  alcFullScreen9: 0,
  alcFullScreen10: 0
};

const BASE_URL = `${ConfigData.protoipaport}/assessmentforms`;

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

export default function AlcoholTobaccoForm() {
  const { t } = useTranslation(["translation", "alcsmk"]);

  const rows = [
    createData(
      t("table.key1", { ns: "alcsmk" }),
      t("table.value1", { ns: "alcsmk" })
    ),
    createData(
      t("table.key2", { ns: "alcsmk" }),
      t("table.value2", { ns: "alcsmk" })
    ),
    createData(
      t("table.key3", { ns: "alcsmk" }),
      t("table.value3", { ns: "alcsmk" })
    ),
    createData(
      t("table.key4", { ns: "alcsmk" }),
      t("table.value4", { ns: "alcsmk" })
    ),
    createData(
      t("table.key5", { ns: "alcsmk" }),
      t("table.value5", { ns: "alcsmk" })
    )
  ];

  const smkFlag = [
    { id: "false", title: t("options.option1", { ns: "alcsmk" }) },
    { id: "true", title: t("options.option2", { ns: "alcsmk" }) }
  ];
  const alcScreenItems1 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option4", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option5", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option6", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option7", { ns: "alcsmk" }) }
  ];
  const alcScreenItems2 = [
    { id: 0, title: t("options.option8", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option9", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option10", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option11", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option12", { ns: "alcsmk" }) }
  ];
  const alcScreenItems3 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option13", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option14", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option15", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option16", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems4 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option13", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option14", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option15", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option16", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems5 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option13", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option14", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option15", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option16", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems6 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option13", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option14", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option15", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option16", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems7 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option13", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option14", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option15", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option16", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems8 = [
    { id: 0, title: t("options.option3", { ns: "alcsmk" }) },
    { id: 1, title: t("options.option13", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option14", { ns: "alcsmk" }) },
    { id: 3, title: t("options.option15", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option16", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems9 = [
    { id: 0, title: t("options.option1", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option17", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option18", { ns: "alcsmk" }) }
  ];
  const alcFullScreenItems10 = [
    { id: 0, title: t("options.option1", { ns: "alcsmk" }) },
    { id: 2, title: t("options.option17", { ns: "alcsmk" }) },
    { id: 4, title: t("options.option18", { ns: "alcsmk" }) }
  ];

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);

  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  /* const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  ); */

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("activityName" in fieldValues) {
      // temp.activityName =
      //   fieldValues.activityName === "None" ? "Value in this field is required." : "" ;
      temp.activityName = fieldValues.activityName
        ? ""
        : "Value in this field is required.";
    }
    if ("preHR" in fieldValues)
      temp.preHR = fieldValues.preHR ? "" : "Value in this field is required.";
    if ("postHR" in fieldValues)
      temp.postHR = fieldValues.postHR ? "" : "Value in this field is required.";
    if ("results" in fieldValues)
      temp.results = fieldValues.results ? "" : "Value in this field is required.";
    if ("rpeBorg" in fieldValues)
      temp.rpeBorg = fieldValues.rpeBorg ? "" : "Value in this field is required.";
    if ("symptoms" in fieldValues)
      temp.symptoms = fieldValues.symptoms ? "" : "Value in this field is required.";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // eslint-disable-next-line
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);
  //const [errors, setErrors] = useState({});

  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current user name
  //const currusername = localStorage.getItem("username");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Creating axios object for form data insertion
  const axiosInsertFormData = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  const handleSubmit = async (e, t) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    if (validate()) {
      //alert("Validation Success");

      try {
        alert(t("alerts.aConfirm"));

        //Show warning snackbar with alert
        // handleClick(
        //   "warning",
        //   "Warning",
        //   "Data once inserted cannot be deleted or modified again. Are you sure you want to Continue? — <strong>check it out!</strong>"
        // );

        /* For testing/debugging purpose */
        // alert("user id is: " + userid);
        // alert("dateTime is: " + values["dateTime"]);
        // alert("activityName is: " + values["activityName"]);
        // alert("preHR is: " + values["preHR"]);
        // alert("postHR is: " + values["postHR"]);
        // alert("results is: " + values["results"]);
        // alert("rpeBorg is: " + values["rpeBorg"]);
        // alert("symptoms is: " + values["symptoms"]);

        // eslint-disable-next-line
        const response = await axiosInsertFormData
          .post("/insertalcoholtobacco", {
            userid: userid,
            //dateTime: values["dateTime"],
            dateTime: isoDate,
            smk: values["smk"],
            alcScreen1: values["alcScreen1"],
            alcScreen2: values["alcScreen2"],
            alcScreen3: values["alcScreen3"],
            alcFullScreen4: values["alcFullScreen4"],
            alcFullScreen5: values["alcFullScreen5"],
            alcFullScreen6: values["alcFullScreen6"],
            alcFullScreen7: values["alcFullScreen7"],
            alcFullScreen8: values["alcFullScreen8"],
            alcFullScreen9: values["alcFullScreen9"],
            alcFullScreen10: values["alcFullScreen10"]
          })
          .then(
            (response) => {
              console.log(response);
              //Show success snackbar with alert
              handleClick(
                "success",
                t("snackHead.sSuccess"),
                t("alerts.aSuccess")
              );
              //alert("Data inserted Successfully!");
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in submitting the form....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              //alert("Data Insertion Failure!");
              //Show error snackbar with alert
              handleClick(
                "error",
                t("snackHead.sError"),
                t("alerts.aInsertion")
              );
            }
          );
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
        //alert("Insertion failure! " + err);
        //Show error snackbar with alert
        handleClick("error", t("snackHead.sError"), t("alerts.aExcept"));
      }

      resetForm();
    } else {
      //alert("Form validation fails!");
      //Show error snackbar with alert
      handleClick("error", t("snackHead.sError"), t("alerts.aValidation"));
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Form>
        <Grid container>
          {/* Snackbar with alert */}
          <div>
            <Snackbar
              open={open}
              //message="Material UI snackbar"
              TransitionComponent={(props) => (
                <Slide {...props} direction="left" />
              )}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              //action={action}
            >
              <Alert
                severity={severity}
                variant="filled"
                //icon={false}
                onClose={handleClose}
              >
                <AlertTitle>{alerttitle}</AlertTitle>
                {alertmessage}
              </Alert>
            </Snackbar>
          </div>

          {/* <Grid item xs={6}>
          <p align="left">
            <b>Name: {currusername} </b>
          </p>
        </Grid>
        <Grid item xs={6} align="right">
          <Controls.Input
            name="dateTime"
            label="Date/Time of Form Filling"
            value={values.dateTime}
            onChange={handleInputChange}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid> */}

          <Grid item xs={12} align="right">
            {/* Table Goes here */}
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 100 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      <b>{t("qTopInfo", { ns: "alcsmk" })}</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.description}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {row.description}
                      </TableCell>
                      <TableCell align="left">{row.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} align="left">
            <p align="left"></p>
            <p align="left">
              <b>{t("questions.tobHead", { ns: "alcsmk" })}</b>
            </p>
            <Controls.RadioGroup
              name="smk"
              label={t("questions.q1", { ns: "alcsmk" })}
              value={values.smk}
              onChange={handleInputChange}
              items={smkFlag}
            />
            <p align="left">
              <b>{t("questions.alcHead", { ns: "alcsmk" })}</b>
            </p>
            <Controls.RadioGroup
              name="alcScreen1"
              label={t("questions.q2", { ns: "alcsmk" })}
              value={values.alcScreen1}
              onChange={handleInputChange}
              items={alcScreenItems1}
            />

            <Controls.RadioGroup
              name="alcScreen2"
              label={t("questions.q3", { ns: "alcsmk" })}
              value={values.alcScreen2}
              onChange={handleInputChange}
              items={alcScreenItems2}
            />

            <Controls.RadioGroup
              name="alcScreen3"
              label={t("questions.q4", { ns: "alcsmk" })}
              value={values.alcScreen3}
              onChange={handleInputChange}
              items={alcScreenItems3}
            />

            <p align="left">
              <b>{t("questions.fsfqHead", { ns: "alcsmk" })}</b>
            </p>

            <Controls.RadioGroup
              name="alcFullScreen4"
              label={t("questions.q5", { ns: "alcsmk" })}
              value={values.alcFullScreen4}
              onChange={handleInputChange}
              items={alcFullScreenItems4}
            />

            <Controls.RadioGroup
              name="alcFullScreen5"
              label={t("questions.q6", { ns: "alcsmk" })}
              value={values.alcFullScreen5}
              onChange={handleInputChange}
              items={alcFullScreenItems5}
            />

            <Controls.RadioGroup
              name="alcFullScreen6"
              label={t("questions.q7", { ns: "alcsmk" })}
              value={values.alcFullScreen6}
              onChange={handleInputChange}
              items={alcFullScreenItems6}
            />

            <Controls.RadioGroup
              name="alcFullScreen7"
              label={t("questions.q8", { ns: "alcsmk" })}
              value={values.alcFullScreen7}
              onChange={handleInputChange}
              items={alcFullScreenItems7}
            />

            <Controls.RadioGroup
              name="alcFullScreen8"
              label={t("questions.q9", { ns: "alcsmk" })}
              value={values.alcFullScreen8}
              onChange={handleInputChange}
              items={alcFullScreenItems8}
            />

            <Controls.RadioGroup
              name="alcFullScreen9"
              label={t("questions.q10", { ns: "alcsmk" })}
              value={values.alcFullScreen9}
              onChange={handleInputChange}
              items={alcFullScreenItems9}
            />

            <Controls.RadioGroup
              name="alcFullScreen10"
              label={t("questions.q11", { ns: "alcsmk" })}
              value={values.alcFullScreen10}
              onChange={handleInputChange}
              items={alcFullScreenItems10}
            />

            <div border="2" align="center">
              &nbsp;&nbsp;
              <Controls.Button
                type="submit"
                onClick={(event) => {
                  handleSubmit(event, t);
                }}
                text={t("footer.send", { ns: "alcsmk" })}
              />
              &nbsp;&nbsp;&nbsp;
              <Controls.Button
                text={t("footer.reset", { ns: "alcsmk" })}
                onClick={resetForm}
                color="inherit"
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </Suspense>
  );
}
