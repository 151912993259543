import React, { Suspense, useState } from "react";
import { useTranslation } from "react-i18next";
import VideoModalWindow from './VideoModalWindow';
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import ConfigData from "./config/Config.json";
import axios from 'axios';

const warmUpVideoURL = `${ConfigData.protoipaport}/api/video/play/1`; // WarmUp Video URL
const circuitVideoURL = `${ConfigData.protoipaport}/api/video/play/2`; // Circuit Video URL
const coolDownVideoURL = `${ConfigData.protoipaport}/api/video/play/3`; // Cool Down Video URL

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const ExerciseSched = () => {
    const { t } = useTranslation(["translation", "crinfo"]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState(null);

    const handleOpenModal = (e, fileUrl) => {
        fetchVideo(fileUrl);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const fetchVideo = async (url) => {
        try {
            const response = await axios.get(url, {
                responseType: 'arraybuffer',
            });

            const videoBlob = new Blob([response.data], { type: 'video/mp4' });
            const videoUrl = URL.createObjectURL(videoBlob);

            setVideoSrc(videoUrl);
        } catch (error) {
            console.error('Error fetching video:', error);
            alert('Failed to load video.');
        }
    };

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">
                                        <div className="col-md-12 icon-box" data-aos="fade-up">
                                            <i className="bx bx-run"></i>
                                            <h4>
                                                {t("header.h4f", { ns: "crinfo" })}
                                            </h4>
                                            <p> <b>{t("para.p19a", { ns: "crinfo" })}</b>  {t("para.p19b", { ns: "crinfo" })}</p>
                                            <p> <b>{t("para.p20a", { ns: "crinfo" })}</b>  {t("para.p20b", { ns: "crinfo" })} </p>
                                            <p> <b>{t("para.p21a", { ns: "crinfo" })}</b>  {t("para.p21b", { ns: "crinfo" })}</p>
                                            <p> <b>{t("para.p22a", { ns: "crinfo" })}</b>  {t("para.p22b", { ns: "crinfo" })}</p>
                                        </div>

                                        <div className="col-md-7 icon-box" data-aos="fade-up" data-aos-delay="200">
                                            <i className="bx bx-link"></i>
                                            <h4>
                                                {t("header.h4b", { ns: "crinfo" })}
                                                {isModalOpen && <VideoModalWindow onClose={handleCloseModal} videoSrc={videoSrc} />}
                                            </h4>

                                            <ListGroup>
                                                {/* Warm Up Video 1 */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            window.open(
                                                                "https://www.youtube.com/watch?v=7zSQlccEGlQ",
                                                                "_Myblank",
                                                                "width=1200, height=950"
                                                            )
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(1) </b>{t("para.p9a1", { ns: "crinfo" })}{" "}
                                                    </Link>
                                                </h5>

                                                {/* Warm Up Video 2 */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            handleOpenModal(event, warmUpVideoURL)
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(2) </b>{t("para.p9a2", { ns: "crinfo" })}{" "}
                                                    </Link>
                                                </h5>

                                                {/* Circuit Video 1 */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            window.open(
                                                                "https://www.youtube.com/watch?v=SK-saFMcAn4",
                                                                "_Myblank",
                                                                "width=1200, height=950"
                                                            )
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(3) </b>{t("para.p9b1", { ns: "crinfo" })}{" "}
                                                    </Link>
                                                </h5>

                                                {/* Circuit Video 2 */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            handleOpenModal(event, circuitVideoURL)
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(4) </b>{t("para.p9b2", { ns: "crinfo" })}{" "}
                                                    </Link>
                                                </h5>

                                                {/* Cool Down Video 1 */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            window.open(
                                                                "https://www.youtube.com/watch?v=2Il-OJGxLdw",
                                                                "_Myblank",
                                                                "width=1200, height=950"
                                                            )
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(5) </b>{t("para.p9c1", { ns: "crinfo" })}{" "}
                                                    </Link>                                                
                                                </h5>

                                                {/* Cool Down Video 2 */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            handleOpenModal(event, coolDownVideoURL)
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(6) </b>{t("para.p9c2", { ns: "crinfo" })}{" "}
                                                    </Link>
                                                </h5>

                                                 {/* Monitoring (RPE/BORG) Scale Video */}
                                                <h5>
                                                    <Link
                                                        className="list-group-item list-group-item-action"
                                                        tag="a"
                                                        to="#"
                                                        onClick={(event) =>
                                                            window.open(
                                                                "https://www.youtube.com/watch?v=SLJUJjD4U3s",
                                                                "_Myblank",
                                                                "width=1200, height=950"
                                                            )
                                                        }
                                                        action
                                                    >
                                                        {" "}
                                                        <b>(7) </b>{t("para.p10", { ns: "crinfo" })}{" "}
                                                    </Link>
                                                    {/* <a
                                                        target="_Myblank"
                                                        rel="noopener noreferrer"
                                                        href="https://www.youtube.com/watch?v=SLJUJjD4U3s"
                                                        onClick={() =>
                                                            window.open(
                                                                "https://www.youtube.com/watch?v=SLJUJjD4U3s",
                                                                "_Myblank",
                                                                "width=1200, height=950"
                                                            )
                                                        }
                                                    >
                                                        <p>{t("para.p10", { ns: "crinfo" })}</p>
                                                    </a> */}
                                                </h5>
                                            </ListGroup>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense>
    );
};

export default ExerciseSched;
