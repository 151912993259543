import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Activities from "../pages/Patient/Activities";
import CRHead from "./CRHeading";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: "#3c44b126"
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526"
    },
    background: {
      default: "#f4f5fd"
    }
  },
  // shape:{
  //   borderRadius: '12px'
  // },
  // overrides: {
  //   MuiAppBar: {
  //     root: {
  //       transform: 'translateZ(0)'
  //     }
  //   }
  // },
  props: {
    MuiIconButton: {
      disableRipple: true
    }
  }
});

const useStyles = makeStyles({
  appMain: {
    paddingLeft: "0px",
    width: "100%"
  }
});

const ExerciseDiary = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Suspense fallback={<Loader />}>
        <section id="contact" className="contact">
          <div className="section-title" align="center">
            <h2><CRHead /></h2>
          </div>

          <Container className="container">
            <Row className="row">
              <Menu />
              <Col className="col-lg-9 info">
                <p align="center">
                  <div className="section-title" align="center">
                    <h3>{t("edHead")}</h3>
                  </div>
                  {/* Calling to the methods */}
                  {/* <div className="messages">{message}</div> */}

                  <ListGroup>
                    <ThemeProvider theme={theme}>
                      <div className={classes.appMain}>
                        <Activities />
                      </div>
                      <CssBaseline />
                    </ThemeProvider>
                  </ListGroup>
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </Suspense>
    </>
  );
};
export default ExerciseDiary;
