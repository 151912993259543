import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import axios from "axios";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment
} from "@mui/material";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import { makeStyles } from "@mui/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PageHeader from "../../components/PageHeader";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ViewAllUsersCRSPL from "./ViewAllUsersCRSPL";
// import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
// import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
// import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
// import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
// import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

const BASE_URL = `${ConfigData.protoipaport}/activitiesdiary`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: "85%"
  },
  newButton: {
    position: "absolute",
    right: "0px",
    left: "15px"
  }
}));

const headCells = [
  { id: "dtTime", label: "Date/Time" },
  { id: "activityName", label: "Activity" },
  { id: "preHR", label: "PreHR" },
  { id: "postHR", label: "PostHR" },
  { id: "results", label: "Results", disableSorting: true },
  { id: "rpeBorg", label: "RPE/BORG" },
  { id: "symptoms", label: "Comments", disableSorting: true }
];

export default function ViewActivityFormCRSPL({ assigneduserid }) {
  const classes = useStyles();

  //To go back to the previous page
  const [tempViewActFlag, setTempViewActFlag] = useState(false);

  //Activities data
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  //Current user id
  //const curruserid = localStorage.getItem("userid");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(assigneduserid);

  //Creating axios object for getting all the activity types
  const axiosGetActivityRecords = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Activity Records
  useEffect(() => {
    const getactivityrecords = async () => {
      const res = await axiosGetActivityRecords.get(
        `/getuseractivitiesuid/${userid}`
      );

      const getActRec = await res.data;
      setRecords(await getActRec);
    };
    getactivityrecords();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.activityName.toLowerCase().includes(target.value)
          );
      }
    });
  };

  const handleShowConAllUserPage = (e) => {
    setTempViewActFlag(true);
  };

  return (
    <>
      {tempViewActFlag ? (
        <ViewAllUsersCRSPL />
      ) : (
        <div>
          <PageHeader
            title="Activities History"
            subTitle="Please check the history of your recorded activities here"
            icon={<ListAltOutlinedIcon fontSize="large" />}
          />

          <Toolbar>
            <Controls.Input
              label="Search Activities"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon //color="secondary"
                    />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />
            &nbsp;&nbsp;
            <Controls.Button
              text=" Back "
              variant="outlined"
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={(event) => {
                handleShowConAllUserPage(event);
              }}
              //setOpenPopup(true);
              //setRecordForEdit(null);
            />
          </Toolbar>

          <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting() === ""
                ? alert("No Records found!")
                : ""}

              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {new Date(item.dtTime).toLocaleDateString("en-IN")}
                  </TableCell>
                  <TableCell>{item.activityName}</TableCell>
                  <TableCell>{item.preHR}</TableCell>
                  <TableCell>{item.postHR}</TableCell>
                  <TableCell>{item.results}</TableCell>
                  <TableCell>{item.rpeBorg}</TableCell>
                  <TableCell>{item.symptoms}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </div>
      )}
    </>
  );
}
