import React, { useState } from "react";
import HADSDataTableCRSPL from "./HADSDataTableCRSPL";
import PHQ9DataTableCRSPL from "./PHQ9DataTableCRSPL";
import GAD7DataTableCRSPL from "./GAD7DataTableCRSPL";
import AlcTobDataTableCRSPL from "./AlcTobDataTableCRSPL";
import PageHeader from "../../components/PageHeader";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Controls from "../../components/controls/Controls";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Toolbar } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ViewAllPatientFormsCRSPL from "./ViewAllPatientFormsCRSPL";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0), //5
    padding: theme.spacing(3)
  }
}));

export default function ViewDataFormsCRSPL({
  assigneduserid,
  assignedusername
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [value, setValue] = useState("1");

  //To go back to the previous page
  const [tempViewFormsFlag, setTempViewFormsFlag] = useState(false);

  const handleShowConAllUserPage = (e) => {
    setTempViewFormsFlag(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {tempViewFormsFlag ? (
        <ViewAllPatientFormsCRSPL />
      ) : (
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label={t("header.tHead", { ns: "hads" })} value="1" />
                <Tab label={t("header.tHead", { ns: "phq9" })} value="2" />
                <Tab label={t("header.tHead", { ns: "gad7" })} value="3" />
                <Tab label={t("header.tHead", { ns: "alcsmk" })} value="4" />
              </TabList>
            </Box>

            <TabPanel value="1">
              <PageHeader
                title={t("header.h1crspl", { ns: "hads" })}
                subTitle={t("header.h2crspl", { ns: "hads" })}
                icon={<LocalHospitalOutlinedIcon fontSize="large" />}
              />

              <Paper className={classes.pageContent}>
                <HADSDataTableCRSPL
                  assigneduserid={assigneduserid}
                  assignedusername={assignedusername}
                />
              </Paper>
            </TabPanel>

            <TabPanel value="2">
              <PageHeader
                title={t("header.h1crspl", { ns: "phq9" })}
                subTitle={t("header.h2crspl", { ns: "phq9" })}
                icon={<MedicalInformationOutlinedIcon fontSize="large" />}
              />
              <Paper className={classes.pageContent}>
                <PHQ9DataTableCRSPL
                  assigneduserid={assigneduserid}
                  assignedusername={assignedusername}
                />
              </Paper>
            </TabPanel>

            <TabPanel value="3">
              <PageHeader
                title={t("header.h1crspl", { ns: "gad7" })}
                subTitle={t("header.h2crspl", { ns: "gad7" })}
                icon={<GradingOutlinedIcon fontSize="large" />}
              />
              <Paper className={classes.pageContent}>
                <GAD7DataTableCRSPL
                  assigneduserid={assigneduserid}
                  assignedusername={assignedusername}
                />
              </Paper>
            </TabPanel>

            <TabPanel value="4">
              <PageHeader
                title={t("header.h1crspl", { ns: "alcsmk" })}
                subTitle={t("header.h2crspl", { ns: "alcsmk" })}
                icon={<LiquorOutlinedIcon fontSize="large" />}
              />
              <Paper className={classes.pageContent}>
                <AlcTobDataTableCRSPL
                  assigneduserid={assigneduserid}
                  assignedusername={assignedusername}
                />
              </Paper>
            </TabPanel>
          </TabContext>
          <Toolbar>
            <Controls.Button
              text={t("footer.back", { ns: "alcsmk" })} //or This key is available in every NS
              variant="outlined"
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={(event) => {
                handleShowConAllUserPage(event);
              }}
              //setOpenPopup(true);
              //setRecordForEdit(null);
            />
          </Toolbar>
        </Box>
      )}
    </>
  );
}
