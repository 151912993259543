import React, { useState, Suspense } from "react";
import { Grid, Slide } from "@mui/material";
import axios from "axios";
import { useForm, Form } from "../../components/useForm";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
//import IconButton from "@mui/material/IconButton";
//import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

//import * as employeeService from "../../services/employeeService";

const initialFValues = {
  usid: 0,
  dateTime: new Date().toLocaleString(),
  //dateTime: new Date(),
  phq1: 0,
  phq2: 0,
  phq3: 0,
  phq4: 0,
  phq5: 0,
  phq6: 0,
  phq7: 0,
  phq8: 0,
  phq9: 0,
  diffLevel: "Not difficult at all"
};

const BASE_URL = `${ConfigData.protoipaport}/assessmentforms`;

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

export default function PHQ9Form() {
  const { t } = useTranslation(["translation", "phq9"]);

  const phqItems = [
    { id: 0, title: t("options.option1", { ns: "phq9" }) },
    { id: 1, title: t("options.option2", { ns: "phq9" }) },
    { id: 2, title: t("options.option3", { ns: "phq9" }) },
    { id: 3, title: t("options.option4", { ns: "phq9" }) }
  ];

  const diffLevelItems = [
    { id: "Not difficult at all", title: t("options.option5", { ns: "phq9" }) },
    { id: "Somewhat difficult", title: t("options.option6", { ns: "phq9" }) },
    { id: "Very difficult", title: t("options.option7", { ns: "phq9" }) },
    { id: "Extremely difficult", title: t("options.option8", { ns: "phq9" }) }
  ];

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);

  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const action = (
  //   <React.Fragment>
  //     <Button color="secondary" size="small" onClick={handleClose}>
  //       UNDO
  //     </Button>
  //     <IconButton
  //       size="small"
  //       aria-label="close"
  //       color="inherit"
  //       onClick={handleClose}
  //     >
  //       <CloseIcon fontSize="small" />
  //     </IconButton>
  //   </React.Fragment>
  // );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("activityName" in fieldValues) {
      // temp.activityName =
      //   fieldValues.activityName === "None" ? "This field is required." : "" ;
      temp.activityName = fieldValues.activityName
        ? ""
        : "Value in this field is required.";
    }
    if ("preHR" in fieldValues)
      temp.preHR = fieldValues.preHR ? "" : "Value in this field is required.";
    if ("postHR" in fieldValues)
      temp.postHR = fieldValues.postHR ? "" : "Value in this field is required.";
    if ("results" in fieldValues)
      temp.results = fieldValues.results ? "" : "Value in this field is required.";
    if ("rpeBorg" in fieldValues)
      temp.rpeBorg = fieldValues.rpeBorg ? "" : "Value in this field is required.";
    if ("symptoms" in fieldValues)
      temp.symptoms = fieldValues.symptoms ? "" : "Value in this field is required.";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // eslint-disable-next-line
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);
  //const [errors, setErrors] = useState({});

  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current user name
  //const currusername = localStorage.getItem("username");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Creating axios object for form data insertion
  const axiosInsertFormData = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  const handleSubmit = async (e, t) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    if (validate()) {
      //alert("Validation Success");

      try {
        alert(t("alerts.aConfirm"));

        //Show warning snackbar with alert
        // handleClick(
        //   "warning",
        //   "Warning",
        //   "Data once inserted cannot be deleted or modified again. Are you sure you want to Continue? — <strong>check it out!</strong>"
        // );

        /* For testing/debugging purpose */
        // alert("user id is: " + userid);
        // alert("dateTime is: " + values["dateTime"]);
        // alert("activityName is: " + values["activityName"]);
        // alert("preHR is: " + values["preHR"]);
        // alert("postHR is: " + values["postHR"]);
        // alert("results is: " + values["results"]);
        // alert("rpeBorg is: " + values["rpeBorg"]);
        // alert("symptoms is: " + values["symptoms"]);

        // eslint-disable-next-line
        const response = await axiosInsertFormData
          .post("/insertphq9", {
            userid: userid,
            //dateTime: values["dateTime"],
            dateTime: isoDate,
            phq1: values["phq1"],
            phq2: values["phq2"],
            phq3: values["phq3"],
            phq4: values["phq4"],
            phq5: values["phq5"],
            phq6: values["phq6"],
            phq7: values["phq7"],
            phq8: values["phq8"],
            phq9: values["phq9"],
            diffLevel: values["diffLevel"]
          })
          .then(
            (response) => {
              console.log(response);
              //Show success snackbar with alert
              handleClick(
                "success",
                t("snackHead.sSuccess"),
                t("alerts.aSuccess")
              );
              //alert("Data inserted Successfully!");
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in inserting the Form Data....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              //alert("Activity Insertion Failure!");
              //Show error snackbar with alert
              handleClick(
                "error",
                t("snackHead.sError"),
                t("alerts.aInsertion")
              );
            }
          );
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
        //alert("Insertion failure! " + err);
        //Show error snackbar with alert
        handleClick("error", t("snackHead.sError"), t("alerts.aExcept"));
      }

      resetForm();
    } else {
      //alert("Form validation fails!");
      //Show error snackbar with alert
      handleClick("error", t("snackHead.sError"), t("alerts.aValidation"));
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Form>
        <Grid container>
          {/* Snackbar with alert */}
          <div>
            <Snackbar
              open={open}
              //message="Material UI snackbar"
              TransitionComponent={(props) => (
                <Slide {...props} direction="left" />
              )}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              //action={action}
            >
              <Alert
                severity={severity}
                variant="filled"
                //icon={false}
                onClose={handleClose}
              >
                <AlertTitle>{alerttitle}</AlertTitle>
                {alertmessage}
              </Alert>
            </Snackbar>
          </div>

          {/* <Grid item xs={6}>
          <p align="left">
            <b>Name: {currusername} </b>
          </p>
        </Grid>
        <Grid item xs={6} align="right">
          <Controls.Input
            name="dateTime"
            label="Date/Time of Form Filling"
            value={values.dateTime}
            onChange={handleInputChange}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid> */}

          <Grid item xs={12} align="left">
            <p align="left">
              <b>{t("titleQ", { ns: "phq9" })}</b>
            </p>

            <Controls.RadioGroup
              name="phq1"
              label={t("questions.q1", { ns: "phq9" })}
              value={values.phq1}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq2"
              label={t("questions.q2", { ns: "phq9" })}
              value={values.phq2}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq3"
              label={t("questions.q3", { ns: "phq9" })}
              value={values.phq3}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq4"
              label={t("questions.q4", { ns: "phq9" })}
              value={values.phq4}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq5"
              label={t("questions.q5", { ns: "phq9" })}
              value={values.phq5}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq6"
              label={t("questions.q6", { ns: "phq9" })}
              value={values.phq6}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq7"
              label={t("questions.q7", { ns: "phq9" })}
              value={values.phq7}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq8"
              label={t("questions.q8", { ns: "phq9" })}
              value={values.phq8}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="phq9"
              label={t("questions.q9", { ns: "phq9" })}
              value={values.phq9}
              onChange={handleInputChange}
              items={phqItems}
            />

            <Controls.RadioGroup
              name="diffLevel"
              label={t("questions.qstatus", { ns: "phq9" })}
              value={values.diffLevel}
              onChange={handleInputChange}
              items={diffLevelItems}
            />

            <div border="2" align="center">
              &nbsp;&nbsp;
              <Controls.Button
                type="submit"
                onClick={(event) => {
                  handleSubmit(event, t);
                }}
                text={t("footer.send", { ns: "phq9" })}
              />
              &nbsp;&nbsp;&nbsp;
              <Controls.Button
                text={t("footer.reset", { ns: "phq9" })}
                onClick={resetForm}
                color="inherit"
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </Suspense>
  );
}
