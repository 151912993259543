import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";


// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Features = () => {
  const { t } = useTranslation(["translation", "appfeatures"]);

  return (
    <Suspense fallback={<Loader />}>
      <>
        <section id="features" className="features">
          <div className="container">
            <div className="section-title">
              <h2>{t("Header.hAppFeat", { ns: "appfeatures" })}</h2>
              <p>
                {t("Header.hText", { ns: "appfeatures" })}
              </p>
            </div>

            <div className="row no-gutters">
              <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                <div className="content d-flex flex-column justify-content-center">
                  <div className="row">
                    <div className="col-md-6 icon-box" data-aos="fade-up">
                      <i className="bx bx-receipt"></i>
                      <h4>{t("Features.f1", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d1", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <i className="bx bx-cube-alt"></i>
                      <h4>{t("Features.f2", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d2", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <i className="bx bx-images"></i>
                      <h4>{t("Features.f3", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d3", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="300"
                    >
                      <i className="bx bx-shield"></i>
                      <h4>{t("Features.f8", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d8", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <i className="bx bx-atom"></i>
                      <h4>{t("Features.f5", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d5", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="500"
                    >
                      <i className="bx bx-id-card"></i>
                      <h4>{t("Features.f6", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d6", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <i className="bx bx-id-card"></i>
                      <h4>{t("Features.f7", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d7", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="700"
                    >
                      <i className="bx bx-id-card"></i>
                      <h4>{t("Features.f4", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d4", { ns: "appfeatures" })}
                      </p>
                    </div>
                    <div
                      className="col-md-6 icon-box"
                      data-aos="fade-up"
                      data-aos-delay="800"
                    >
                      <i className="bx bx-id-card"></i>
                      <h4>{t("Features.f9", { ns: "appfeatures" })}</h4>
                      <p>
                        {t("Description.d9", { ns: "appfeatures" })}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                data-aos="fade-left"
                data-aos-delay="100"
              >
                <img src="assets/img/features.svg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </section>
      </>
    </Suspense>
  );
};

export default Features;
