import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const RPEScale = () => {
    const { t } = useTranslation(["translation", "rpescale"]);

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t("header.h2", { ns: "rpescale" })}</h2>
                            {/* <p>
                                  {t("para.p1", { ns: "kyhh" })}
                               </p> */}
                        </div>

                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">

                                        <div className="col-md-12 icon-box" data-aos="fade-up">
                                            <i className="bx bx-receipt"></i>
                                            {/* <h4>{t("header.h4a", { ns: "rpescale" })}</h4> */}
                                            <p>
                                                {t("para.p1", { ns: "rpescale" })}
                                            </p>
                                        </div>


                                        <div
                                            //className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                            data-aos="fade-up"
                                            align="center"
                                        >
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        {/* <TableRow>
                                                            <TableCell align="center" colSpan="4"><b>{t("table.r1c1", { ns: "dietaryadvice" })}</b></TableCell>
                                                        </TableRow> */}
                                                    </TableHead>
                                                    <TableBody>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s1", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r1", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p2", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s2", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r2", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p3", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s3", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r2", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p3", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s4", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r3", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p4", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s5", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r3", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p4", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s6", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r4", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p5", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s7", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r5", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{""}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s8", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r6", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p6", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s9", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r6", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p6", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s10", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r7", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p7", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s11", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r7", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p7", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s12", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r8", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p8", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s13", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r8", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p8", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s14", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r9", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p9", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell align="left"><b>{t("score.s15", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left"><b>{t("rating.r10", { ns: "rpescale" })}</b></TableCell>
                                                            <TableCell align="left">{t("para.p10", { ns: "rpescale" })}</TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense >

    );
};

export default RPEScale;
