import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import CRHead from "./CRHeading";
//import Home from "./Home";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import ConfigData from "./config/Config.json";
import axios from "axios";

const GET_URL = `${ConfigData.protoipaport}/api/test`;

const Dashboard = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [message, setMessage] = useState("User Content is displayed Here");
  // eslint-disable-next-line
  const [error, setError] = useState(false);
  // eslint-disable-next-line
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [statusCode, setStatusCode] = useState("");

  const token = localStorage.getItem("token");

  // eslint-disable-next-line
  const authAxios = axios.create({
    baseURL: GET_URL,
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/Login");
    }
  }, [navigate]);

  /* const handleProfileData = async (e) => {
    e.preventDefault();
    //alert("Inside Profile.");
    // console.log({
    //   message: message
    // });

    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/user").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  }; */

  /* const handlePublicData = async (e) => {
    e.preventDefault();
    //alert("Inside Public.");
    // console.log({
    //   message: message
    // });

    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/all").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  }; 

  const handlePatientData = async (e) => {
    e.preventDefault();
    //alert("Inside Patient.");
    // console.log({
    //   message: message
    // });

    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/patient").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleCRSPLData = async (e) => {
    e.preventDefault();
    //alert("Inside CRSPL.");
    // console.log({
    //   message: message
    // });

    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/crspl").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleLHCPData = async (e) => {
    e.preventDefault();
    //alert("Inside LHCP.");
    // console.log({
    //   message: message
    // });

    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/lhcp").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleHERPAppData = async (e) => {
    e.preventDefault();
    //alert("Inside HerpApp.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/herpapp").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  }; 

  const handleCREXData = async (e) => {
    e.preventDefault();
    //alert("Inside CREX.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/crex").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleExProtoData = async (e) => {
    e.preventDefault();
    //alert("Inside ExProto.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/exproto").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleDitAdvData = async (e) => {
    e.preventDefault();
    //alert("Inside DitAdv.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/ditadv").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleKYHSData = async (e) => {
    e.preventDefault();
    //alert("Inside KYHS.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/kyhs").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleEDUVData = async (e) => {
    e.preventDefault();
    //alert("Inside EDUV.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/eduv").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleHEXVData = async (e) => {
    e.preventDefault();
    //alert("Inside HEXV.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("User Content is displayed Here");
      await authAxios.get("/hexv").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem(
                "message",
                "Sorry! You are not authorized to view this Resource."
              );
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  };

  const handleDownloadsData = async (e) => {
    e.preventDefault();
    //alert("Inside Downloads.");
    // console.log({
    //   message: message
    // });
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      setMessage("Downloading.....");
      await authAxios.get("/downloads").then(
        (response) => {
          console.log(response.data);
          localStorage.setItem("message", response.data);
          setMessage(localStorage.getItem("message"));
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem("message", "Downloading.....");
              setMessage(localStorage.getItem("message"));
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }

          setStatusCode(error.statusCode);
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          console.log(statusCode);
        }
      );

      console.log(JSON.stringify(localStorage.getItem("token")));
      //console.log(response?.data);
      //setProp();
      // console.log(response?.accessToken);
      // console.log(response.data);
      // console.log(JSON.stringify(localStorage.getItem("message")));

      setSuccess(true);
      setError(false);
    } catch (err) {
      console.log(err.response);
      console.log(err.message);

      if (!err?.response) {
        setError("No Server Response");
      } else if (err.response?.status === 400) {
        setError("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setError("Unauthorized");
      } else {
        setError("Get Request Failed");
      }
      //errRef.current.focus();
    }

    setMessage(localStorage.getItem("message"));
  }; */

  // Showing success message
  // const successMessage = () => {
  //   return (
  //     <div
  //       className="success"
  //       style={{
  //         display: submitted ? "" : "none"
  //       }}
  //     >
  //       {/* <h2 color="green"> User {user} successfully registered!!</h2> */}
  //       <font color="green">
  //         Success! User {success} successfully registered!
  //       </font>
  //     </div>
  //   );
  // };

  // Showing error message if error is true
  // const errorMessage = () => {
  //   return (
  //     <div
  //       className="error"
  //       style={{
  //         display: error ? "" : "none"
  //       }}
  //     >
  //       {/* <h2 color="red">Please enter all the fields</h2> */}
  //       {/* <font color="red">Please enter all the fields</font> */}
  //       <font color="red"> Error! {error} </font>
  //     </div>
  //   );
  // };

  return (
    <>
      <section id="contact" className="contact">
        <div className="section-title" align="center">
          <h2><CRHead/></h2>
        </div>

        <Container className="container">
          <Row className="row">
            <Menu/>

            <Col className="col-lg-9 info">
              <p align="center">
                <div className="section-title" align="center">
                  <h3>Real Time Exercise Sessions</h3>
                </div>
                {/* Calling to the methods */}
                {/* <div className="messages">{message}</div> */}

                <ListGroup>
                  <Link
                    className="list-group-item list-group-item-action"
                    active
                    tag="a"
                    to="#"
                    action
                  >
                    {" "}
                    This is a Real Time Exercise Activity Window{" "}
                  </Link>
                </ListGroup>
              </p>
            </Col>
            {/* <Col className="col-lg-9 info">
              <p align="center"> */}
            {/* Calling to the methods */}
            {/* <div className="messages">{message}</div> */}

            {/* <div className="form-group mt-3">
                  <textarea
                    placeholder=<message />
                    className="form-control text-center"
                    name="message"
                    rows="20"
                    required
                    readonly="true"
                  ></textarea>
                </div> */}
            {/* </p>
            </Col> */}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Dashboard;
