import React, { useState, Suspense } from "react";
import DietaryAdvice from "../../components/DietaryAdvice";
import CrsplDownloads from "./CrsplDownloads";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0), //5
    padding: theme.spacing(3)
  }
}));

export default function CRSpecialistDownlodTabs() {
  const classes = useStyles();
  const { t } = useTranslation(["translation", "downloadtabs"]);

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Suspense fallback={<Loader />}> 
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("header.crspl.tHead1", { ns: "downloadtabs" })} value="1" />
              <Tab label={t("header.crspl.tHead2", { ns: "downloadtabs" })} value="2" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Paper className={classes.pageContent}>
              <DietaryAdvice />
            </Paper>
          </TabPanel>

          <TabPanel value="2">
            <Paper className={classes.pageContent}>
              <CrsplDownloads />
            </Paper>
          </TabPanel>

        </TabContext>
      </Box>
    </>
    </Suspense>
  );
}
