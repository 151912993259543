import React from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import ConfigData from "./config/Config.json";
import axios from "axios";
import CRHead from "./CRHeading";


//const GET_URL = "https://source.unsplash.com/random";
const GET_URL = `${ConfigData.protoipaport}/checkfilealgo/download`;

const ContactInfoCrPhOur = () => {
  const token = localStorage.getItem("token");

  // eslint-disable-next-line
  const authAxios = axios.create({
    baseURL: GET_URL,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  /* const downloadResource = async (e, fileId, fileName) => {
    e.preventDefault();
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      await authAxios.get(fileId).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          //alert(url);
          const link = document.createElement("a");
          link.href = url;
          //const fileName = `${+new Date()}.pdf`; // whatever your file name .
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);

          link.remove(); // you need to remove that elelment which is created before.
          console.log(response.data);
          localStorage.setItem("message", response.data);
          alert("Download Success!");
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem("message", "Downloading.....");
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          alert("Download Failure!");
        }
      );
      console.log(JSON.stringify(localStorage.getItem("token")));
    } catch (err) {
      console.log(err.response);
      console.log(err.message);
    }
  }; */

  return (
    <>
      <section id="contact" className="contact">
        <div className="section-title" align="center">
          <h2><CRHead/></h2>
        </div>

        <Container className="container">
          <Row className="row">
            <Menu
            // The child component will access using actionName
            // actionProfile={handleProfileData}
            // actionPublic={handlePublicData}
            // actionPatient={handlePatientData}
            // actionCRSPL={handleCRSPLData}
            // actionLHCP={handleLHCPData}
            // actionHERPApp={handleHERPAppData}
            // actionCREX={handleCREXData}
            // actionExProto={handleExProtoData}
            // actionDitAdv={handleDitAdvData}
            // actionKYHS={handleKYHSData}
            // actionEDUV={handleEDUVData}
            // actionHEXV={handleHEXVData}
            // actionDownloads={handleDownloadsData}
            />
            <Col className="col-lg-9 info">
              <p align="left">
                <div className="section-title" align="center">
                  <h3>Contact Info (CR Specialist / Physician / Our)</h3>
                </div>
                {/* Calling to the methods */}
                {/* <div className="messages">{message}</div> */}

                <ListGroup>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    action
                  >
                    {" "}
                    <b>Contact Info will go here:</b>{" "}
                  </Link>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    action
                  >
                    {" "}
                    -Line 1{" "}
                  </Link>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    action
                  >
                    {" "}
                    -Line 2{" "}
                  </Link>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    action
                  >
                    {" "}
                    -Line 3{" "}
                  </Link>
                </ListGroup>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ContactInfoCrPhOur;
