import { Suspense } from "react";
import * as React from 'react';
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import ConfigData from "./config/Config.json";
import axios from "axios";
//import HeartMed1 from "./HeartMed1";
// import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


const GET_URL = `${ConfigData.protoipaport}/checkfilealgo/download`;
// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const HeartMedicationSubAccord = () => {
    const { t } = useTranslation(["translation", "lwhd"]);
    const token = localStorage.getItem("token");
    const [expanded, setExpanded] = React.useState();

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    // eslint-disable-next-line
    const authAxios = axios.create({
        baseURL: GET_URL,
        method: "GET",
        responseType: "blob",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="contact" className="contact">
                    <Container className="container">
                        <Row className="row">

                            {/* <Col className="col-lg-12 info"> */}
                            <Col className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <section id="hmedsa">
                                    <div align="left" className="row">
                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c1", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* { <HeartMed1 />} */}
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p11", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c1a", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p12", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c1b", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p13", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c1c", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p14", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c1d", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p15", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c1e", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p16", { ns: "lwhd" })}</p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon/>}
                                                aria-controls="panel2-content"
                                                id="panel2-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c2", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p17a", { ns: "lwhd" })} <b>{t("para.p17b", { ns: "lwhd" })}</b></p>
                                                    <p> {t("para.p18", { ns: "lwhd" })} </p>
                                                    <p> {t("para.p19", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c2a", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p20", { ns: "lwhd" })} </p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel3-content"
                                                id="panel3-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c3", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p21a", { ns: "lwhd" })}<b>{t("para.p21b", { ns: "lwhd" })}</b> </p>
                                                    <p> {t("para.p22", { ns: "lwhd" })} </p>
                                                    <p> {t("para.p23", { ns: "lwhd" })} </p>
                                                    <p> {t("para.p24", { ns: "lwhd" })} </p>

                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel4-content"
                                                id="panel4-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c4", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p25a", { ns: "lwhd" })} <b>{t("para.p25b", { ns: "lwhd" })}</b></p>
                                                    <p> {t("para.p26", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c4a", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p27", { ns: "lwhd" })} </p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel5-content"
                                                id="panel5-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c5", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p28a", { ns: "lwhd" })}<b>{t("para.p28b", { ns: "lwhd" })}</b></p>
                                                    <p> {t("para.p29", { ns: "lwhd" })} </p>
                                                    <p> {t("para.p30", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c5a", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p31", { ns: "lwhd" })} </p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel6-content"
                                                id="panel6-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c6", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p32a", { ns: "lwhd" })}<b>{t("para.p32b", { ns: "lwhd" })}</b> </p>
                                                    <p> {t("para.p33", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c6a", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p34", { ns: "lwhd" })} </p>

                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel7-content"
                                                id="panel7-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c7", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div
                                                    className="col-md-12 icon-box"
                                                    data-aos="fade-up"
                                                >
                                                    {/* <i className="bx bx-receipt"></i> */}
                                                    <p> {t("para.p35a", { ns: "lwhd" })} <b>{t("para.p35b", { ns: "lwhd" })}</b></p>
                                                    <p> {t("para.p36", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c7a", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p37", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c7b", { ns: "lwhd" })}</h5>
                                                    <h5>{t("header.h5c7c", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p38", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c7d", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p39", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c7e", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p40", { ns: "lwhd" })} </p>
                                                    <h5>{t("header.h5c7f", { ns: "lwhd" })}</h5>
                                                    <p> {t("para.p41", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p42", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p43", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p44", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p45", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p46", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p47", { ns: "lwhd" })}</p>
                                                    <p> {t("para.p48", { ns: "lwhd" })}</p>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel8-content"
                                                id="panel8-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c8", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p> {t("para.p49", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c8a", { ns: "lwhd" })}</h5>
                                                <p> <b>{t("para.p50", { ns: "lwhd" })} </b></p>
                                                <p> <b>{t("para.p51", { ns: "lwhd" })} </b></p>
                                                <p> <b>{t("para.p52", { ns: "lwhd" })} </b></p>
                                                <h5>{t("header.h5c8b", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p53", { ns: "lwhd" })}</p>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel9-content"
                                                id="panel9-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c9", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <p> {t("para.p54", { ns: "lwhd" })} </p>
                                                <p> {t("para.p55", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c9a", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p56", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c9b", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p57a", { ns: "lwhd" })}<b>{t("para.p57b", { ns: "lwhd" })}</b> </p>
                                                <p> {t("para.p58", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c9c", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p59", { ns: "lwhd" })}</p>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
                                            <AccordionSummary
                                                // expandIcon={<ArrowForwardIcon />}
                                                aria-controls="panel10-content"
                                                id="panel10-header"
                                            >
                                                {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                                                <h5><b><u>{t("header.h3c10", { ns: "lwhd" })}</u></b></h5>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <h5>{t("header.h5c10a", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p60", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c10b", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p61", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c10c", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p62", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c10d", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p63", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c10e", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p64", { ns: "lwhd" })} </p>
                                                <h5>{t("header.h5c10f", { ns: "lwhd" })}</h5>
                                                <p> {t("para.p65", { ns: "lwhd" })}</p>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </section>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        </Suspense>
    );
};
export default HeartMedicationSubAccord;