import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Dashboard from "./Dashboard";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const auth = localStorage.getItem("token");

const Home = () => {
  const { t } = useTranslation(["translation", "home"]);

  return (
    <>
     <Suspense fallback={<Loader />}>
      {auth ? (
        <div> {<Dashboard />}</div>
      ) : (
        <section id="hero" className="d-flex align-items-center">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
                data-aos="fade-up"
              >
                <div>
                  <h1>{t("home.hmORG", { ns: "home" })}</h1>
                  <h2>
                    <ul>
                      <li type="square">
                      {t("home.hmWWD1", { ns: "home" })}
                      </li>
                      <li type="square">{t("home.hmWWD2", { ns: "home" })}</li>
                      <li type="square">{t("home.hmWWD3", { ns: "home" })}</li>
                    </ul>
                    {/* Cardiac Health Solutions helps and aims to facilitate the
                  patients, to takepart in cardiac rehabilitation program, with
                 the comfort of their home, using our mobile App. */}
                  </h2>
                  <Link to="/Login" className="download-btn">
                    <i className="bx bxl-play-store"></i> {t("home.hmGPlay", { ns: "home" })}
                  </Link>
                  <Link to="/Login" className="download-btn">
                    <i className="bx bxl-apple"></i> {t("home.hmAStore", { ns: "home" })}
                  </Link>
                </div>
              </div>
              <div
                className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 hero-img"
                data-aos="fade-up"
              >
                <img
                  src="assets/img/hero-img.png"
                  className="img-fluid animated"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      )}
      </Suspense>
    </>
  );
};

export default Home;
