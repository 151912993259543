import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import ConfigData from "../../components/config/Config.json";
import axios from "axios";
import { useTranslation } from "react-i18next";

//const GET_URL = "https://source.unsplash.com/random";
const BASE_URL = `${ConfigData.protoipaport}/checkfilealgo/download`;

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const PatientDownloads = () => {
    const { t } = useTranslation(["translation", "downloadtabs"]);
    const token = localStorage.getItem("token");

    const authAxios = axios.create({
        baseURL: BASE_URL,
        method: "GET",
        responseType: "blob",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    const downloadResource = async (e, fileId, fileName) => {
        e.preventDefault();
        try {
            //alert("Sending GET Request to get the resource, if authorized.");
            localStorage.removeItem("message");
            await authAxios.get(fileId).then(
                (response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    //alert(url);
                    const link = document.createElement("a");
                    link.href = url;
                    //const fileName = `${+new Date()}.pdf`; // whatever your file name .
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();

                    // clean up "a" element & remove ObjectURL
                    document.body.removeChild(link);
                    URL.revokeObjectURL(url);

                    link.remove(); // you need to remove that elelment which is created before.
                    console.log(response.data);
                    localStorage.setItem("message", response.data);
                    alert("Download Success!");
                },
                (error) => {
                    if (error.response) {
                        console.log(error.response.data);
                        const scode = error.response.status;
                        if (scode === 401 || scode === 403 || scode === 404) {
                            localStorage.setItem("message", "Downloading.....");
                        }
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }
                    console.log("Error is below: ");
                    console.log(error);
                    console.log(error.data.message);
                    alert("Download Failure!");
                }
            );
            console.log(JSON.stringify(localStorage.getItem("token")));
        } catch (err) {
            console.log(err.response);
            console.log(err.message);
        }
    };

    return (
        <Suspense fallback={<Loader />}>
        <>
            <section id="contact" className="contact">
                <Container className="container">
                    <Row className="row">
                        <Col className="col-lg-12 info">
                            <p align="left">
                               
                                <ListGroup>
                                    <Link
                                        className="list-group-item list-group-item-action"
                                        tag="a"
                                        to="#"
                                        onClick={(event) =>
                                            downloadResource(
                                                event,
                                                "/8886f20c-dcee-4981-b057-42c2a246a5a9",
                                                "Home Exercise routine and advice.pdf"
                                            )
                                        }
                                        //onClick={props.actionProfile}
                                        action
                                    >
                                        {" "}
                                        <b>1).</b> {t("header.patient.dwnLink1", { ns: "downloadtabs" })}
                                        {" "}
                                    </Link><br />
                                </ListGroup>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
        </Suspense>
    );
};
export default PatientDownloads;
