import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText
} from "@mui/material";

export default function SelectRPE(props) {
  const { name, label, value, error = null, onChange, options } = props;

  return (
    <FormControl variant="outlined" {...(error && { error: true })}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect label={label} name={name} value={value} onChange={onChange}>
        {/* <MenuItem value="None">None</MenuItem> */}
        {options.map((item,idx) => (
          <MenuItem key={item.id} value={item.value}>
            {/* {item.value} - {item.title} / {item.desc} */}
            {item.value} - {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
