import React from "react";

import Navbar from "./Navbar";

const Header = () => {
  return (
    <>
      <header id="header" className="fixed-top  header-transparent">
        <Navbar />
      </header>
    </>
  );
};

export default Header;
