import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const IsItSafeToEx = () => {
    const { t } = useTranslation(["translation", "rqhh"]);

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">
                                       
                                        <div className="col-md-12 icon-box" data-aos="fade-up">
                                            {/* <i className="bx bx-receipt"></i> */}
                                            <i className="bx bx-receipt"></i>
                                            <h4>{t("header.h4a", { ns: "rqhh" })}</h4>
                                            <p>
                                                {t("para.p1", { ns: "rqhh" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >
                                            {/* <i className="bx bx-shield"></i> */}
                                            <i className="bx bx-walk"></i>
                                            <h4>{t("header.h4b", { ns: "rqhh" })}</h4>
                                            <p> {t("para.p2", { ns: "rqhh" })} </p>
                                            <p> {t("para.p3", { ns: "rqhh" })} </p>
                                            <p> {t("para.p4", { ns: "rqhh" })} </p>
                                            <p> {t("para.p5", { ns: "rqhh" })} </p>
                                            <p> {t("para.p6", { ns: "rqhh" })} </p>
                                            <p> {t("para.p7", { ns: "rqhh" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="200"
                                        >
                                            <i className="bx bx-atom"></i>
                                            <h4>
                                                {t("header.h4c", { ns: "rqhh" })}
                                            </h4>
                                            <p>
                                                {t("para.p8", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p9", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p10", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p11", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p12", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p13", { ns: "rqhh" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <i className="bx bx-cube-alt"></i>
                                            <h4>
                                                {t("header.h4d", { ns: "rqhh" })}
                                            </h4>
                                            <p>
                                                {t("para.p14", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p15", { ns: "rqhh" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="400"
                                        >
                                            <i className="bx bx-shield"></i>
                                            <h4>
                                                {t("header.h4e", { ns: "rqhh" })}
                                            </h4>
                                            <p>
                                                {t("para.p16", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p17", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p18", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p19", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p20", { ns: "rqhh" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="500"
                                        >
                                            <i className="bx bx-star"></i>
                                            <h4>
                                                {t("header.h4f", { ns: "rqhh" })}
                                            </h4>
                                            <p>
                                                {t("para.p21", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p22", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p23", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p24", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p25", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p26", { ns: "rqhh" })}
                                            </p>
                                            <p>
                                                {t("para.p27", { ns: "rqhh" })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense>
    );
};

export default IsItSafeToEx;
