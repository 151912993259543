import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";


// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const FAQ = () => {
  const { t } = useTranslation(["translation", "faq"]);

  return (
    <Suspense fallback={<Loader />}>

      <>
        <section id="faq" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{t("Header.hFaq", { ns: "faq" })}</h2>
              <p>
                {t("Header.hText", { ns: "faq" })}
              </p>
            </div>

            <div className="accordion-list">
              <ul>
                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapse"
                    data-bs-target="#accordion-list-1"
                    href="/Home"
                  >
                    {t("Questions.q1", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-1"
                    className="collapse show"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a1", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-2"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q2", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-2"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a2", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-3"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q3", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-3"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a3", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-4"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q4", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-4"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a4", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="400">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-5"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q5", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-5"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a5", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="500">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-6"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q6", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-6"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a6", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="600">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-7"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q7", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-7"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a7", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="700">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-8"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q8", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-8"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a8", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="800">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-9"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q9", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-9"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a9", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="900">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-10"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q10", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-10"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a10", { ns: "faq" })}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="1000">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-11"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("Questions.q11", { ns: "faq" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-11"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {t("Answers.a11", { ns: "faq" })}
                    </p>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </section>
      </>
    </Suspense>
  );
};

export default FAQ;
