import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const ToService = () => {
    const { t } = useTranslation(["translation", "toservice"]);

    return (
        <Suspense fallback={<Loader />}>

            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t("header.h2a", { ns: "toservice" })}</h2>
                            <hr/>
                            <b><p align = "left">
                             {t("header.h2b", { ns: "toservice" })}
                            </p></b>
                            <p align = "left">
                                {t("para.p1", { ns: "toservice" })}
                            </p>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-12 icon-box" data-aos="fade-up">
                                            {/* <i className="bx bx-receipt"></i> */}
                                            <h4>{t("header.h4a", { ns: "toservice" })}</h4>
                                            <p>
                                                {t("para.p2", { ns: "toservice" })}
                                            </p>
                                            <p> {t("para.p3", { ns: "toservice" })}</p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >
                                            {/* <i className="bx bx-shield"></i> */}
                                            <h4>{t("header.h4b", { ns: "toservice" })}
                                            </h4>
                                           
                                            <p> {t("para.p4", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4a", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4b", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4c", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4d", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4e", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4f", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p4g", { ns: "toservice" })} </p>
                                            <p> {t("para.p5", { ns: "toservice" })} </p>
                                            <p> {t("para.p6a", { ns: "toservice" })} <b>{t("para.p6b", { ns: "toservice" })}</b> {t("para.p6c", { ns: "toservice" })}</p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="200"
                                        >
                                            {/* <i className="bx bx-atom"></i> */}
                                            <h4>
                                                {t("header.h4c", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p7", { ns: "toservice" })} </p>
                                            <p> {t("para.p8", { ns: "toservice" })} </p>
                                            <p> {t("para.p9", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            {/* <i className="bx bx-shield"></i> */}
                                            <h4>{t("header.h4d", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p10", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p10a", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p10b", { ns: "toservice" })} </p>
                                            <p> &nbsp;&nbsp;{t("para.p10c", { ns: "toservice" })} </p>
                                            <p> {t("para.p11", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="400"
                                        >
                                            <h4>{t("header.h4e", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p12", { ns: "toservice" })} </p>
                                            <p> {t("para.p13", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="500"
                                        >
                                            <h4>{t("header.h4f", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p14", { ns: "toservice" })} </p>
                                            <p> {t("para.p15", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="600"
                                        >
                                            <h4>{t("header.h4g", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p16", { ns: "toservice" })} </p>
                                            <p> {t("para.p17", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="700"
                                        >
                                            <h4>{t("header.h4h", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p18", { ns: "toservice" })} </p>
                                            <p> {t("para.p19", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="800"
                                        >
                                            <h4>{t("header.h4i", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p20", { ns: "toservice" })} </p>
                                            <p> {t("para.p21", { ns: "toservice" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="900"
                                        >
                                            <h4>{t("header.h4j", { ns: "toservice" })}
                                            </h4>
                                            <p> {t("para.p22", { ns: "toservice" })} </p>
                                            <p> {t("para.p23", { ns: "toservice" })} </p>
                                        </div>
                                        <hr/>
                                        <p> {t("para.p24a", { ns: "toservice" })} <b>{t("para.p24b", { ns: "toservice" })}</b></p>
                                        <p> {t("para.p25", { ns: "toservice" })} </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Suspense>

    );
};

export default ToService;
