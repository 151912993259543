import react from "react";

class DateDefault extends react.Component {
  constructor() {
    super();
    this.state = {
      date1: new Date().toISOString().slice(0, 10)
    };
  }
  selDate = (e) => {
    this.setState({ date1: e.target.value });
    const ag = this.calcAge();
    //alert(this.state.date1);
    this.props.setAge(ag);
    this.props.setDate(e.target.value);
  };

  // on change handler function added here
  handleDateUpdate = (e) => {
    this.selDate(e);
    const dateValue = e.target.value;
    alert(dateValue);

    // this.props.setAge(this.calcAge());
  };

  calcAge = () => {
    var today = new Date();
    var birthDate = new Date(this.state.date1);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age === -1) {
      alert("Date of birth cannot be more than the current date");
      age = 0;
      return age;
    } else {
      return age;
    }
  };

  render() {
    return (
      <div className="col-lg-3">
        <input
          type="date"
          name="date"
          //onChange={(e) => this.handleDateUpdate(e)}
          onChange={this.selDate}
          className="form-control"
          id="date"
          value={this.state.date1}
          autoComplete="off"
          required
        />
      </div>
    );
  }
}
export default DateDefault;
