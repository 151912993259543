import React from "react";
import Menu from "./Menu";
import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import { makeStyles } from "@mui/styles";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import DataForms from "../pages/Patient/DataForms";
import { useTranslation } from "react-i18next";
import CRHead from "./CRHeading";

const theme = createTheme({
  palette: {
    primary: {
      main: "#333996",
      light: "#3c44b126"
    },
    secondary: {
      main: "#f83245",
      light: "#f8324526"
    },
    background: {
      default: "#f4f5fd"
    }
  },
  // shape:{
  //   borderRadius: '12px'
  // },
  // overrides: {
  //   MuiAppBar: {
  //     root: {
  //       transform: 'translateZ(0)'
  //     }
  //   }
  // },
  props: {
    MuiIconButton: {
      disableRipple: true
    }
  }
});

const useStyles = makeStyles({
  appMain: {
    paddingLeft: "0px",
    width: "100%"
  }
});

const PatientDataForms = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <section id="contact" className="contact">
        <div className="section-title" align="center">
          <h2><CRHead/></h2>
        </div>

        <Container className="container">
          <Row className="row">
            <Menu />
            <Col className="col-lg-9 info">
              <p align="center">
                <div className="section-title" align="center">
                  <h3>{t("mHead")}</h3>
                </div>
                {/* Calling to the methods */}
                {/* <div className="messages">{message}</div> */}

                <ListGroup>
                  <ThemeProvider theme={theme}>
                    <div className={classes.appMain}>
                      <DataForms />
                    </div>
                    <CssBaseline />
                  </ThemeProvider>
                </ListGroup>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default PatientDataForms;
