import React from "react";

const Gallery = () => {
  return (
    <>
      <section id="gallery" className="gallery">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Gallery</h2>
            <p>
              This is our mobile App gallery section. Please, check out the
              same.
            </p>
          </div>
        </div>

        <div className="container-fluid" data-aos="fade-up">
          <div className="gallery-slider swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-1.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-1.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-2.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-2.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-3.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-3.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-4.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-4.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-5.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-5.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-6.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-6.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-7.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-7.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-8.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-8.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-9.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-9.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-10.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-10.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-11.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-11.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
              <div className="swiper-slide">
                <a
                  href="assets/img/gallery/gallery-12.png"
                  className="gallery-lightbox"
                  data-gall="gallery-carousel"
                >
                  <img
                    src="assets/img/gallery/gallery-12.png"
                    className="img-fluid"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Gallery;
