import { Suspense } from "react";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import Menu from "./Menu";
// import { Link } from "react-router-dom";
// import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import ConfigData from "./config/Config.json";
import axios from "axios";
import CRHead from "./CRHeading";
import AiimsProg from "./AiimsProg";
import ExerciseInst from "./ExerciseInst";
import ExerciseSched from "./ExerciseSched";
import ExerciseLevelPres from "./ExerciseLevelPres";
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';


const GET_URL = `${ConfigData.protoipaport}/checkfilealgo/download`;
// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CRInformation = () => {
  const { t } = useTranslation(["translation", "crinfo"]);
  const token = localStorage.getItem("token");
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // eslint-disable-next-line
  const authAxios = axios.create({
    baseURL: GET_URL,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  }); 

  return (
    <Suspense fallback={<Loader />}>
    <>
      <section id="contact" className="contact">
          <div className="section-title" align="center">
            <h2><CRHead /></h2>
          </div>

          <Container className="container">
            <Row className="row">
              <Menu />

              <Col className="col-lg-9 info">
                <section id="rqhh">
                  <div className="section-title" align="center">
                    <h3>{t("header.h2", { ns: "crinfo" })}</h3>
                  </div>
             
                  <div>
                    <Accordion  expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3a", { ns: "crinfo" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {<AiimsProg />}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion  expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon/>}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3b", { ns: "crinfo" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        {<ExerciseInst />}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion  expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon />}
                        aria-controls="panel3-content"
                        id="panel3-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3c", { ns: "crinfo" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        {<ExerciseSched />}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion  expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                      <AccordionSummary
                        // expandIcon={<ArrowForwardIcon />}
                        aria-controls="panel4-content"
                        id="panel4-header"
                      >
                        {/* <i className="bx bx-heart-circle icon-help"></i>{" "} */}
                        <h5><b><u>{t("header.h3d", { ns: "crinfo" })}</u></b></h5>
                      </AccordionSummary>
                      <AccordionDetails>
                        { <ExerciseLevelPres />}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </section>
              </Col>
            </Row>
          </Container>
        </section>
    </>
    </Suspense>
  );
};
export default CRInformation;
