import React from "react";
import Login from "./components/login/Login";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import Home from "./components/Home";
import Features from "./components/Features";
import WhatWeDo from "./components/WhatWeDo";
import CRExplained from "./components/CRExplained";
import CRHome from "./components/CRHome";
import KYHHealth from "./components/KYHHealth";
import SOHAilment from "./components/SOHAilment";
//import Details from "./components/Details";
import Gallery from "./components/Gallery";
import Testimonials from "./components/Testimonials";
import ToService from "./components/ToService";
import PrivPolicy from "./components/PrivPolicy";
//import Pricing from "./components/Pricing";
import Signup from "./components/Signup";
import FAQ from "./components/FAQ";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Logout from "./components/Logout";
import Messages from "./components/Messages";
import ExPhyActivity from "./components/ExPhyActivity";
import RQHeartHealth from "./components/RQHeartHealth";
import CRInformation from "./components/CRInformation";
import LivingWithHD from "./components/LivingWithHD";
import ContactInfoCrPhOur from "./components/ContactInfoCrPhOur";
import ExerciseDiary from "./components/ExerciseDiary";
import ExerciseDiaryCRSPL from "./components/ExerciseDiaryCRSPL";
import PatientDataForms from "./components/PatientDataForms";
import PatientDataFormsCRSPL from "./components/PatientDataFormsCRSPL";
import Settings from "./components/Settings";
import Downloads from "./components/Downloads";
import PatientDownloads from "./components/PatientDownloads";
import CRSpecialistDownloads from "./components/CRSpecialistDownloads";
import { Route, Routes } from "react-router-dom";
import AssignActivityFormCRSPL from "./pages/Patient/AssignActivityFormCRSPL";


function App() {
  return (
    <>
      <div className="App">
        <main id="main">
          <Routes>
            <Route
              exact
              path="/"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Home />
                </>
              }
            />
            <Route
              exact
              path="/Home"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Home />
                </>
              }
            />
            {/* <Route exact path="/AppFeatures" element={<Features />} />
            <Route exact path="/WhatWeDo" element={<What We Do? />} />
            <Route exact path="/Gallery" element={<Gallery />} />
            <Route exact path="/FAQ" element={<FAQ />} />
            <Route exact path="/Contact" element={<Contact />} /> 
            <Route exact path="/Testimonials" element={<Testimonials />} /> */}
            <Route
              exact
              path="/AppFeatures"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Features />
                </>
              }
            />
            <Route
              exact
              path="/WhatWeDo"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <WhatWeDo />
                </>
              }
            />
            <Route
              exact
              path="/CRExplained"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <CRExplained />
                </>
              }
            />
            <Route
              exact
              path="/CRHome"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <CRHome />
                </>
              }
            />
            <Route
              exact
              path="/KYHHealth"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <KYHHealth />
                </>
              }
            />
             <Route
              exact
              path="/SOHAilment"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <SOHAilment />
                </>
              }
            />
            <Route
              exact
              path="/Gallery"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Gallery />
                </>
              }
            />
            <Route
              exact
              path="/FAQ"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <FAQ />
                </>
              }
            />
            <Route
              exact
              path="/Contact"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Contact />
                </>
              }
            />
            <Route
              exact
              path="/Testimonials"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Testimonials />
                </>
              }
            />
             <Route
              exact
              path="/ToService"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <ToService />
                </>
              }
            />
             <Route
              exact
              path="/PrivPolicy"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <PrivPolicy />
                </>
              }
            />
            <Route
              exact
              path="/Signup"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Signup />
                </>
              }
            />
            <Route
              exact
              path="/Login"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Login />
                </>
              }
            />
            <Route
              exact
              path="/Dashboard"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Dashboard />
                </>
              }
            />
            <Route
              exact
              path="/Navbar"
              element={
                <>
                  <Header />
                </>
              }
            />
            <Route
              exact
              path="/Logout"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Logout />
                </>
              }
            />
            <Route
              exact
              path="/exphyactivity"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <ExPhyActivity />
                </>
              }
            />
            <Route
              exact
              path="/messages"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Messages />
                </>
              }
            />
            <Route
              exact
              path="/rqhearthealth"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <RQHeartHealth />
                </>
              }
            />
            <Route
              exact
              path="/crinformation"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <CRInformation />
                </>
              }
            />
            <Route
              exact
              path="/livingwithhd"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <LivingWithHD />
                </>
              }
            />
            <Route
              exact
              path="/contactinfocrphour"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <ContactInfoCrPhOur />
                </>
              }
            />
            <Route
              exact
              path="/exercisediary"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <ExerciseDiary />
                </>
              }
            />
            <Route
              exact
              path="/exercisediarycrspl"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <ExerciseDiaryCRSPL />
                </>
              }
            />
            <Route
              exact
              path="/patientdataforms"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <PatientDataForms />
                </>
              }
            />
            <Route
              exact
              path="/patientdataformscrspl"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <PatientDataFormsCRSPL />
                </>
              }
            />
            <Route
              exact
              path="/patientdownloads"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <PatientDownloads />
                </>
              }
            />
            <Route
              exact
              path="/crspldownloads"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <CRSpecialistDownloads />
                </>
              }
            />
            <Route
              exact
              path="/settings"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Settings />
                </>
              }
            />
            <Route
              exact
              path="/downloads"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <Downloads />
                </>
              }
            />

            <Route
              exact
              path="/assignedactivityformcrspl"
              element={
                <>
                  <Header /> <br />
                  <br />
                  <AssignActivityFormCRSPL />
                </>
              }
            />
          </Routes>

          {/* <Features /> R*/}
          {/* <Details /> NR*/}
          {/* <WhatWeDo /> R*/}
          {/* <Gallery /> R*/}
          {/* <Testimonials /> NR*/}
          {/* <Pricing /> NR*/}
          {/* <FAQ /> R*/}
          {/* <Contact R/> */}
        </main>
        <Footer />
      </div>
    </>
  );
}
export default App;
