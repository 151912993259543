import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

// function createData(name, trigly, totChol, hdlChol, ldlChol) {
//     return { name, trigly, totChol, hdlChol, ldlChol };
// }

const DietaryAdvice = () => {
    const { t } = useTranslation(["translation", "dietaryadvice"]);

    // const rows = [
    //     createData(t("table.r2c1", { ns: "kyhh" }), t("table.r2c2", { ns: "kyhh" }), t("table.r2c3", { ns: "kyhh" }), t("table.r2c4", { ns: "kyhh" }), t("table.r2c5", { ns: "kyhh" })),
    //     createData(t("table.r3c1", { ns: "kyhh" }), t("table.r3c2", { ns: "kyhh" }), t("table.r3c3", { ns: "kyhh" }), t("table.r3c4", { ns: "kyhh" }), t("table.r3c5", { ns: "kyhh" })),
    //     createData(t("table.r4c1", { ns: "kyhh" }), t("table.r4c2", { ns: "kyhh" }), t("table.r4c3", { ns: "kyhh" }), t("table.r4c4", { ns: "kyhh" }), t("table.r4c5", { ns: "kyhh" })),
    //     createData(t("table.r5c1", { ns: "kyhh" }), t("table.r5c2", { ns: "kyhh" }), t("table.r5c3", { ns: "kyhh" }), t("table.r5c4", { ns: "kyhh" }), t("table.r5c5", { ns: "kyhh" })),
    //     createData(t("table.r6c1", { ns: "kyhh" }), t("table.r6c2", { ns: "kyhh" }), t("table.r6c3", { ns: "kyhh" }), t("table.r6c4", { ns: "kyhh" }), t("table.r6c5", { ns: "kyhh" })),
    // ];

    return (
        <Suspense fallback={<Loader />}>

            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t("header.h2", { ns: "dietaryadvice" })}</h2>
                            {/* <p>
                                  {t("para.p1", { ns: "kyhh" })}
                               </p> */}
                        </div>

                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">
                                        
                                        <div className="col-md-12 icon-box" data-aos="fade-up">
                                            <i className="bx bx-receipt"></i>
                                            <h4>{t("header.h4a", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p1", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >
                                            <i className="bx bx-key"></i>
                                            <h4>{t("header.h4b", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p2", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p3", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p4", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p5", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {t("para.p6", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {t("para.p7", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {t("para.p8", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                {t("para.p9", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p10", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p11", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p12", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p13", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p14", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p15", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>
                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >
                                            <i className="bx bx-palette"></i>
                                            <h4>{t("header.h4c", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p16", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p17", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;
                                                {t("para.p18", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;
                                                {t("para.p19", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;
                                                {t("para.p20", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;
                                                {t("para.p21", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;
                                                {t("para.p22", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                &nbsp;&nbsp;
                                                {t("para.p23", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="200"
                                        >
                                            <i className="bx bx-minus"></i>
                                            <h4>{t("header.h4d", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p24", { ns: "dietaryadvice" })}
                                            </p><br />
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="300"
                                        >
                                            <i className="bx bx-shield"></i>
                                            <h4>{t("header.h4e", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p25", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b>{t("para.p26a", { ns: "dietaryadvice" })}</b> {t("para.p26b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b>{t("para.p27a", { ns: "dietaryadvice" })}</b>
                                                {t("para.p27b", { ns: "dietaryadvice" })}
                                                <b>{t("para.p27c", { ns: "dietaryadvice" })}</b>
                                                {t("para.p27d", { ns: "dietaryadvice" })}
                                                <b>{t("para.p27e", { ns: "dietaryadvice" })}</b>
                                                {t("para.p27f", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            //className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                            data-aos="fade-left"
                                            data-aos-delay="400"
                                            align="center"
                                        >
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell align="center" colSpan="4"><b>{t("table.r1c1", { ns: "dietaryadvice" })}</b></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left">{t("table.r2c1", { ns: "dietaryadvice" })}</TableCell>
                                                            <TableCell align="left">{t("table.r2c2", { ns: "dietaryadvice" })}</TableCell>
                                                            <TableCell align="left">{t("table.r2c3", { ns: "dietaryadvice" })}</TableCell>
                                                            <TableCell align="left">{t("table.r2c4", { ns: "dietaryadvice" })}</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* {rows.map((row) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                                                <TableCell align="left">{row.trigly}</TableCell>
                                                                <TableCell align="left">{row.totChol}</TableCell>
                                                                <TableCell align="left">{row.hdlChol}</TableCell>
                                                                <TableCell align="left">{row.ldlChol}</TableCell>
                                                            </TableRow>
                                                        ))} */}

                                                        <TableRow
                                                            key={"XYZ"}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                                {t("table.r3c1a", { ns: "dietaryadvice" })}<br /><br />
                                                                {t("table.r3c1b", { ns: "dietaryadvice" })}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                                {t("table.r3c2a", { ns: "dietaryadvice" })}<br /><br />
                                                                {t("table.r3c2b", { ns: "dietaryadvice" })}<br /><br />
                                                                {t("table.r3c2c", { ns: "dietaryadvice" })}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                                {t("table.r3c3a", { ns: "dietaryadvice" })}<br /><br />
                                                                {t("table.r3c3b", { ns: "dietaryadvice" })}
                                                            </TableCell>
                                                            <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                                {t("table.r3c4a", { ns: "dietaryadvice" })}<br /><br />
                                                                {t("table.r3c4b", { ns: "dietaryadvice" })}<br /><br />
                                                                {t("table.r3c4c", { ns: "dietaryadvice" })}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="500"
                                        >
                                            <i className="bx bx-atom"></i>
                                            <h4>{t("header.h4f", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p28", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p29", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="600"
                                        >
                                            <i className="bx bx-images"></i>
                                            <h4>{t("header.h4g", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p30", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p31", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="700"
                                        >
                                            <i className="bx bx-cube-alt"></i>
                                            <h4>{t("header.h4h", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p32", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p33", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="800"
                                        >
                                            <i className="bx bx-walk"></i>
                                            <h4>{t("header.h4i", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p34", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="900"
                                        >
                                            <i className="bx bx-plus"></i>
                                            <h4>{t("header.h4j", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p35", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                {t("para.p36", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="1000"
                                        >
                                            <i className="bx bx-heart"></i>
                                            <h4>{t("header.h4k", { ns: "dietaryadvice" })}</h4>
                                            <p>
                                                {t("para.p37a", { ns: "dietaryadvice" })}<b> {t("para.p37b", { ns: "dietaryadvice" })}</b>
                                            </p>
                                            <p>
                                                <b> {t("para.p38a", { ns: "dietaryadvice" })}</b> {t("para.p38b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b> {t("para.p39", { ns: "dietaryadvice" })}</b>
                                            </p>
                                            <p>
                                                <b> {t("para.p40a", { ns: "dietaryadvice" })}</b> {t("para.p40b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b>{t("para.p41a", { ns: "dietaryadvice" })}</b> {t("para.p41b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b> {t("para.p42a", { ns: "dietaryadvice" })}</b> {t("para.p42b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b>{t("para.p43a", { ns: "dietaryadvice" })}</b> {t("para.p43b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b> {t("para.p44a", { ns: "dietaryadvice" })}</b> {t("para.p44b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b> {t("para.p45a", { ns: "dietaryadvice" })}</b> {t("para.p45b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b>{t("para.p46a", { ns: "dietaryadvice" })}</b> {t("para.p46b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b> {t("para.p47a", { ns: "dietaryadvice" })}</b> {t("para.p47b", { ns: "dietaryadvice" })}
                                            </p>
                                            <p>
                                                <b>{t("para.p48a", { ns: "dietaryadvice" })}</b> {t("para.p48b", { ns: "dietaryadvice" })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense >

    );
};

export default DietaryAdvice;
