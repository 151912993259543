import React, { useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import ActivityInsertForm from "./ActivityInsertForm";
import ViewActivityForm from "./ViewActivityForm";
import ViewActivityPlanForm from "./ViewActivityPlanForm";
import PageHeader from "../../components/PageHeader";
// import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
// import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsBikeTwoToneIcon from "@mui/icons-material/DirectionsBikeTwoTone";
import NordicWalkingIcon from "@mui/icons-material/NordicWalking";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
//import NordicWalkingTwoToneIcon from "@mui/icons-material/NordicWalkingTwoTone";
//import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

// loading component for suspense fallback
const Loader = () => (
  <div>
      <b>Please Wait something is still loading...</b>
  </div>
);

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0), //5
    padding: theme.spacing(3)
  }
}));

export default function Activities() {
  const { t } = useTranslation(["translation", "activities"]);
  const classes = useStyles();

  const [value, setValue] = useState("2");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
     <Suspense fallback={<Loader />}>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("plan.hplan", { ns: "activities" })} value="1" />
              <Tab label={t("activities.hactivities", { ns: "activities" })} value="2" />
              <Tab label={t("history.hhistory", { ns: "activities" })} value="3" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <PageHeader
              title={t("plan.subheadplan", { ns: "activities" })}
              subTitle={t("plan.subtextplan", { ns: "activities" })}
              icon={<NordicWalkingIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ViewActivityPlanForm />
            </Paper>
          </TabPanel>

          <TabPanel value="2">
            <PageHeader
              title={t("activities.subheadactivities", { ns: "activities" })}
              subTitle={t("activities.subtextactivities", { ns: "activities" })}
              icon={<DirectionsBikeTwoToneIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ActivityInsertForm />
            </Paper>
          </TabPanel>

          <TabPanel value="3">
            <PageHeader
              title={t("history.subheadhistory", { ns: "activities" })}
              subTitle={t("history.subtexthistory", { ns: "activities" })}
              icon={<ListAltOutlinedIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ViewActivityForm />
            </Paper>
          </TabPanel>
        </TabContext>
      </Box>
      </Suspense>
    </>
  );
}
