import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import VideoPlayer from './VideoPlayer';

const VideoModalWindow = ({ onClose, videoSrc }) => {
  const containerEl = document.createElement('div');
  const newWindow = useRef(null);
  
  useEffect(() => {
    // Calculate the center position for the new window
    const width = 460;
    const height = 810;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2;

    // Open a new window
    newWindow.current = window.open('', '', `width=${width},height=${height},left=${left},top=${top}`);
    //newWindow.current = window.open('', '', 'width=700,height=1150');

    // If the new window could not be opened, handle the error
    if (!newWindow.current) {
      console.error('Failed to open new window. It might be blocked by a popup blocker.');
      alert("Failed to open new window. It might be blocked by a popup blocker.");
      onClose(); // Call the onClose callback to notify the parent component
      return;
    }

    // Append the div container to the new window's body
    newWindow.current.document.body.appendChild(containerEl);

    // Render the modal content in the new window
    ReactDOM.render(<VideoPlayer onClose={onCloseInWindow} videoSrc={videoSrc} />, containerEl);

    // Cleanup when the component unmounts
    return () => {
      if (newWindow.current) {
        newWindow.current.close();
      }
    };
    // eslint-disable-next-line
  }, [containerEl]);

  const onCloseInWindow = () => {
    onClose();
    if (newWindow.current) {
      newWindow.current.close();
    }
  };

  return null;
};

export default VideoModalWindow;
