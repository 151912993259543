import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import axios from "axios";
import {
  TableBody,
  TableRow,
  TableCell
} from "@mui/material";
import ConfigData from "../../components/config/Config.json";
//import Controls from "../../components/controls/Controls";
//import { makeStyles } from "@mui/styles";
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const BASE_URL = `${ConfigData.protoipaport}/assessmentforms`;

/*const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: "100%"
  }
})); */

const headCells = [
  { id: "dateTime", label: "Date/Time" },
  { id: "smk", label: "Smoke" },
  { id: "alcScreen1", label: "ASQ1" },
  { id: "alcScreen2", label: "ASQ2" },
  { id: "alcScreen3", label: "ASQ3" },
  { id: "alcFullScreen4", label: "FSQ1" },
  { id: "alcFullScreen5", label: "FSQ2" },
  { id: "alcFullScreen6", label: "FSQ3" },
  { id: "alcFullScreen7", label: "FSQ4" },
  { id: "alcFullScreen8", label: "FSQ5" },
  { id: "alcFullScreen9", label: "FSQ6" },
  { id: "alcFullScreen10", label: "FSQ7" },
  { id: "totalAlcoholScreen", label: "TotalAS", disableSorting: true },
  { id: "totalFullScreen", label: "TotalFS", disableSorting: true },
  { id: "grandTotalScore", label: "GrandTotal", disableSorting: true }
];

export default function AlcTobDataTableCRSPL({
  assigneduserid,
  assignedusername
}) {
  //const classes = useStyles();

  //Form score data
  const [records, setRecords] = useState([]);
  // eslint-disable-next-line
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //User id for which the data is required
  // eslint-disable-next-line
  const [userid, setUserId] = useState(assigneduserid);

  //Creating axios object for getting all the score data for a particular form
  const axiosGetFormScoreCard = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Form Score card Records
  useEffect(() => {
    const axiosgetformscorecard = async () => {
      const res = await axiosGetFormScoreCard.get(
        `/getalcoholtobaccoformdatabyuid/${userid}`
      );

      const getScoreCard = await res.data;
      setRecords(await getScoreCard);
    };
    axiosgetformscorecard();
    // eslint-disable-next-line
  }, []);

  // const handleSearch = (e) => {
  //   let target = e.target;
  //   setFilterFn({
  //     fn: (items) => {
  //       if (target.value === "") return items;
  //       else
  //         return items.filter((x) =>
  //           x.grandTotalScore.toLowerCase().includes(target.value)
  //         );
  //     }
  //   });
  // };

  return (
    <>
      {/* <Toolbar>
        <Controls.Input
          label="Search Activities"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon //color="secondary"
                />
              </InputAdornment>
            )
          }}
          onChange={handleSearch}
        />
      </Toolbar> */}

      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                {new Date(item.dateTime).toLocaleDateString("en-IN")}
              </TableCell>
              <TableCell>{item.smk}</TableCell>
              <TableCell>{item.alcScreen1}</TableCell>
              <TableCell>{item.alcScreen2}</TableCell>
              <TableCell>{item.alcScreen3}</TableCell>
              <TableCell>{item.alcFullScreen4}</TableCell>
              <TableCell>{item.alcFullScreen5}</TableCell>
              <TableCell>{item.alcFullScreen6}</TableCell>
              <TableCell>{item.alcFullScreen7}</TableCell>
              <TableCell>{item.alcFullScreen8}</TableCell>
              <TableCell>{item.alcFullScreen9}</TableCell>
              <TableCell>{item.alcFullScreen10}</TableCell>
              <TableCell>{item.totalAlcoholScreen}</TableCell>
              <TableCell>{item.totalFullScreen}</TableCell>
              <TableCell>{item.grandTotalScore}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}
