import React, { useRef } from 'react';
//import axios from 'axios';

// Some basic styles for modal and exit button
const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    borderRadius: '8px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    zIndex: 1000,
};

const exitButtonStyle = {
    position: 'absolute',
    bottom: '10px',
    //left: '260px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '10px 20px',
    //fontSize: '16px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
};

const VideoPlayer = ({ onClose, videoSrc }) => {
    // const [videoSrc, setVideoSrc] = useState(null);
    // const [isVideoVisible, setIsVideoVisible] = useState(true);
    const videoRef = useRef(null);

    
    // const fetchVideo = async () => {
    //     try {
    //         const response = await axios.get(url, {
    //             responseType: 'arraybuffer',
    //         });

    //         const videoBlob = new Blob([response.data], { type: 'video/mp4' });
    //         const videoUrl = URL.createObjectURL(videoBlob);

    //         setVideoSrc(videoUrl);
    //     } catch (error) {
    //         console.error('Error fetching video:', error);
    //         alert('Failed to load video.');
    //     }
    // };

    // const handlePlayVideo = () => {
    //     setIsVideoVisible(true);
    //     fetchVideo();
    // };

    const handleExitVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();  // Pause the video when exiting
            videoRef.current.currentTime = 0; // Reset video time
        }
        //setIsVideoVisible(false);
        onClose();
    };

    return (
        <div>
            {/* <button onClick={handlePlayVideo} style={playButtonStyle}>Play Video</button> */}
            {
                <div style={modalStyle}>
                    <button onClick={handleExitVideo} style={exitButtonStyle}>Exit Video</button>
                    {videoSrc && (
                        <video ref={videoRef} controls width="400">
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    <br /><br /><br />
                </div>
            }
        </div>
    );
};

export default VideoPlayer;
