import React from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import ConfigData from "./config/Config.json";
import axios from "axios";
import CRHead from "./CRHeading";


//const GET_URL = "https://source.unsplash.com/random";
const BASE_URL = `${ConfigData.protoipaport}/checkfilealgo/download`;

const Downloads = () => {
  const token = localStorage.getItem("token");

  const authAxios = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const downloadResource = async (e, fileId, fileName) => {
    e.preventDefault();
    try {
      //alert("Sending GET Request to get the resource, if authorized.");
      localStorage.removeItem("message");
      await authAxios.get(fileId).then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          //alert(url);
          const link = document.createElement("a");
          link.href = url;
          //const fileName = `${+new Date()}.pdf`; // whatever your file name .
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);

          link.remove(); // you need to remove that elelment which is created before.
          console.log(response.data);
          localStorage.setItem("message", response.data);
          alert("Download Success!");
        },
        (error) => {
          if (error.response) {
            console.log(error.response.data);
            const scode = error.response.status;
            if (scode === 401 || scode === 403 || scode === 404) {
              localStorage.setItem("message", "Downloading.....");
            }
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          console.log("Error is below: ");
          console.log(error);
          console.log(error.data.message);
          alert("Download Failure!");
        }
      );
      console.log(JSON.stringify(localStorage.getItem("token")));
    } catch (err) {
      console.log(err.response);
      console.log(err.message);
    }
  };

  return (
    <>
      <section id="contact" className="contact">
        <div className="section-title" align="center">
          <h2><CRHead/></h2>
        </div>

        <Container className="container">
          <Row className="row">
            <Menu
            // The child component will access using actionName
            // actionProfile={handleProfileData}
            // actionPublic={handlePublicData}
            // actionPatient={handlePatientData}
            // actionCRSPL={handleCRSPLData}
            // actionLHCP={handleLHCPData}
            // actionHERPApp={handleHERPAppData}
            // actionCREX={handleCREXData}
            // actionExProto={handleExProtoData}
            // actionDitAdv={handleDitAdvData}
            // actionKYHS={handleKYHSData}
            // actionEDUV={handleEDUVData}
            // actionHEXV={handleHEXVData}
            // actionDownloads={handleDownloadsData}
            />
            <Col className="col-lg-9 info">
              <p align="left">
                <div className="section-title" align="center">
                  <h3>Downloads</h3>
                </div>
                {/* Calling to the methods */}
                {/* <div className="messages">{message}</div> */}

                <ListGroup>
                  
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/e8be9383-2700-4171-9754-5889eee8da13",
                        "Dietary advice.pdf"
                      )
                    }
                    action
                  >
                    {" "}
                    <b>1).</b> Download Dietary Advice Document{" "}
                  </Link><br/>

                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/7b75b456-93e5-40c7-a4ec-d47ff11c1330",
                        "Exercise Diary.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>2).</b> Download Exercise Diary Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/a7232baf-41d6-40de-a442-2767ee0cb290",
                        "Initial Advice.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>3).</b> Download Initial Advice Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/b79f5569-d772-44d6-9856-4473cd04abb7",
                        "Specialist First Assessment.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>4).</b> Download Specialist First Assessment Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/15168ee2-92e1-44fb-a267-43540e0cff75",
                        "Alcohol Smoking.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>5).</b> Download Alcohol/Smoking Assessment Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/8886f20c-dcee-4981-b057-42c2a246a5a9",
                        "Home Exercise routine and advice.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>6).</b> Download Home Exercise routine and advice
                    Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/a501c279-a698-42cb-a293-9f30ab7eb7e9",
                        "Initial Assessment.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>7).</b> Download Initial Assessment Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/b662d822-f8af-4c1c-b487-6f260addbf55",
                        "PHQ-9 Questionnaire.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>8).</b> Download PHQ-9 Questionnaire Document{" "}
                  </Link><br/>
                  <Link
                    className="list-group-item list-group-item-action"
                    tag="a"
                    to="#"
                    onClick={(event) =>
                      downloadResource(
                        event,
                        "/0c41c564-5746-4e15-b7ca-a4203d8d8830",
                        "Pshychological Assessment HADS.pdf"
                      )
                    }
                    //onClick={props.actionProfile}
                    action
                  >
                    {" "}
                    <b>9).</b> Download Pshychological Assessment HADS Document{" "}
                  </Link>
                </ListGroup>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Downloads;
