import React, { useContext, Suspense } from "react";
import { Link } from "react-router-dom";
import ActiveFlagContext from "./Context";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const PDFRoleBasedAccess = (props) => {
  const { t } = useTranslation(["translation", "menuitems"]);

  const role = localStorage.getItem("roles");

  const { active, setActive } = useContext(ActiveFlagContext);

  const handlePDFCRSpecialist = () => {
    if (
      setActive === undefined ||
      setActive === null ||
      active === undefined ||
      active === null
    ) {
      //Do nothing
    } else {
      setActive(true);
      //alert(active);
    }
  };

  // const handleEDPatient = () => {
  //   //alert("2");
  //   //setActive(true);
  //   setCount("Patient");
  // };

  return role === "ROLE_CRSPL" ? (
    <Suspense fallback={<Loader />}>
      <Link
        className="list-group-item list-group-item-action"
        tag="a"
        //reloadDocument
        to="/patientdataformscrspl"
        onClick={handlePDFCRSpecialist}
        action
      >
        {" "}
        {t("menu.mItem8", { ns: "menuitems" })}{" "}
      </Link>
    </Suspense>
  ) : (
    <Suspense fallback={<Loader />}>
      <Link
        className="list-group-item list-group-item-action"
        tag="a"
        to="/patientdataforms"
        action
      >
        {" "}
        {t("menu.mItem8", { ns: "menuitems" })}{" "}
      </Link>
    </Suspense>
  );
};

export default PDFRoleBasedAccess;
