import ViewAllUsersCRSPL from "./ViewAllUsersCRSPL";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0), //5
    padding: theme.spacing(3)
  }
}));

export default function ActivitiesCRSPL() {
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.pageContent}>
        <ViewAllUsersCRSPL />
      </Paper>
    </>
  );
}
