import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const GeneralIVL = () => {
    const { t } = useTranslation(["translation", "lwhd"]);

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">
                                  
                                        <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="300">
                                            <i className="bx bx-link"></i>
                                            <h4>
                                                {t("header.h4a", { ns: "lwhd" })}
                                            </h4>
                                            <h5>
                                                
                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://vimeo.com/906768838/1b573a38e0"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://vimeo.com/906768838/1b573a38e0",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p1a", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                            <h5>
                                                
                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://vimeo.com/906767976/a2cd722eb7"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://vimeo.com/906767976/a2cd722eb7",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p1b", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                            <h5>
                                                
                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://vimeo.com/906768098/6337a16de8"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://vimeo.com/906768098/6337a16de8",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p1c", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                            <h5>
                                                
                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://vimeo.com/906767776/4b89ba3842"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://vimeo.com/906767776/4b89ba3842",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p2", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                            <h5>
                                                
                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://www.fhft.nhs.uk/media/4393/progressive-muscle-relaxation.mp3"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://www.fhft.nhs.uk/media/4393/progressive-muscle-relaxation.mp3",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p3", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense>
    );
};

export default GeneralIVL;
