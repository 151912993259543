import React, { useState, Suspense } from "react";
import { Grid, Slide } from "@mui/material";
import axios from "axios";
import { useForm, Form } from "../../components/useForm";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
//import IconButton from "@mui/material/IconButton";
//import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//import * as employeeService from "../../services/employeeService";

const initialFValues = {
  usid: 0,
  dateTime: new Date().toLocaleString(),
  //dateTime: new Date(),
  gad1: 0,
  gad2: 0,
  gad3: 0,
  gad4: 0,
  gad5: 0,
  gad6: 0,
  gad7: 0,
  diffLevel: "Not difficult at all"
};

const BASE_URL = `${ConfigData.protoipaport}/assessmentforms`;

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

export default function GAD7Form() {
  const { t } = useTranslation(["translation", "gad7"]);

  const gadItems = [
    { id: 0, title: t("options.option1", { ns: "gad7" }) },
    { id: 1, title: t("options.option2", { ns: "gad7" }) },
    { id: 2, title: t("options.option3", { ns: "gad7" }) },
    { id: 3, title: t("options.option4", { ns: "gad7" }) }
  ];

  const diffLevelItems = [
    { id: "Not difficult at all", title: t("options.option5", { ns: "gad7" }) },
    { id: "Somewhat difficult", title: t("options.option6", { ns: "gad7" }) },
    { id: "Very difficult", title: t("options.option7", { ns: "gad7" }) },
    { id: "Extremely difficult", title: t("options.option8", { ns: "gad7" }) }
  ];

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);

  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

 /* const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  ); */

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("gad1" in fieldValues)
      temp.gad1 = fieldValues.gad1 ? "" : "GAD-1 field is required.";
    if ("gad2" in fieldValues)
      temp.gad2 = fieldValues.gad2 ? "" : "GAD-2 field is required.";
    if ("gad3" in fieldValues)
      temp.gad3 = fieldValues.gad3 ? "" : "GAD-3 field is required.";
    if ("gad4" in fieldValues)
      temp.gad4 = fieldValues.gad4 ? "" : "GAD-4 field is required.";
    if ("gad5" in fieldValues)
      temp.gad5 = fieldValues.gad5 ? "" : "GAD-5 field is required.";
    if ("gad6" in fieldValues)
      temp.gad6 = fieldValues.gad6 ? "" : "GAD-6 field is required.";
    if ("gad7" in fieldValues)
      temp.gad7 = fieldValues.gad7 ? "" : "GAD-7 field is required.";
    if ("diffLevel" in fieldValues)
      temp.diffLevel = fieldValues.diffLevel
        ? ""
        : "Difficulty level field is required.";
    setErrors({
      ...temp
    });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const validate2 = (fieldValues = values) => {
    let flag = true;
    if (
      values["gad1"] === "" ||
      values["gad1"] === undefined ||
      values["gad1"] === null
    ) {
      flag = false;
      alert("GAD-1 is required!");
    }
    if (
      values["gad2"] === "" ||
      values["gad2"] === undefined ||
      values["gad2"] === null
    ) {
      flag = false;
      alert("GAD-2 is required!");
    }
    if (
      values["gad3"] === "" ||
      values["gad3"] === undefined ||
      values["gad3"] === null
    ) {
      flag = false;
      alert("GAD-3 is required!");
    }
    return flag;
  };

  const {
    values,
    // eslint-disable-next-line
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);
  //const [errors, setErrors] = useState({});

  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current user name
  //const currusername = localStorage.getItem("username");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Creating axios object for form data insertion
  const axiosInsertFormData = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  const handleSubmit = async (e, t) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    if (validate2()) {
      //alert("Validation Success");

      try {
        alert(t("alerts.aConfirm"));

        //Show warning snackbar with alert
        // handleClick(
        //   "warning",
        //   "Warning",
        //   "Data once inserted cannot be deleted or modified again. Are you sure you want to Continue? — <strong>check it out!</strong>"
        // );

        /* For testing/debugging purpose */
        // alert("user id is: " + userid);
        // alert("dateTime is: " + values["dateTime"]);
        // alert("activityName is: " + values["activityName"]);
        // alert("preHR is: " + values["preHR"]);
        // alert("postHR is: " + values["postHR"]);
        // alert("results is: " + values["results"]);
        // alert("rpeBorg is: " + values["rpeBorg"]);
        // alert("symptoms is: " + values["symptoms"]);

        // eslint-disable-next-line
        const response = await axiosInsertFormData
          .post("/insertgad7", {
            userid: userid,
            //dateTime: values["dateTime"],
            dateTime: isoDate,
            gad1: values["gad1"],
            gad2: values["gad2"],
            gad3: values["gad3"],
            gad4: values["gad4"],
            gad5: values["gad5"],
            gad6: values["gad6"],
            gad7: values["gad7"],
            diffLevel: values["diffLevel"]
          })
          .then(
            (response) => {
              console.log(response);
              //Show success snackbar with alert
              handleClick(
                "success",
                t("snackHead.sSuccess"),
                t("alerts.aSuccess")
              );
              //alert("Activity inserted Successfully!");
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in inserting the Data....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              //alert("Activity Insertion Failure!");
              //Show error snackbar with alert
              handleClick(
                "error",
                t("snackHead.sError"),
                t("alerts.aInsertion")
              );
            }
          );
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
        //alert("Insertion failure! " + err);
        //Show error snackbar with alert
        handleClick("error", t("snackHead.sError"), t("alerts.aExcept"));
      }

      resetForm();
    } else {
      //alert("Form validation fails!");
      //Show error snackbar with alert
      handleClick("warning", "Warning", t("alerts.aValidation"));
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Form>
        <Grid container>
          {/* Snackbar with alert */}
          <div>
            <Snackbar
              open={open}
              //message="Material UI snackbar"
              TransitionComponent={(props) => (
                <Slide {...props} direction="left" />
              )}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              //action={action}
            >
              <Alert
                severity={severity}
                variant="filled"
                //icon={false}
                onClose={handleClose}
              >
                <AlertTitle>{alerttitle}</AlertTitle>
                {alertmessage}
              </Alert>
            </Snackbar>
          </div>

          {/* <Grid item xs={6}>
          <p align="left">
            <b>Name: {currusername} </b>
          </p>
        </Grid>
        <Grid item xs={6} align="right">
          <Controls.Input
            name="dateTime"
            label="Date/Time of Form Filling"
            value={values.dateTime}
            onChange={handleInputChange}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid> */}

          <Grid item xs={12} align="left">
            {/* <p align="left">
            <b>
              Over the last 2 weeks, how often have you been bothered by any of
              the following problems? (select the radio button to indicate your
              answer)
            </b>
          </p> */}
            <p align="left">
              <b>{t("titleQ", { ns: "gad7" })}</b>
            </p>
            <Controls.RadioGroup
              name="gad1"
              label={t("questions.q1", { ns: "gad7" })}
              value={values.gad1}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="gad2"
              label={t("questions.q2", { ns: "gad7" })}
              value={values.gad2}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="gad3"
              label={t("questions.q3", { ns: "gad7" })}
              value={values.gad3}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="gad4"
              label={t("questions.q4", { ns: "gad7" })}
              value={values.gad4}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="gad5"
              label={t("questions.q5", { ns: "gad7" })}
              value={values.gad5}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="gad6"
              label={t("questions.q6", { ns: "gad7" })}
              value={values.gad6}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="gad7"
              label={t("questions.q7", { ns: "gad7" })}
              value={values.gad7}
              onChange={handleInputChange}
              items={gadItems}
            />
            <Controls.RadioGroup
              name="diffLevel"
              label={t("questions.qstatus", { ns: "gad7" })}
              value={values.diffLevel}
              onChange={handleInputChange}
              items={diffLevelItems}
            />
            <div border="2" align="center">
              &nbsp;&nbsp;
              <Controls.Button
                type="submit"
                onClick={(event) => {
                  handleSubmit(event, t);
                }}
                text={t("footer.send", { ns: "gad7" })}
              />
              &nbsp;&nbsp;&nbsp;
              <Controls.Button
                text={t("footer.reset", { ns: "gad7" })}
                onClick={resetForm}
                color="inherit"
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </Suspense>
  );
}
