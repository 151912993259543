import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import HeartMedicationSubAccord from "./HeartMedicationSubAccord";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const HeartMedExplained = () => {
    const { t } = useTranslation(["translation", "lwhd"]);

    return (
        <Suspense fallback={<Loader />}>
            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="row no-gutters">
                            <div className="col-xl-12 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div align="left" className="row">

                                        <div className="col-md-12 icon-box" data-aos="fade-up">

                                            <div
                                                className="col-md-12 icon-box"
                                                data-aos="fade-up"
                                            >
                                                <i className="bx bx-capsule"></i>
                                                <h4>
                                                    {t("header.h4d", { ns: "lwhd" })}
                                                </h4>
                                                <p>
                                                    {<HeartMedicationSubAccord />} 
                                                </p>

                                            </div>


                                            <i className="bx bx-link"></i>
                                            <h4>
                                                {t("header.h4e", { ns: "lwhd" })}
                                            </h4>
                                            <h5>

                                                <a
                                                    target="_Myblank"
                                                    rel="noopener noreferrer"
                                                    href="https://vimeo.com/907507206/7d31c38de1"
                                                    onClick={() =>
                                                        window.open(
                                                            "https://vimeo.com/907507206/7d31c38de1",
                                                            "_Myblank",
                                                            "width=1200, height=950"
                                                        )
                                                    }
                                                >
                                                    <p>{t("para.p66", { ns: "lwhd" })}</p>
                                                </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        </Suspense>
    );
};

export default HeartMedExplained;
