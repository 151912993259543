import React, { useState } from "react";
import ViewHADSForm from "./HADSForm";
import ViewPHQ9Form from "./PHQ9Form";
import ViewGAD7Form from "./GAD7Form";
import ViewAlcoholTobaccoForm from "./AlcoholTobaccoForm";
import PageHeader from "../../components/PageHeader";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import GradingOutlinedIcon from "@mui/icons-material/GradingOutlined";
import LiquorOutlinedIcon from "@mui/icons-material/LiquorOutlined";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(0), //5
    padding: theme.spacing(3)
  }
}));

export default function DataForms() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("header.tHead", { ns: "hads" })} value="1" />
              <Tab label={t("header.tHead", { ns: "phq9" })} value="2" />
              <Tab label={t("header.tHead", { ns: "gad7" })} value="3" />
              <Tab label={t("header.tHead", { ns: "alcsmk" })} value="4" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <PageHeader
              title={t("header.h1", { ns: "hads" })}
              subTitle={t("header.h2", { ns: "hads" })}
              icon={<LocalHospitalOutlinedIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ViewHADSForm />
            </Paper>
          </TabPanel>

          <TabPanel value="2">
            <PageHeader
              title={t("header.h1", { ns: "phq9" })}
              subTitle={t("header.h2", { ns: "phq9" })}
              icon={<MedicalInformationOutlinedIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ViewPHQ9Form />
            </Paper>
          </TabPanel>

          <TabPanel value="3">
            <PageHeader
              title={t("header.h1", { ns: "gad7" })}
              subTitle={t("header.h2", { ns: "gad7" })}
              icon={<GradingOutlinedIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ViewGAD7Form />
            </Paper>
          </TabPanel>

          <TabPanel value="4">
            <PageHeader
              title={t("header.h1", { ns: "alcsmk" })}
              subTitle={t("header.h2", { ns: "alcsmk" })}
              icon={<LiquorOutlinedIcon fontSize="large" />}
            />
            <Paper className={classes.pageContent}>
              <ViewAlcoholTobaccoForm />
            </Paper>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
