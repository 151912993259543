import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import { Col } from "react-bootstrap";
import EDRoleBasedAccess from "./EDRoleBasedAccess";
import PDFRoleBasedAccess from "./PDFRoleBasedAccess";
import DownRoleBasedAccess from "./DownRoleBasedAccess";
import { useTranslation } from "react-i18next";


// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Menu = (props) => {
  const { t } = useTranslation(["translation", "menuitems"]);
  // function getFromChild(data) {
  //   alert(data);
  //   //props.getFromED(data);
  // }
  //const { rolevalue, setRoleValue } = props;

  return (
    <>
      <Suspense fallback={<Loader />}>

        <Col className="col-lg-3 info">
          <i className="bx bx-map"></i>
          <p align="left">
            {
              <ListGroup>
                {/* <Link
                className="list-group-item list-group-item-action"
                tag="a"
                to="#"
                onClick={props.actionProfile}
                action
              >
                {" "}
                User Profile{" "}
              </Link>

              <Link
                className="list-group-item list-group-item-action"
                tag="a"
                to="#"
                onClick={props.actionPublic}
                action
              >
                {" "}
                Public Content{" "}
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                tag="a"
                to="#"
                onClick={props.actionPatient}
                action
              >
                {" "}
                Patient Content{" "}
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                tag="a"
                to="#"
                onClick={props.actionCRSPL}
                action
              >
                {" "}
                Physical Instructor Content{" "}
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                tag="a"
                to="#"
                onClick={props.actionLHCP}
                action
              >
                {" "}
                Physician Content{" "}
              </Link> */}

                <Link
                  className="list-group-item list-group-item-action"
                  active
                  tag="a"
                  to="/exphyactivity"
                  //onClick={props.actionCREX}
                  action
                >
                  {" "}
                  {t("menu.mItem1", { ns: "menuitems" })}{" "}
                </Link>

                <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/messages"
                  action
                >
                  {" "}
                  {t("menu.mItem2", { ns: "menuitems" })}{" "}
                </Link>

                <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/rqhearthealth"
                  //onClick={props.actionExProto}
                  action
                >
                  {" "}
                  {t("menu.mItem3", { ns: "menuitems" })}{" "}
                </Link>

                <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/crinformation"
                  //onClick={props.actionHERPApp}
                  action
                >
                  {" "}
                  {t("menu.mItem4", { ns: "menuitems" })}{" "}
                </Link>
                <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/livingwithhd"
                  // onClick={props.actionHEXV}
                  action
                >
                  {" "}
                  {t("menu.mItem5", { ns: "menuitems" })}{" "}
                </Link>

                {/* <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/contactinfocrphour"
                  // onClick={props.actionHEXV}
                  action
                >
                  {" "}
                  {t("menu.mItem6", { ns: "menuitems" })}{" "}
                </Link> */}

                {/* To dynamically route the ED based on roles  */}
                <EDRoleBasedAccess />
                <PDFRoleBasedAccess />

                {/* <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/settings"
                  action
                >
                  {" "}
                  {t("menu.mItem9", { ns: "menuitems" })}{" "}
                </Link> */}

                {/* <Link
                  className="list-group-item list-group-item-action"
                  tag="a"
                  to="/downloads"
                  action
                >
                  {" "}
                  {t("menu.mItem10", { ns: "menuitems" })}{" "}
                </Link> */}

                <DownRoleBasedAccess />
              </ListGroup>
            }
          </p>
        </Col>{" "}
      </Suspense>
    </>
  );
};

export default Menu;
