import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import loginIcon from "../../images/user1b.png";
//import uiImg from "../../images/undraw_nature_fun.svg";
//import uiImg from "../../images/undraw_walking_outside.svg";
//import uiImg from "../../images/undraw_medical_research.svg";
import uiImg from "../../images/undraw_doctors.svg";
//import uiImg from "../../images/fitness_tracker.svg";
//import uiImg from '../../images/undraw_medicine.svg';
import "./Login.css";
import { useState, useEffect } from "react";
import axios from "axios";
//import Dashboard from "../../components/Dashboard";
import Header from "../../components/Header";
// eslint-disable-next-line
import Dashboard from "../../components/Dashboard";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line
import { Redirect } from "react-router-dom";
import ConfigData from "../config/Config.json";
// For snackbar and alerts
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { Slide } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const LOGIN_URL = `${ConfigData.protoipaport}/api/auth/signin`;

// eslint-disable-next-line
const auth = localStorage.getItem("token");

const Login = () => {
  const { t } = useTranslation(["translation", "login"]);

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  // eslint-disable-next-line
  const [validUsername, setValidUsername] = useState(false);
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);

  // eslint-disable-next-line
  const [validPassword, setValidPassword] = useState(false);

  // States for checking the errors
  // eslint-disable-next-line
  const [submitted, setSubmitted] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [resMSG, setResMSG] = useState("");

  //For snackbar and alert
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("info");
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);

  //For snackbar and alert
  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  //For snackbar and alert
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // Setting the props
  // eslint-disable-next-line
  const setProp = (e) => {
    return <Header currentUser={success} />;
  };

  // Handling the name change
  const handleUserName = (e) => {
    setUsername(e.target.value);
    setSubmitted(false);
  };

  // Handling the password change
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setSubmitted(false);
  };

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log({
    //   username: username,
    //   password: password
    // });

    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);

    if (username === "" || password === "") {
      setError(true);
      setError("Username or Password cannot be Blank!");
      handleClick("error", "Blank UserId Id or Password", "Username or Password cannot be Blank!");
    } else {
      if (!v1 || !v2) {
        setError(true);
        setError("Invalid Username/Password Entry");
        handleClick("error", "Invalid Entry", "Invalid Username and/or Password Entry!");
      } else {
        try {
          alert("Are you sure you want to Login to your account?");
          const response = await axios
            .post(
              LOGIN_URL,
              {
                username: username,
                password: password
              },
              {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
              }
            )
            .then((response) => {
              if (response.data.accessToken) {
                localStorage.setItem("token", response.data.accessToken);
                localStorage.setItem("username", response.data.username);
                localStorage.setItem("userid", response.data.id);
                localStorage.setItem("roles", response.data.roles);
                //navigate("/Dashboard");
              }
              return response.data;
            });

          //console.log(JSON.stringify(localStorage.getItem("token")));
          //console.log(response?.data);
          //setProp();

          // console.log(response?.username);
          // console.log(response?.accessToken);
          // console.log(response?.id);
          // console.log(response?.email);
          // console.log(response?.accountstatus);
          // console.log(response?.validityperiod);
          // console.log(response?.roles);
          // console.log(response?.tokenType);

          //console.log(JSON.stringify(response));

          // setResMSG(JSON.stringify(response.data.message));
          // console.log({ resMSG });

          //const accessToken = response?.data?.accessToken;

          setSuccess(true);
          setSubmitted(true);
          setError(false);
          setResMSG(response.data.message);
          //clear state and controlled inputs
          //need value attrib on inputs for this
          setUsername("");
          setPassword("");
        } catch (err) {
          console.log(err.response);
          console.log(err.message);

          if (err.response?.status === 400) {
            handleClick("info", "Info", err.response.data.message);
          }

          if (err.response?.status === 401) {
            handleClick("error", "Unauthorised Access", "Username or Password is Incorrect!");
          }

          if (!err?.response) {
            setError("No Server Response");
            handleClick("error", "No Response", "Server did not responded for this request!");
          } else if (err.response?.status === 400) {
            setError("Missing Username or Password");
            handleClick("error", "Missing Data", "Either Username or Password is Missing!");
          } else if (err.response?.status === 401) {
            setError("Unauthorized");
            handleClick("error", "Unauthorised Access", "Username or Password is Incorrect!");
          } else {
            setError("Login Failed");
            handleClick("error", "Login Failed", "Cannot login currently! Please, contat your admin");
          }

          //alert("Login failure! " + error);
          //errRef.current.focus();
        }
      }
    }
  };

  return (
    <Suspense fallback={<Loader />}>
    <>
      <div>
        <Snackbar
          open={open}
          //message="Material UI snackbar"
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
          autoHideDuration={12000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        //action={action}
        >
          <Alert
            severity={severity}
            variant="filled"
            //icon={false}
            onClose={handleClose}
          >
            <AlertTitle>{alerttitle}</AlertTitle>
            {alertmessage}
          </Alert>
        </Snackbar>
      </div>

      {success ? (
        <div>
          {/* {<Header />} */}
          {navigate("/Dashboard")}
          {/* {<Dashboard />} */}
        </div>
      ) : (
        <Container className="mt-5">
          <Row>
            <Col
              lg={4}
              md={6}
              sm={12}
              className="text-center mt-5 p-3 "
              data-aos="fade-up"
            >

              <img className="icon-img" src={loginIcon} alt="icon" />
              <Form>

                <Form.Group className="mb-3">
                  <div className="text-left mt-3" data-aos="fade-up">
                    <small className="reset">{t("Label.lblNew", { ns: "login" })}</small>
                    {" "}
                    <Link to="/Signup">
                      <small className="signup ml-2">{t("Label.lblReg", { ns: "login" })}</small>
                    </Link>
                  </div>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder={t("placeholder.txtUID", { ns: "login" })}
                    onChange={handleUserName}
                    value={username}
                    controlid="username"
                    maxLength="20"
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type={visible ? "text" : "password"}
                      placeholder={t("placeholder.txtPass", { ns: "login" })}
                      onChange={handlePassword}
                      value={password}
                      controlid="password"
                      autoComplete="off"
                      maxLength="25"
                      required
                    />
                   <span className="password-toggle-icon" onClick={() => setVisible(!visible)}>
                        {visible ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                   </span> 
                  
                </Form.Group>

                <Button
                  variant="primary btn-block"
                  onClick={handleSubmit}
                  type="submit"
                >
                  {t("Label.lblLogin", { ns: "login" })}
                </Button>

                <div className="text-left mt-3" data-aos="fade-up">
                  <Link to="/Login">
                    <small className="reset">{t("Label.lblPassRst", { ns: "login" })}</small>
                  </Link>{" "}
                  II{" "}
                  <Link to="/Login">
                    <small className="reset ml-2">{t("Label.lblQuickRec", { ns: "login" })}</small>
                  </Link>
                </div>
              </Form>
            </Col>
            <Col lg={8} md={6} sm={12} data-aos="fade-up">
              <img
                className="w-100"
                src={uiImg}
                alt="login img"
                data-aos="fade-up"
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
    </Suspense>
  );
};

export default Login;
