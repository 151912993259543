import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import axios from "axios";
import ConfigData from "./config/Config.json";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const REGISTER_URL = `${ConfigData.protoipaport}/api/auth/contactus`;

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Contact = () => {
  const { t } = useTranslation(["translation", "contactus"]);

  // States for contact form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  // States for checking the errors and success
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [resMSG, setResMSG] = useState("");

  // Handling the name change
  const handleName = (e) => {
    setName(e.target.value);
    setSubmitted(false);
  };

  // Handling the email change
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setSubmitted(false);
  };

  // Handling the subject change
  const handleSubject = (e) => {
    setSubject(e.target.value);
    setSubmitted(false);
  };

  // Handling the message change
  const handleMessage = (e) => {
    setMessage(e.target.value);
    setSubmitted(false);
  };

  //Response Message
  useEffect(() => {
    setResMSG(resMSG);
  }, [resMSG]);

  //Name
  useEffect(() => {
    const getname = async () => {
      setName(name);
    };
    getname();
  }, [name]);

  //Subject
  useEffect(() => {
    const getsubject = async () => {
      setSubject(subject);
    };
    getsubject();
  }, [subject]);

  //Message
  useEffect(() => {
    const getmessage = async () => {
      setMessage(message);
    };
    getmessage();
  }, [message]);

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setResMSG("");

    // Creating the current date-time object to send for insertion
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    const v1 = EMAIL_REGEX.test(email);

    if (name === "" || email === "" || subject === "" || message === "") {
      setError(true);
      setError("Error: Name, Email, Subject or Message field cannot be Blank!");
    } else {
      if (!v1) {
        setError(true);
        setError("Error: Invalid Email Entry");
      } else {
        try {
          alert("Are you sure you want to send the massage?");
          const response = await axios.post(
            REGISTER_URL,
            {
              dateTime: isoDate,
              name: name,
              email: email,
              subject: subject,
              message: message
            },
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true
            }
          );

          //console.log(response?.accessToken);
          setResMSG(response.data.message);

          // These all three are working fine
          // alert(JSON.stringify(response?.data.message));
          // alert(response.data.message);
          // alert(JSON.stringify(response.data.message));

          setSubmitted(true);
          setError(false);

          //clear state and controlled inputs
          setName("");
          setSubject("");
          setEmail("");
          setMessage("");
        } catch (err) {
          if (!err?.response) {
            setError("Error: No Server Response");
          } else if (err.response?.status === 400) {
            setError(true);
            setError(err.response?.data.message);
          } else {
            setError(
              "Error: Message Submission Failed! Status code is: " +
                err.response?.status +
                "."
            );
          }
          //errRef.current.focus();
        }
      }
    }
  };

  // Showing success message
  const successMessage = () => {
    return (
      <div
        className="success"
        style={{
          display: submitted ? "" : "none"
        }}
      >
        <font color="green">{resMSG}</font>
      </div>
    );
  };

  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error"
        style={{
          display: error ? "" : "none"
        }}
      >
        <font color="red">{error}</font>
      </div>
    );
  };

  return (
    <Suspense fallback={<Loader />}>

    <>
      <section id="contact" className="contact">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>{t("Header.h1", { ns: "contactus" })}</h2>
            <p>
              {t("Header.h2", { ns: "contactus" })}
            </p>
          </div>
          {/* Calling to the methods */}
          <div className="messages_signup">
            {errorMessage()}
            {successMessage()}
          </div>
          <br />

          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                {/* <div className="col-lg-6 info">
                  <i className="bx bx-map"></i>
                  <h4>Address</h4>
                  <p>
                    A108 Adam Street,
                    <br />
                    New York, NY 535022
                  </p>
                </div> */}
                <div className="col-lg-12 info">
                  <i className="bx bx-envelope"></i>
                  <h4>{t("text.txtLblEmail", { ns: "contactus" })}</h4>
                  <p>
                  {t("text.txtEmail", { ns: "contactus" })}
                    {/* <br />
                    info@example.com */}
                  </p>
                </div>
                <div className="col-lg-12 info">
                  <i className="bx bx-phone"></i>
                  <h4>{t("text.txtLblCall", { ns: "contactus" })}</h4>
                  <p>
                  {t("text.txtPhone1", { ns: "contactus" })}
                    <br />
                    {t("text.txtPhone2", { ns: "contactus" })}  
                  </p>
                </div>
                {/* <div className="col-lg-6 info">
                  <i className="bx bx-time-five"></i>
                  <h4>Working Hours</h4>
                  <p>
                    Mon - Fri: 9AM to 5PM
                    <br />
                    Sunday: 9AM to 1PM
                  </p>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6">
              <form
                action="forms/contact.php"
                method="post"
                className="php-email-form"
                data-aos="fade-up"
              >
                <div className="form-group">
                  <input
                    placeholder={t("text.txtPHName", { ns: "contactus" })}
                    type="text"
                    name="name"
                    className="form-control"
                    maxLength="50"
                    value={name}
                    onChange={handleName}
                    id="name"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    placeholder={t("text.txtPHEmail", { ns: "contactus" })}
                    type="email"
                    className="form-control"
                    name="email"
                    maxLength="50"
                    value={email}
                    onChange={handleEmail}
                    id="email"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <input
                    placeholder={t("text.txtPHSub", { ns: "contactus" })}
                    type="text"
                    className="form-control"
                    name="subject"
                    maxLength="120"
                    value={subject}
                    onChange={handleSubject}
                    id="subject"
                    required
                  />
                </div>
                <div className="form-group mt-3">
                  <textarea
                    placeholder={t("text.txtPHMsg", { ns: "contactus" })}
                    className="form-control"
                    name="message"
                    maxLength="1000"
                    value={message}
                    onChange={handleMessage}
                    id="subject"
                    rows="5"
                    required
                  ></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">{t("text.txtLoad", { ns: "contactus" })}</div>
                  <div className="error-message"></div>
                  <div className="sent-message">
                  {t("text.txtResponseMsg", { ns: "contactus" })}
                  </div>
                </div>
                <div className="text-center">
                  <button onClick={handleSubmit} type="submit">
                  {t("text.txtPHSendMsg", { ns: "contactus" })}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
    </Suspense>
  );
};

export default Contact;
