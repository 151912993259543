import React, { useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Slide } from "@mui/material";
import axios from "axios";
import { useForm, Form } from "../../components/useForm";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
//import IconButton from "@mui/material/IconButton";
//import CloseIcon from "@mui/icons-material/Close";
//import * as employeeService from "../../services/employeeService";
//For date time picker
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// loading component for suspense fallback
const Loader = () => (
  <div>
      <b>Please Wait something is still loading...</b>
  </div>
);

const initialFValues = {
  usid: 0,
  dateTime: new Date().toLocaleString(),
  //dateTime: "new Date()",
  activityName: "",
  preHR: "",
  postHR: "",
  results: "",
  rpeBorg: "",
  symptoms: ""
};

const BASE_URL = `${ConfigData.protoipaport}/activitiesdiary`;


export default function ActivityInsertForm() {
  const { t } = useTranslation(["translation", "activities"]);

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);
  const [datetimepickervalue, setDateTimePickerValue] = React.useState(
    dayjs(new Date().toLocaleString())
  );

  //toLocaleDateString("en-IN")
  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

/*  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  ); */

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("activityName" in fieldValues) {
      // temp.activityName =
      //   fieldValues.activityName === "None" ? "This field is required." : "" ;
      temp.activityName = fieldValues.activityName
        ? ""
        : "This field is required.";
    }
    if ("preHR" in fieldValues)
      temp.preHR = fieldValues.preHR ? "" : "Value in this field is required.";
    if ("postHR" in fieldValues)
      temp.postHR = fieldValues.postHR ? "" : "Value in this field is required.";
    if ("results" in fieldValues)
      temp.results = fieldValues.results ? "" : "Value in this field is required.";
    if ("rpeBorg" in fieldValues)
      temp.rpeBorg = fieldValues.rpeBorg ? "" : "Value in this field is required.";
    if ("symptoms" in fieldValues)
      temp.symptoms = fieldValues.symptoms ? "" : "Value in this field is required.";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // eslint-disable-next-line
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);
  //const [errors, setErrors] = useState({});

  //Activity Type
  //const [activitytype, setActivityType] = useState([]);
  //const [activitytypeid, setActivityTypeId] = useState(-1);

  //Activity Type with i18n
  const actType = [
    { id: "101", value: "Brisk Walk", title: t("title.t101", { ns: "activities" }) },
    { id: "102", value: "Cycling", title: t("title.t102", { ns: "activities" }) },
    { id: "103", value: "Running", title: t("title.t103", { ns: "activities" }) },
    { id: "104", value: "Rope Jumping", title: t("title.t104", { ns: "activities" }) },
    { id: "105", value: "Smoke Quantity", title: t("title.t105", { ns: "activities" }) },
    { id: "106", value: "Alcohol Quantiy", title: t("title.t106", { ns: "activities" }) },
    { id: "107", value: "Tread Mill", title:t("title.t107", { ns: "activities" }) },
    { id: "108", value: "Jogging", title: t("title.t108", { ns: "activities" }) },
    { id: "109", value: "Blood Preassure", title: t("title.t109", { ns: "activities" }) },
    { id: "110", value: "Sugar Level", title: t("title.t110", { ns: "activities" }) },
    { id: "111", value: "Oxygen Level", title: t("title.t111", { ns: "activities" }) }
  ];

  //Fixed RPE/BORG Scale
  const rpeBorgScale = [
    { id: "1", value: "6", title: t("rpetitle.t6", { ns: "activities" }) },
    { id: "2", value: "7", title: t("rpetitle.t7", { ns: "activities" }) },
    { id: "3", value: "8", title: t("rpetitle.t8", { ns: "activities" }) },
    { id: "4", value: "9", title: t("rpetitle.t9", { ns: "activities" }) },
    { id: "5", value: "10", title: t("rpetitle.t10", { ns: "activities" }) },
    { id: "6", value: "11", title: t("rpetitle.t11", { ns: "activities" }) },
    { id: "7", value: "12", title: t("rpetitle.t12", { ns: "activities" }) },
    { id: "8", value: "13", title: t("rpetitle.t13", { ns: "activities" }) },
    { id: "9", value: "14", title: t("rpetitle.t14", { ns: "activities" }) },
    { id: "10", value: "15", title: t("rpetitle.t15", { ns: "activities" }) },
    { id: "11", value: "16", title: t("rpetitle.t16", { ns: "activities" }) },
    { id: "12", value: "17", title: t("rpetitle.t17", { ns: "activities" }) },
    { id: "13", value: "18", title: t("rpetitle.t18", { ns: "activities" }) },
    { id: "14", value: "19", title: t("rpetitle.t19", { ns: "activities" }) },
    { id: "15", value: "20", title: t("rpetitle.t20", { ns: "activities" }) }
  ];


  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Creating axios object for getting all the activity types
  // const axiosGetActivityType = axios.create({
  //   baseURL: BASE_URL,
  //   method: "GET",
  //   headers: {
  //     Authorization: `Bearer ${token}` //jwt security
  //   }
  // });

  //Creating axios object for activity insertion
  const axiosInsertActivity = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Activity Type
  // useEffect(() => {
  //   const getactivitytype = async () => {
  //     const res = await axiosGetActivityType.get(`/getallactivitiestype`);
  //     const getAct = await res.data;
  //     setActivityType(await getAct);
  //   };
  //   getactivitytype();
  //   // eslint-disable-next-line
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //Current date creation
    var now = new Date(datetimepickervalue); // if using Date Time picker value
    //var now = new Date(); // if using the current fixed date time
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    if (validate()) {
      //alert("Validation Success");

      try {
        alert(
          "Exercise Activity once added cannot be deleted or modified again. Are you sure you want to Continue?"
        );

        //Show warning snackbar with alert
        // handleClick(
        //   "warning",
        //   "Warning",
        //   "Data once inserted cannot be deleted or modified again. Are you sure you want to Continue? — <strong>check it out!</strong>"
        // );

        /* For testing/debugging purpose */
        //alert("user id is: " + userid);
        // alert("dateTimePicker value  is: " + new Date(datetimepickervalue));
        // alert("ISO dateTimePicker value  is: " + isoDate);
        // alert("dateTime is: " + values["dateTime"]);
        // alert(" new dateTime is: " + new Date(values["dateTime"]));

        // alert("activityName is: " + values["activityName"]);
        // alert("preHR is: " + values["preHR"]);
        // alert("postHR is: " + values["postHR"]);
        // alert("results is: " + values["results"]);
        // alert("rpeBorg is: " + values["rpeBorg"]);
        // alert("symptoms is: " + values["symptoms"]);

        // eslint-disable-next-line
        const response = await axiosInsertActivity
          .post("/insertactivity", {
            userid: userid,
            dateTime: isoDate,
            activityName: values["activityName"],
            preHR: values["preHR"],
            postHR: values["postHR"],
            results: values["results"],
            rpeBorg: values["rpeBorg"],
            symptoms: values["symptoms"]
          })
          .then(
            (response) => {
              console.log(response);
              //Show success snackbar with alert
              handleClick(
                "success",
                "Success",
                "Exercise Activity added successfully."
              );
              //alert("Activity inserted Successfully!");
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in inserting the excercise Activity....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              //alert("Activity Insertion Failure!");
              //Show error snackbar with alert
              handleClick(
                "error",
                "Error",
                "Error in adding the Exercise Activity. Activity could not be added!"
              );
            }
          );
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
        //alert("Insertion failure! " + err);
        //Show error snackbar with alert
        handleClick(
          "error",
          "Error",
          "Exercise Activity could not be added. Activity Insertion failure!"
        );
      }

      resetForm();
    } else {
      //alert("Form validation fails!");
      //Show error snackbar with alert
      handleClick(
        "error",
        "Error",
        "There is some error in validating the values Inserted. Please, enter the proper values in each field before submitting the exercise activity."
      );
    }
  };

  return (
    
 <Suspense fallback={<Loader />}>

    <Form>
      <Grid container>
        {/* Snackbar with alert */}
        <div>
          <Snackbar
            open={open}
            //message="Material UI snackbar"
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            //action={action}
          >
            <Alert
              severity={severity}
              variant="filled"
              //icon={false}
              onClose={handleClose}
            >
              <AlertTitle>{alerttitle}</AlertTitle>
              {alertmessage}
            </Alert>
          </Snackbar>
        </div>

        <Grid item xs={6}>
          {/* <Controls.DatePicker
            name="dateTime"
            label="Date/Time of Activity"
            value={values.dateTime}
            onChange={handleInputChange}
            error={errors.dateTime}
          /> */}

          {/* <Controls.Input
            name="dateTime"
            label="Date/Time of Activity"
            value={values.dateTime}
            onChange={handleInputChange}
            InputProps={{
              readOnly: true
            }}
          /> */}

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DemoItem>
                <DateTimePicker
                  name="dateTime"
                  label={t("activities.txtDateTime", { ns: "activities" })}
                  value={datetimepickervalue}
                  onChange={(newValue) => setDateTimePickerValue(newValue)}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>

          <Controls.Input
            name="preHR"
            label={t("activities.txtPreHR", { ns: "activities" })}
            value={values.preHR}
            onChange={handleInputChange}
            error={errors.preHR}
          />

          <Controls.Input
            name="results"
            label={t("activities.txtResults", { ns: "activities" })}
            value={values.results}
            onChange={handleInputChange}
            error={errors.results}
          />

          <Controls.Input
            label={t("activities.txtComments", { ns: "activities" })}
            name="symptoms"
            value={values.symptoms}
            onChange={handleInputChange}
            error={errors.symptoms}
          />
        </Grid>

        <Grid item xs={6}>
          <Controls.Select
            label={t("activities.txtTOA", { ns: "activities" })}
            name="activityName"
            value={values.activityName}
            onChange={handleInputChange}
            options={actType}
            //options={employeeService.getDepartmentCollection()}
            error={errors.activityName}
          />

          <Controls.Input
            label={t("activities.txtPostHR", { ns: "activities" })}
            name="postHR"
            value={values.postHR}
            onChange={handleInputChange}
            error={errors.postHR}
          />

          <Controls.SelectRPE
            label={t("activities.txtRpeBorg", { ns: "activities" })}
            name="rpeBorg"
            value={values.rpeBorg}
            onChange={handleInputChange}
            options={rpeBorgScale}
            error={errors.rpeBorg}
          />

          <div border="2" align="center">
            &nbsp;&nbsp;
            <Controls.Button type="submit" onClick={handleSubmit} text={t("activities.txtAddBtn", { ns: "activities" })} />
            &nbsp;&nbsp;&nbsp;
            <Controls.Button text={t("activities.txtResetBtn", { ns: "activities" })} onClick={resetForm} color="inherit" />
          </div>
        </Grid>
      </Grid>
    </Form>
    
 </Suspense>

  );
}
