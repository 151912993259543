import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Footer = () => {
  const { t } = useTranslation(["translation", "footer"]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h3>{t("footer.ftrLogo", { ns: "footer" })}</h3>
                  <p>
                    {/* {t("footer.ftrAddr1", { ns: "footer" })} <br />
                        {t("footer.ftrAddr2", { ns: "footer" })} <br />
                        {t("footer.ftrAddr3", { ns: "footer" })} <br />
                        {t("footer.ftrAddr4", { ns: "footer" })}
                        <br />
                        {t("footer.ftrAddr5", { ns: "footer" })} <br /> */}
                    {/* <strong>Phone (WhattsApp Number):</strong> +91 xxxx-xxxx-xxxx  */}
                    {/* {/* <strong>Phone </strong>(WhattsApp)<strong>:</strong> +91-9920738871  */}
                    {/* <br /> */}
                    <strong>{t("footer.ftrEmaillbl", { ns: "footer" })}</strong> {t("footer.ftrEmail", { ns: "footer" })}
                    <br />
                  </p>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>{t("footer.ftrUL", { ns: "footer" })}</h4>
                  <ul>
                    {/* <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/Home">{t("footer.ftrHome", { ns: "footer" })}</Link>
                    </li> */}
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/Testimonials">{t("footer.ftrOP", { ns: "footer" })}</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/ToService">{t("footer.ftrTOS", { ns: "footer" })}</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/PrivPolicy">{t("footer.ftrPP", { ns: "footer" })}</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>{t("footer.ftrOS", { ns: "footer" })}</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/CRExplained">{t("footer.ftrCRE", { ns: "footer" })}</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/CRHome">{t("footer.ftrCRH", { ns: "footer" })}</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/KYHHealth">{t("footer.ftrKHH", { ns: "footer" })}</Link>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <Link to="/SOHAilment">{t("footer.ftrSOHA", { ns: "footer" })}</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>{t("footer.ftrOSN", { ns: "footer" })}</h4>
                  <p>
                    {t("footer.ftrTxtSN", { ns: "footer" })}
                  </p>
                  <div className="social-links mt-3">
                    {/* <Link to="/Home" className="twitter">
                          <i className="bx bxl-twitter"></i>
                        </Link>
                        <Link to="/Home" className="facebook">
                          <i className="bx bxl-facebook"></i>
                        </Link>
                        <Link to="/Home" className="instagram">
                          <i className="bx bxl-instagram"></i>
                        </Link>
                        <Link to="Home" className="google-plus">
                          <i className="bx bxl-skype"></i>
                        </Link>
                        <Link to="/Home" className="linkedin">
                          <i className="bx bxl-linkedin"></i>
                        </Link> */}

                    <a
                      className="twitter"
                      target="_Myblank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/CardiRehab"
                      onClick={() =>
                        window.open(
                          "https://twitter.com/CardiRehab",
                          "_Myblank",
                          "width=1200, height=950"
                        )
                      }
                    >
                      <i className="bx bxl-twitter"></i>
                    </a>

                    <a
                      className="facebook"
                      target="_Myblank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/profile.php?id=61556631058784"
                      onClick={() =>
                        window.open(
                          "https://www.facebook.com/profile.php?id=61556631058784",
                          "_Myblank",
                          "width=1200, height=950"
                        )
                      }
                    >
                      <i className="bx bxl-facebook"></i>
                    </a>

                    <a
                      className="instagram"
                      target="_Myblank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/cardirehab/"
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/cardirehab/",
                          "_Myblank",
                          "width=1200, height=950"
                        )
                      }
                    >
                      <i className="bx bxl-instagram"></i>
                    </a>

                    <a
                      className="pinterest"
                      target="_Myblank"
                      rel="noopener noreferrer"
                      href="https://in.pinterest.com/cardirehabsolution"
                      onClick={() =>
                        window.open(
                          "https://in.pinterest.com/cardirehabsolution",
                          "_Myblank",
                          "width=1200, height=950"
                        )
                      }
                    >
                      <i className="bx bxl-pinterest"></i>
                    </a>

                    <a
                      className="linkedin"
                      target="_Myblank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/cardirehab/"
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/cardirehab/",
                          "_Myblank",
                          "width=1200, height=950"
                        )
                      }
                    >
                      <i className="bx bxl-linkedin"></i>
                    </a>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container py-4">
            <div className="copyright">
              &copy; {t("footer.ftrPreCR", { ns: "footer" })}{" "}
              <strong>
                <span>{t("footer.ftrCName", { ns: "footer" })} </span>
              </strong>
              {t("footer.ftrPostCR", { ns: "footer" })}
            </div>
            <div className="credits">{t("footer.ftrLblDesBy", { ns: "footer" })} {t("footer.ftrDesByTxt", { ns: "footer" })}</div>
          </div>
        </footer>


      </Suspense>
    </>
  );
};

export default Footer;
