import React from "react";
//import Login from "./login/Login";
import Home from "./Home";
import Header from "./Header";

const Logout = () => {
  return (
    <>
      {localStorage.removeItem("token")}
      {localStorage.removeItem("username")}
      {localStorage.removeItem("userid")}
      {localStorage.removeItem("roles")}
      {localStorage.removeItem("message")};
      {<Header />} {<Home/>} 
    </>
  );
};
export default Logout;
