import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import axios from "axios";
import { Toolbar } from "@mui/material";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import { makeStyles } from "@mui/styles";
import PageHeader from "../../components/PageHeader";
import ViewAllUsersCRSPL from "./ViewAllUsersCRSPL";
//import { styled } from "@mui/material/styles";

// For using MUI-MIT Data Grid
import Box from "@mui/material/Box";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

// Icons
//import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
//import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
// import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
// import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
// import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
// import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";
// import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
//import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";

// For implementing Autocomplete box
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { styled, lighten, darken } from "@mui/system";
import { useForm } from "../../components/useForm";

// For snackbar and alerts
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";
import { Slide } from "@mui/material";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}));

const GroupItems = styled("ul")({
  padding: 0
});

const initialFValues = {
  usid: 0,
  dateTime: new Date().toLocaleString(),
  //dateTime: new Date(),
  activityName: "",
  preHR: "",
  postHR: "",
  results: "",
  rpeBorg: "",
  symptoms: ""
};

//To determine if row updation is required or not
let updRequiredFlag = false;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const BASE_URL = `${ConfigData.protoipaport}/activitiesdiary`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: "85%"
  },
  newButton: {
    position: "absolute",
    right: "0px",
    left: "15px"
  },

  tHead: {
    marginTop: theme.spacing(3)
    // fontWeight: "600",
    // color: theme.palette.primary.main,
    // backgroundColor: theme.palette.primary.light
  },

  // For Data grid Header
  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light
    },
    "& tbody td": {
      fontWeight: "300"
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer"
    }
  }
}));

const headCells = [
  //{ id: "dtTime", label: "Date/Time" },
  { id: "activityName", label: "Activity" },
  { id: "preHR", label: "PreHR" },
  { id: "postHR", label: "PostHR" },
  { id: "results", label: "Results", disableSorting: true },
  { id: "rpeBorg", label: "RPE/BORG" },
  { id: "symptoms", label: "Comments", disableSorting: true }
];

// Dummy data for autocomplete checkbox
// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
/* const top100Films = [
  { id: 1, title: "The Shawshank Redemption", year: 1994 },
  { id: 2, title: "The Godfather", year: 1972 },
  { id: 3, title: "The Godfather: Part II", year: 1974 },
  { id: 4, title: "The Dark Knight", year: 2008 },
  { id: 5, title: "12 Angry Men", year: 1957 },
  { id: 6, title: "Schindler's List", year: 1993 },
  { id: 7, title: "Pulp Fiction", year: 1994 },
  {
    id: 8,
    title: "The Lord of the Rings: The Return of the King",
    year: 2003
  },
  { id: 9, title: "The Good, the Bad and the Ugly", year: 1966 },
  { id: 10, title: "Fight Club", year: 1999 },
  {
    id: 11,
    title: "The Lord of the Rings: The Fellowship of the Ring",
    year: 2001
  },
  {
    id: 12,
    title: "Star Wars: Episode V - The Empire Strikes Back",
    year: 1980
  },
  { id: 13, title: "Forrest Gump", year: 1994 },
  { id: 14, title: "Inception", year: 2010 },
  {
    id: 15,
    title: "The Lord of the Rings: The Two Towers",
    year: 2002
  },
  { id: 16, title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { id: 17, title: "Goodfellas", year: 1990 },
  { id: 18, title: "The Matrix", year: 1999 },
  { id: 19, title: "Seven Samurai", year: 1954 },
  {
    id: 20,
    title: "Star Wars: Episode IV - A New Hope",
    year: 1977
  },
  { id: 21, title: "City of God", year: 2002 },
  { id: 22, title: "Se7en", year: 1995 },
  { id: 23, title: "The Silence of the Lambs", year: 1991 },
  { id: 24, title: "It's a Wonderful Life", year: 1946 },
  { id: 25, title: "Life Is Beautiful", year: 1997 },
  { id: 26, title: "The Usual Suspects", year: 1995 },
  { id: 27, title: "Léon: The Professional", year: 1994 },
  { id: 28, title: "Spirited Away", year: 2001 },
  { id: 29, title: "Saving Private Ryan", year: 1998 },
  { id: 30, title: "Once Upon a Time in the West", year: 1968 },
  { id: 31, title: "American History X", year: 1998 },
  { id: 32, title: "Interstellar", year: 2014 }
]; */

//Dummy coloumns data for MUI - MIT Data Grid
/* const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 90
  },
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
    editable: true
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
    editable: true
  },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 110,
    editable: true
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.row.firstName || ""} ${params.row.lastName || ""}`
  }
]; 

// //Dummy coloumns data for MUI - MIT Data Grid
// const columnsCHS = [
//   // {
//   //   field: "id",
//   //   headerName: "S.No.",
//   //   width: 5
//   // },
//   // {
//   //   field: "dtTime",
//   //   headerName: "Date/Time"
//   //   //width: 50
//   // },
//   {
//     //field: "actType",
//     field: "activitiesType",
//     headerName: "Activity"
//     //width: 50
//   },
//   {
//     field: "actFreq",
//     headerName: "Frequency",
//     //width: 50,
//     editable: true
//   },
//   {
//     field: "peakHR",
//     headerName: "PeakHR",
//     //type: "number",
//     //width: 20,
//     editable: true
//   },
//   {
//     field: "peakBorgRPE",
//     headerName: "Peak RPE/BORG",
//     //type: "number",
//     //width: 50,
//     editable: true
//   },
//   {
//     field: "minDuration",
//     //field: "year",
//     headerName: "Duration(mins)",
//     //type: "number",
//     //width: 50,
//     editable: true
//   },
//   // {
//   //   field: "actStatus",
//   //   headerName: "Status",
//   //   valueGetter: () => {
//   //     return "Pending";
//   //   }
//   //   //width: 55
//   // },

//   {
//     field: "currDateTime",
//     headerName: "DateTime",
//     valueGetter: () => {
//       return new Date();
//     }

//     //width: 55
//   },
//   {
//     field: "comments",
//     headerName: "Others",
//     //description: "This column has a value getter and is not sortable.",
//     sortable: false,
//     editable: true
//     //width: 160,
//     // valueGetter: (params) =>
//     //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
//   },
//   {
//     field: "actions",
//     headerName: "Actions",
//     type: "actions",
//     //width: 100,
//     getActions: (params) => [
//       <GridActionsCellItem icon={<EditIcon />} label="Edit" />,
//       <GridActionsCellItem
//         icon={<DeleteIcon />}
//         label="Delete"
//         onClick={deleteActivity(params.id)}
//       />
//     ]
//   }
// ];

//Dummy rows data for MUI - MIT Data Grid
/* const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 }
]; */

//Dummy rows data for MUI - MIT Data Grid
/* const rowsCHS = [
  {
    id: 1,
    //dtTime: "12May2023 7:33AM",
    actType: "Cycling",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  },
  {
    id: 2,
    //dtTime: "12May2023 7:33AM",
    actType: "Jogging",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  },
  {
    id: 3,
    //dtTime: "12May2023 7:33AM",
    actType: "Brisk Walk",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  },
  {
    id: 4,
    //dtTime: "12May2023 7:33AM",
    actType: "Jumping",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  },
  {
    id: 5,
    //dtTime: "12May2023 7:33AM",
    actType: "Running",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  },
  {
    id: 6,
    //dtTime: "12May2023 7:33AM",
    actType: "Rope",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  },
  {
    id: 7,
    //dtTime: "12May2023 7:33AM",
    actType: "Treadmill",
    freqActivity: "2 times a day",
    peakHR: "85 Beats/min",
    peakBorg: "16",
    minDuration: "30",
    actStatus: "Pending",
    comments: "Cover at least 20Kms"
  }
]; */

export default function AssignActivityFormCRSPL({
  assigneduserid,
  assignedusername
}) {
  const classes = useStyles();

  //For snackbar and alert
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);

  //For snackbar and alert
  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  //For snackbar and alert
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //To go back to the previous page
  const [tempAssignActFlag, setTempAssignActFlag] = useState(false);

  //Activities data
  // eslint-disable-next-line
  const [records, setRecords] = useState([]);
  // eslint-disable-next-line
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  //const [defaultValues, setDefaultValues] = useState([]);
  // defaultValues.push({ id: "101", activitiesType: "Brisk Walk" });
  // defaultValues.push({ id: "103", activitiesType: "Running" });
  // defaultValues.push({ id: "102", activitiesType: "Cycling" });
  const [selectedActivities, setSelectedActivities] = useState([]);

  const {
    // eslint-disable-next-line
    TblContainer,
    // eslint-disable-next-line
    TblHead,
    // eslint-disable-next-line
    TblPagination,
    // eslint-disable-next-line
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  //Activity Type
  const [activitiesplan, setActivitiesPlan] = useState([]);

  //Activity Type
  const [activitytype, setActivityType] = useState([]);

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(assigneduserid);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("activityName" in fieldValues) {
      // temp.activityName =
      //   fieldValues.activityName === "None" ? "This field is required." : "" ;
      temp.activityName = fieldValues.activityName
        ? ""
        : "Value in this field is required.";
    }
    if ("preHR" in fieldValues)
      temp.preHR = fieldValues.preHR ? "" : "Value in this field is required.";
    if ("postHR" in fieldValues)
      temp.postHR = fieldValues.postHR ? "" : "Value in this field is required.";
    if ("results" in fieldValues)
      temp.results = fieldValues.results ? "" : "Value in this field is required.";
    if ("rpeBorg" in fieldValues)
      temp.rpeBorg = fieldValues.rpeBorg ? "" : "Value in this field is required.";
    if ("symptoms" in fieldValues)
      temp.symptoms = fieldValues.symptoms ? "" : "Value in this field is required.";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // eslint-disable-next-line
    setValues,
    errors,
    setErrors,
    // eslint-disable-next-line
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);

  //Reading activities plan by user id
  const axiosGetActivitiesPlan = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Creating axios object for getting all the activity types
  const axiosGetActivityType = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Creating axios object for assigned activities insertion
  const axiosInsertAssignedActivities = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Deleting the  assigned activity by activity id
  const axiosDeleteAssignedActivity = axios.create({
    baseURL: BASE_URL,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Updating the  assigned activity by activity id
  // eslint-disable-next-line
  const axiosUpdateAssignedActivity = axios.create({
    baseURL: BASE_URL,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  // Read Activities Plan by userid
  useEffect(() => {
    const getactivitiesplan = async () => {
      const wStatus = "Active";
      const res = await axiosGetActivitiesPlan.get(
        `/getassignedactivitiesuid/${userid}/${wStatus}`
      );
      const getActPlan = await res.data;
      //chatUsers.push(response_array);
      //setChatUsers([...response_array]);
      setActivitiesPlan(await getActPlan);
      setSelectedActivities(activitiesplan);
      //console.log("This is activities Plan: " + activitiesplan);
    };
    getactivitiesplan();
    // eslint-disable-next-line
  }, [activitytype]);

  //, [activitytype][gridUpdated]);
  //}, [activitytype]);
  //  }, [selectedActivities]);

  //Activity Type
  useEffect(() => {
    const getactivitytype = async () => {
      const res = await axiosGetActivityType.get(`/getallactivitiestype`);
      const getAct = await res.data;

      setActivityType(await getAct);
    };
    getactivitytype();
    // eslint-disable-next-line
  }, []);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626"
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959"
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343"
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c"
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff"
    }
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const handleInsertActivitiesPlan = async (e) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    //const selectedActivitiesCopy = { ...selectedActivities };
    const selectedActivitiesCopy = [];
    /* Looping to remove the unwanted or duplicate rows (which are already there in the database) */
    selectedActivities.forEach((a, index) => {
      if (
        !a.currDateTime ||
        a.currDateTime === null ||
        a.currDateTime === undefined ||
        !a.acttypeid ||
        a.acttypeid === null ||
        a.acttypeid === undefined ||
        !a.actStatus ||
        a.actStatus === null ||
        a.actStatus === undefined
      ) {
        /* Push or Copy the row object into new array element */
        selectedActivitiesCopy.push(selectedActivities[index]);
      } else {
        /* Do Nothing */
      }
    });

    /* Looping to initialize the values properly in every new or remaining(after filtering above) rows of a plan */
    selectedActivitiesCopy.forEach((a, index) => {
      /* User id for which the plan is generated */
      a.userid = userid;
      /* Activity type id changed to id field from grid */
      a.acttypeid = a.id;
      a.id = 0;
      /* Status of the exercise - Pending, completed or Missed */
      a.actStatus = "Pending";
      /* Date time at which the Exercise is prescribed/assigned */
      a.currDateTime = isoDate;
      /* To determine the history of the exercise - deleted, changed or Active */
      a.actWorkStatus = "Active";
      /* Date time at which the working status is changed */
      a.workStatusChangedDT = isoDate;
      /* Frequency of exercise */
      if (!a.actFreq || a.actFreq === null || a.actFreq === undefined) {
        a.actFreq = "";
      }
      /* Peak Heart Rate to achieve */
      if (!a.peakHR || a.peakHR === null || a.peakHR === undefined) {
        a.peakHR = "";
      }
      /* Peak RPE/BORG to achieve */
      if (
        !a.peakBorgRPE ||
        a.peakBorgRPE === null ||
        a.peakBorgRPE === undefined
      ) {
        a.peakBorgRPE = "";
      }
      /* Duration of minutes to perform the exercise */
      if (
        !a.minDuration ||
        a.minDuration === null ||
        a.minDuration === undefined
      ) {
        a.minDuration = "";
      }
      /* Any other comments or instructions */
      if (!a.comments || a.comments === null || a.comments === undefined) {
        a.comments = "";
      }
    });

    //if (validate()) {
    //alert("Validation Success");

    if (selectedActivities.length === 0) {
      handleClick(
        "error",
        "Error",
        "No records or activity assigned to generate the plan."
      );
    } else {
      if (selectedActivitiesCopy.length === 0) {
        handleClick(
          "warning",
          "Warning",
          "This plan is already generated. It cannot be regenerate."
        );
      } else {
        try {
          alert(
            "Certain activity/activities are newly assigned. Are you sure you want to add/re-generate the current activities Plan?"
          );

          //Show warning snackbar with alert
          // handleClick(
          //   "warning",
          //   "Warning",
          //   "Activity once inserted cannot be deleted or modified again. Are you sure you want to Continue?"
          // );

          // eslint-disable-next-line
          const response = await axiosInsertAssignedActivities

            .post("/insertassignedactivities", selectedActivitiesCopy)
            .then(
              (response) => {
                console.log(response);
                //Show success snackbar with alert
                handleClick(
                  "success",
                  "Success",
                  "Activity assignment plan is re-generated or modified successfully."
                );

                /* Initialize and back assign the values here - both for pure plan and mix plan */
                if (
                  selectedActivities.length === selectedActivitiesCopy.length
                ) {
                  selectedActivities.forEach((a, index) => {
                    a.id = response.data[index].id;
                    a.userid = response.data[index].userid;
                    a.acttypeid = response.data[index].acttypeid;
                    //a.activitiesType = response.data[index].activitiesType; //Not required to do so
                    a.actStatus = response.data[index].actStatus;
                    a.currDateTime = response.data[index].currDateTime;
                    a.actWorkStatus = response.data[index].actWorkStatus;
                    a.workStatusChangedDT =
                      response.data[index].workStatusChangedDT;
                    a.actFreq = response.data[index].actFreq;
                    a.peakHR = response.data[index].peakHR;
                    a.peakBorgRPE = response.data[index].peakBorgRPE;
                    a.minDuration = response.data[index].minDuration;
                    a.comments = response.data[index].comments;
                  });
                } else {
                  //Nested loops to back assign the values
                  selectedActivities.forEach((a) => {
                    selectedActivitiesCopy.forEach((b, index) => {
                      if (a.activitiesType === b.activitiesType) {
                        a.id = response.data[index].id;
                        a.userid = response.data[index].userid;
                        a.acttypeid = response.data[index].acttypeid;
                        //a.activitiesType = response.data[index].activitiesType; //Not required to do so
                        a.actStatus = response.data[index].actStatus;
                        a.currDateTime = response.data[index].currDateTime;
                        a.actWorkStatus = response.data[index].actWorkStatus;
                        a.workStatusChangedDT =
                          response.data[index].workStatusChangedDT;
                        a.actFreq = response.data[index].actFreq;
                        a.peakHR = response.data[index].peakHR;
                        a.peakBorgRPE = response.data[index].peakBorgRPE;
                        a.minDuration = response.data[index].minDuration;
                        a.comments = response.data[index].comments;
                      }
                    });
                  });
                }

                /* Clear the temp array */
                selectedActivitiesCopy.length = 0;
                //alert("Activity inserted Successfully!");
              },
              (error) => {
                if (error.response) {
                  console.log(error.response.data);
                  const scode = error.response.status;
                  if (scode === 401 || scode === 403 || scode === 404) {
                    localStorage.setItem(
                      "message",
                      "Error in generating the Activity assignment plan....."
                    );
                    //Show error snackbar with alert
                    handleClick(
                      "error",
                      "Error",
                      "Error in generating the Activity assignment plan."
                    );
                  }
                  console.log(error.response.status);
                  console.log(error.response.headers);
                }
                console.log("Error is below: ");
                console.log(error);
                console.log(error.data.message);
                //alert("Activity Insertion Failure!");
                //Show error snackbar with alert
                handleClick(
                  "error",
                  "Error",
                  "Activity Assignment plan generation failure."
                );
              }
            );
        } catch (err) {
          console.log(err.response);
          console.log(err.message);
          //alert("Insertion failure! " + err);
          //Show error snackbar with alert
          handleClick(
            "error",
            "Error",
            "Assignment Plan Generation failure."
          );
        }
      }
    }

    //resetForm();
    // } else {
    //   //alert("Form validation fails!");
    //   //Show error snackbar with alert
    //   handleClick(
    //     "error",
    //     "Error",
    //     "Form validation fails."
    //   );
    // }
  };

  const handleUpdNewGridRowInsert = async (e, params) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    // eslint-disable-next-line
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    //if (validate()) {
    //alert("Validation Success");

    /* Frequency of exercise */
    let actFreq = params.row.actFreq;
    if (
      !params.row.actFreq ||
      params.row.actFreq === null ||
      params.row.actFreq === undefined
    ) {
      // eslint-disable-next-line
      actFreq = "";
    }

    /* Peak Heart Rate to achieve */
    let peakHR = params.row.peakHR;
    if (
      !params.row.peakHR ||
      params.row.peakHR === null ||
      params.row.peakHR === undefined
    ) {
      // eslint-disable-next-line
      peakHR = "";
    }

    /* Peak RPE/BORG to achieve */
    let peakBorgRPE = params.row.peakBorgRPE;
    if (
      !params.row.peakBorgRPE ||
      params.row.peakBorgRPE === null ||
      params.row.peakBorgRPE === undefined
    ) {
      // eslint-disable-next-line
      peakBorgRPE = "";
    }

    /* Duration of minutes to perform the exercise */
    let minDuration = params.row.minDuration;
    if (
      !params.row.minDuration ||
      params.row.minDuration === null ||
      params.row.minDuration === undefined
    ) {
    // eslint-disable-next-line
      minDuration = "";
    }

    /* Any other comments or instructions */
    let comments = params.row.comments;
    if (
      !params.row.comments ||
      params.row.comments === null ||
      params.row.comments === undefined
    ) {
      // eslint-disable-next-line
      comments = "";
    }

    try {
      alert("Are you sure you want to update/insert this exercise activity?");

      //To insert the new clone of the old record before updation
      // const responseInsUpdClone = await axiosUpdateAssignedActivity
      //   .put(`/updAssignedActivity/${0}`, {
      //     /* User id for which the new row is generated */
      //     userid: assigneduserid,

      //     /* Activity type id changed to id field from grid */
      //     acttypeid: params.row.id,
      //     id: 0,

      //     /* Activity type id changed to id field from grid */
      //     activitiesType: params.row.activitiesType,

      //     /* Status of the exercise - Pending, completed or Missed */
      //     actStatus: "Pending",

      //     /* Date time at which the Exercise is prescribed/assigned */
      //     currDateTime: isoDate,

      //     /* Frequency of exercise */
      //     actFreq: actFreq,

      //     /* Peak Heart Rate to achieve */
      //     peakHR: peakHR,

      //     /* Peak RPE/BORG to achieve */
      //     peakBorgRPE: peakBorgRPE,

      //     /* Duration of minutes to perform the exercise */
      //     minDuration: minDuration,

      //     /* Any other comments or instructions */

      //     comments: comments,

      //     /* To determine the history of the exercise - Deleted, Updated or Active */
      //     actWorkStatus: "Active",

      //     /* Date time at which the working status is changed */
      //     workStatusChangedDT: isoDate
      //   })
      //   .then(
      //     (response) => {
      //       console.log(response);
      //       //Show success snackbar with alert
      //       handleClick(
      //         "success",
      //         "Success",
      //         "Activity inserted successfully."
      //       );

      //       /* Assign the newly generated id as well as current date time to an old row id in grid */
      //       params.row.id = response.data.id;
      //       params.row.currDateTime = response.data.currDateTime;
      //       params.row.actStatus = response.data.actStatus;
      //       params.row.acttypeid = response.data.acttypeid;
      //       params.row.userid = response.data.userid;
      //       //alert(`New id is: ${params.row.id}`);
      //       //alert("Activity updated/inserted Successfully!");
      //     },
      //     (error) => {
      //       if (error.response) {
      //         console.log(error.response.data);
      //         const scode = error.response.status;
      //         if (scode === 401 || scode === 403 || scode === 404) {
      //           localStorage.setItem(
      //             "message",
      //             "Error in inserting the Activity....."
      //           );
      //         }
      //         console.log(error.response.status);
      //         console.log(error.response.headers);
      //       }
      //       console.log("Error is below: ");
      //       console.log(error);
      //       console.log(error.data.message);
      //       //alert("Activity Deletion Failure!");
      //       //Show error snackbar with alert
      //       handleClick(
      //         "error",
      //         "Error",
      //         "Assigned New Grid Activity Insertion Failure."
      //       );
      //     }
      //   );
    } catch (err) {
      console.log(err.response);
      console.log(err.message);
      //alert("Insertion failure! " + err);
      //Show error snackbar with alert
      handleClick(
        "error",
        "Error",
        "Activity Insertion failure."
      );
    }

    resetForm();
    // } else {
    //   //alert("Form validation fails!");
    //   //Show error snackbar with alert
    //   handleClick(
    //     "error",
    //     "Error",
    //     "Form validation fails."
    //   );
    // }
  };

  /* const handleSearch = (e) => {
     let target = e.target;
     setFilterFn({
       fn: (items) => {
         if (target.value === "") return items;
         else
           return items.filter((x) =>
             x.activityName.toLowerCase().includes(target.value)
           );
       }
     });
   }; */

  const handleShowConAllUserPage = (e) => {
    setTempAssignActFlag(true);
  };

  const handleOnChangeAC = (_, value, reason) => {
    //console.log(value, reason);
    //if (reason === "selectOption") {
    /* To update the autocomplete(with value prop set) textbox on the fly */
    setActivitiesPlan(value);
    /* To update the data grid values on the fly */
    setSelectedActivities(value);
    //}
  };

  // Processing the row updation
  const processRowUpdate = (newRow, oldRow) => {
    //Setting the rowUpdationRequired Flag
    if (
      oldRow.actFreq === newRow.actFreq &&
      oldRow.peakHR === newRow.peakHR &&
      oldRow.peakBorgRPE === newRow.peakBorgRPE &&
      oldRow.minDuration === newRow.minDuration &&
      oldRow.comments === newRow.comments
    ) {
      updRequiredFlag = false;
      //alert("Flag is:  " + updRequiredFlag);
    } else {
      updRequiredFlag = true;
      //alert("Flag is:  " + updRequiredFlag);
    }

    //Setting the selected activities current rows
    setSelectedActivities((prevRows) => {
      const newRows = [...prevRows].map((row) => {
        if (row.id === newRow.id) return newRow;
        return row;
      });
      return newRows;
    });
    // the DataGrid expects the newRow as well
    return newRow;
  };

  //(id) =>
  //To delete the record
  const deleteActivity = React.useCallback(async (params, e) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    //if (validate()) {
    //alert("Validation Success");

    // /* Frequency of exercise */
    // if (!a.actFreq || a.actFreq === null || a.actFreq === undefined) {
    //   a.actFreq = "";
    // }

    // Check if the row is in database or only inside the data grid
    if (
      !params.row.currDateTime ||
      params.row.currDateTime === null ||
      params.row.currDateTime === undefined ||
      params.row.id === 0
    ) {
      //Do nothing, record will get deleted from the grid only.
      alert(
        "This record still does not exists in database. Want to remove it from the Data Grid?"
      );
      //alert(params.row.currDateTime);
    } else {
      try {
        alert("Are you sure you want to delete this activity?");

        //Show warning snackbar with alert
        // handleClick(
        //   "warning",
        //   "Warning",
        //   "Activity once inserted cannot be deleted or modified again. Are you sure you want to Continue?"
        // );

        // eslint-disable-next-line
        const response = await axiosDeleteAssignedActivity
          .put(`/updAssignedActivity/${params.row.id}`, {
            id: 0,
            userid: params.row.userid,
            acttypeid: params.row.acttypeid,
            currDateTime: params.row.currDateTime,
            activitiesType: params.row.activitiesType,
            actFreq: params.row.actFreq,
            peakHR: params.row.peakHR,
            peakBorgRPE: params.row.peakBorgRPE,
            minDuration: params.row.minDuration,
            actStatus: params.row.actStatus,
            comments: params.row.comments,
            actWorkStatus: "Deleted",
            workStatusChangedDT: isoDate
          })
          .then(
            (response) => {
              console.log(response);
              //Show success snackbar with alert
              handleClick(
                "success",
                "Success",
                "Activity deleted successfully."
              );
              //alert("Activity inserted Successfully!");
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in deleting the Activity....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              //alert("Activity Deletion Failure!");
              //Show error snackbar with alert
              handleClick(
                "error",
                "Error",
                "Assigned Activity Deletion Failure."
              );
            }
          );
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
        //alert("Deletion failure! " + err);
        //Show error snackbar with alert
        handleClick(
          "error",
          "Error",
          "Activity deletion failure."
        );
      }
    }
    setTimeout(() => {
      setSelectedActivities((prevRows) =>
        prevRows.filter((row) => row.id !== params.id)
      );
      setActivitiesPlan((prevRows) =>
        prevRows.filter((row) => row.id !== params.id)
      );
    });
    // eslint-disable-next-line
  }, []);

  //To update the record
  const updateActivity = React.useCallback(async (params, e) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    // eslint-disable-next-line
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    //if (validate()) {
    //alert("Validation Success");

    // /* Frequency of exercise */
    // if (!a.actFreq || a.actFreq === null || a.actFreq === undefined) {
    //   a.actFreq = "";
    // }

    //console.log("updRequiredFlag:  " + updRequiredFlag);

    // Check if the row is in database or only inside the data grid
    if (
      !params.row.currDateTime ||
      params.row.currDateTime === null ||
      params.row.currDateTime === undefined ||
      params.row.id === 0
    ) {
      //Insert the new record in database - No updation required here because it is a new first time entry
      alert(
        "This Activity still is not the part of the plan. Want to insert this Activity?"
      );

      //Check if row updation is really required
      if (updRequiredFlag) {
        //alert("Required");
        //Insert the new record - No updation is required here
        handleUpdNewGridRowInsert(e, params);
        //Reset the flag
        updRequiredFlag = false;
      } else {
        //alert("Not Required");
        alert("Please, fill up the fields!");
      }
    } else {
      // eslint-disable-next-line
      let tempRowId = 0;
      //Check if row updation is really required
      if (updRequiredFlag) {
        //alert("Required");
        try {
          alert("Are you sure you want to update this activity?");
          //Show warning snackbar with alert
          // handleClick(
          //   "warning",
          //   "Warning",
          //   "Activity once inserted cannot be deleted or modified again. Are you sure you want to Continue?"
          // );

          /* Frequency of exercise */
          let actFreq = params.row.actFreq;
          if (
            !params.row.actFreq ||
            params.row.actFreq === null ||
            params.row.actFreq === undefined
          ) {
            // eslint-disable-next-line
            actFreq = "";
          }

          /* Peak Heart Rate to achieve */
          let peakHR = params.row.peakHR;
          if (
            !params.row.peakHR ||
            params.row.peakHR === null ||
            params.row.peakHR === undefined
          ) {
            // eslint-disable-next-line
            peakHR = "";
          }

          /* Peak RPE/BORG to achieve */
          let peakBorgRPE = params.row.peakBorgRPE;
          if (
            !params.row.peakBorgRPE ||
            params.row.peakBorgRPE === null ||
            params.row.peakBorgRPE === undefined
          ) {
            // eslint-disable-next-line
            peakBorgRPE = "";
          }

          /* Duration of minutes to perform the exercise */
          let minDuration = params.row.minDuration;
          if (
            !params.row.minDuration ||
            params.row.minDuration === null ||
            params.row.minDuration === undefined
          ) {
            // eslint-disable-next-line
            minDuration = "";
          }

          /* Any other comments or instructions */
          let comments = params.row.comments;
          if (
            !params.row.comments ||
            params.row.comments === null ||
            params.row.comments === undefined
          ) {
            // eslint-disable-next-line
            comments = "";
          }

          //To insert the new clone of the old record before updation
          // const responseInsUpdClone = await axiosUpdateAssignedActivity
          //   .put(`/updAssignedActivity/${0}`, {
          //     id: 0,
          //     userid: params.row.userid,
          //     acttypeid: params.row.acttypeid,
          //     currDateTime: isoDate,
          //     activitiesType: params.row.activitiesType,
          //     actFreq: actFreq,
          //     peakHR: peakHR,
          //     peakBorgRPE: peakBorgRPE,
          //     minDuration: minDuration,
          //     actStatus: params.row.actStatus,
          //     comments: comments,
          //     actWorkStatus: "Active",
          //     workStatusChangedDT: isoDate
          //   })
          //   .then(
          //     (response) => {
          //       console.log(response); //you will get the new row id from here - update it with 0 old id (params.row.id = new id)
          //       //Show success snackbar with alert
          //       handleClick(
          //         "success",
          //         "Success",
          //         "Activity clone created successfully."
          //       );
          //       /* Assign the new id to temp row id */
          //       tempRowId = response.data.id;
          //       //alert("Activity clone inserted Successfully!");
          //     },
          //     (error) => {
          //       if (error.response) {
          //         console.log(error.response.data);
          //         const scode = error.response.status;
          //         if (scode === 401 || scode === 403 || scode === 404) {
          //           localStorage.setItem(
          //             "message",
          //             "Error in creating the clone of the Activity....."
          //           );
          //         }
          //         console.log(error.response.status);
          //         console.log(error.response.headers);
          //       }
          //       console.log("Error is below: ");
          //       console.log(error);
          //       console.log(error.data.message);
          //       //alert("Activity Clone Insertion Failure!");
          //       //Show error snackbar with alert
          //       handleClick(
          //         "error",
          //         "Error",
          //         "Assigned Activity Clone Insertion Failure."
          //       );
          //     }
          //   ); 
          // To update the old record with valid id (non zero)
          //   const responseUpd = await axiosUpdateAssignedActivity
          //     .put(`/updAssignedActivity/${params.row.id}`, {
          //       id: 0,
          //       userid: params.row.userid,
          //       acttypeid: params.row.acttypeid,
          //       currDateTime: params.row.currDateTime,
          //       activitiesType: params.row.activitiesType,
          //       actFreq: params.row.actFreq,
          //       peakHR: params.row.peakHR,
          //       peakBorgRPE: params.row.peakBorgRPE,
          //       minDuration: params.row.minDuration,
          //       actStatus: params.row.actStatus,
          //       comments: params.row.comments,
          //       actWorkStatus: "Updated",
          //       workStatusChangedDT: isoDate
          //     })
          //     .then(
          //       (response) => {
          //         console.log(response);
          //         //Show success snackbar with alert
          //         handleClick(
          //           "success",
          //           "Success",
          //           "Activity updated successfully."
          //         );
          //         /* Assign or Replace the new row id with the current row id in the grid */
          //         params.row.id = tempRowId;
          //         //alert("New id generated is:  " + params.row.id);
          //         //alert("Activity updated Successfully!");
          //       },
          //       (error) => {
          //         if (error.response) {
          //           console.log(error.response.data);
          //           const scode = error.response.status;
          //           if (scode === 401 || scode === 403 || scode === 404) {
          //             localStorage.setItem(
          //               "message",
          //               "Error in updating the Activity....."
          //             );
          //           }
          //           console.log(error.response.status);
          //           console.log(error.response.headers);
          //         }
          //         console.log("Error is below: ");
          //         console.log(error);
          //         console.log(error.data.message);
          //         //alert("Activity Updation/Insertion Failure!");
          //         //Show error snackbar with alert
          //         handleClick(
          //           "error",
          //           "Error",
          //           "Assigned Activity Updation Failure."
          //         );
          //       } 
          //     ); 
        } catch (err) {
          console.log(err.response);
          console.log(err.message);
          //alert("Updation/Insertion failure! " + err);
          //Show error snackbar with alert
          handleClick(
            "error",
            "Error",
            "Activity Updation failure."
          );
        }
        //Reset the flag
        updRequiredFlag = false;
      } else {
        //alert("Not Required");
        alert("Please, change some values before updation!");
      }
    }
    // setTimeout(() => {
    //   setSelectedActivities((prevRows) =>
    //     prevRows.filter((row) => row.id !== params.id)
    //   );
    //   setActivitiesPlan((prevRows) =>
    //     prevRows.filter((row) => row.id !== params.id)
    //   );
    // });
    // eslint-disable-next-line
  }, []);

  //Dummy coloumns data for MUI - MIT Data Grid
  const columnsCHS = [
    // {
    //   field: "id",
    //   headerName: "S.No.",
    //   width: 5
    // },
    // {
    //   field: "dtTime",
    //   headerName: "Date/Time"
    //   //width: 50
    // },
    {
      //field: "actType",
      field: "activitiesType",
      headerName: "Activity"
      //width: 50
    },
    {
      field: "actFreq",
      headerName: "Frequency",
      //width: 50,
      editable: true
    },
    {
      field: "peakHR",
      headerName: "PeakHR",
      //type: "number",
      //width: 20,
      editable: true
    },
    {
      field: "peakBorgRPE",
      headerName: "Peak RPE/BORG",
      //type: "number",
      //width: 50,
      editable: true
    },
    {
      field: "minDuration",
      //field: "year",
      headerName: "Duration(mins)",
      //type: "number",
      //width: 50,
      editable: true
    },
    // {
    //   field: "actStatus",
    //   headerName: "Status",
    //   valueGetter: () => {
    //     return "Pending";
    //   }
    //   //width: 55
    // },

    {
      field: "currDateTime",
      headerName: "Date/Time",
      valueGetter: () => {
        return new Date();
      }

      //width: 55
    },
    {
      field: "comments",
      headerName: "Others",
      //description: "This column has a value getter and is not sortable.",
      sortable: false,
      editable: true
      //width: 160,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ""} ${params.row.lastName || ""}`
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      //width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={(event) => {
            updateActivity(params, event);
          }}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          //onClick={deleteActivity(params.id)}
          onClick={(event) => {
            deleteActivity(params, event);
          }}
        />
      ]
    }
  ];

  //https://stackoverflow.com/questions/66726455/material-ui-get-all-rows-from-datagrid
  // function useApiRef() {
  //   const apiRef = useRef(null);
  //   const _columns = useMemo(
  //     () =>
  //       columns.concat({
  //         field: "__HIDDEN__",
  //         width: 0,
  //         renderCell: (params) => {
  //           apiRef.current = params.api;
  //           return null;
  //         }
  //       }),
  //     [columns]
  //   );

  //   return { apiRef, columns: _columns };
  // }

  // const { apiRef, columnsCHS } = useApiRef();
  // const handleClickButton = (e) => {
  //   console.log(apiRef.current.getRowModels());
  //   handleInsertActivitiesPlan(e);
  // };

  return (
    <>
      <Snackbar
        open={open}
        //message="Material UI snackbar"
        TransitionComponent={(props) => <Slide {...props} direction="left" />}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      //action={action}
      >
        <Alert
          severity={severity}
          variant="filled"
          //icon={false}
          onClose={handleClose}
        >
          <AlertTitle>{alerttitle}</AlertTitle>
          {alertmessage}
        </Alert>
      </Snackbar>
      {tempAssignActFlag ? (
        <ViewAllUsersCRSPL />
      ) : (
        <div>
          <PageHeader
            title="Assign Activities"
            subTitle="Please assign the patient's activities/exercise plan here"
            icon={<AssignmentIndOutlinedIcon fontSize="large" />}
          />
          {/* For auto complete checkbox select dropdown */}
          <Toolbar>
            <Autocomplete
              multiple
              limitTags={5}
              id="autocompcheck"
              //options={activitytype}
              options={activitytype.sort(
                (a, b) =>
                  -b.activitiesType[0]
                    .toUpperCase()
                    .localeCompare(a.activitiesType[0].toUpperCase())
              )}
              //groupBy={(option) => option.activitiesType[0].toUpperCase()}
              //defaultValue={activitiesplan.activitiesType}
              //defaultValue={[activitiesplan[0].activitiesType]}
              value={activitiesplan}
              disableCloseOnSelect
              getOptionLabel={(option) => option.activitiesType}
              // getOptionSelected={(option, value) =>
              //   option.activitiesType === value.activitiesType
              // }
              //filterSelectedOptions
              disableClearable
              // onChange={(event, newValue) => {
              //   //console.log(newValue);
              //   handleOnChangeAC(event, `${newValue}`);
              // }}
              onChange={handleOnChangeAC}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.activitiesType}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assign Activities"
                  placeholder="more"
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </Toolbar>
          {/* <Toolbar>
            <Controls.Input
              label="Search Activities"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon //color="secondary"
                    />
                  </InputAdornment>
                )
              }}
              onChange={handleSearch}
            />
            &nbsp;&nbsp;
            <Controls.Button
              text=" Back "
              variant="outlined"
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={(event) => {
                handleShowConAllUserPage(event);
              }}
              //setOpenPopup(true);
              //setRecordForEdit(null);
            />
          </Toolbar> */}
          {/* MUI-MIT Data Grid */}

          <Toolbar>
            <Box sx={{ height: 400, width: "100%" }} className={classes.tHead}>
              <DataGrid
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay
                }}
                rows={selectedActivities}
                //rows={rowsCHS2}

                columns={columnsCHS}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5
                    }
                  },
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      currDateTime: false
                    }
                  }
                }}
                pageSizeOptions={[5]}
                //checkboxSelection
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                disableRowSelectionOnClick
                style={{ width: "100%" }}
              />
            </Box>
          </Toolbar>

          <Toolbar>
            <Controls.Button
              text=" Back "
              variant="outlined"
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={(event) => {
                handleShowConAllUserPage(event);
              }}

            //setOpenPopup(true);
            //setRecordForEdit(null);
            />{" "}
            &nbsp;&nbsp;
            <Controls.Button
              text=" Assign "
              variant="outlined"
              startIcon={<AssignmentTurnedInOutlinedIcon />}
              onClick={(event) => {
                handleInsertActivitiesPlan(event);
                //handleClickButton(event);
              }}
            />{" "}
            &nbsp;&nbsp;
            {/* <TblPagination /> */}
          </Toolbar>
          {/* <TblContainer>
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting() === ""
                ? alert("No Records found!")
                : ""}

              {recordsAfterPagingAndSorting().map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.dtTime}</TableCell>
                  <TableCell>{item.activityName}</TableCell>
                  <TableCell editable>{item.preHR}</TableCell>
                  <TableCell editable>{item.postHR}</TableCell>
                  <TableCell editable>{item.results}</TableCell>
                  <TableCell editable>{item.rpeBorg}</TableCell>
                  <TableCell>{item.symptoms}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>

          <Toolbar>
            <Controls.Button
              text=" Back "
              variant="outlined"
              startIcon={<ArrowBackOutlinedIcon />}
              onClick={(event) => {
                handleShowConAllUserPage(event);
              }}

              //setOpenPopup(true);
              //setRecordForEdit(null);
            />{" "}
            &nbsp;&nbsp;
            <Controls.Button
              text=" Assign "
              variant="outlined"
              startIcon={<AssignmentTurnedInOutlinedIcon />}
              onClick={(event) => {
                //handleShowConAllUserPage(event);
              }}
            />{" "}
            &nbsp;&nbsp;
            <TblPagination />
          </Toolbar> */}
        </div>
      )}
    </>
  );
}
