import React, { useState, Suspense } from "react";
import { Grid, Slide } from "@mui/material";
import axios from "axios";
import { useForm, Form } from "../../components/useForm";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
//import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
//import IconButton from "@mui/material/IconButton";
// CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
//import * as employeeService from "../../services/employeeService";

const initialFValues = {
  usid: 0,
  dateTime: new Date().toLocaleString(),
  //dateTime: new Date(),
  anx1: 0,
  anx2: 0,
  anx3: 0,
  anx4: 0,
  anx5: 0,
  anx6: 0,
  anx7: 0,
  dep1: 0,
  dep2: 0,
  dep3: 0,
  dep4: 0,
  dep5: 0,
  dep6: 0,
  dep7: 0
};

const BASE_URL = `${ConfigData.protoipaport}/assessmentforms`;

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

export default function HADSForm() {
  const { t } = useTranslation(["translation", "hads"]);

  const anxItems1 = [
    { id: 0, title: t("options.option1", { ns: "hads" }) },
    { id: 1, title: t("options.option2", { ns: "hads" }) },
    { id: 2, title: t("options.option3", { ns: "hads" }) },
    { id: 3, title: t("options.option4", { ns: "hads" }) }
  ];
  const anxItems2 = [
    { id: 0, title: t("options.option1", { ns: "hads" }) },
    { id: 1, title: t("options.option5", { ns: "hads" }) },
    { id: 2, title: t("options.option6", { ns: "hads" }) },
    { id: 3, title: t("options.option7", { ns: "hads" }) }
  ];
  const anxItems3 = [
    { id: 0, title: t("options.option8", { ns: "hads" }) },
    { id: 1, title: t("options.option9", { ns: "hads" }) },
    { id: 2, title: t("options.option3", { ns: "hads" }) },
    { id: 3, title: t("options.option10", { ns: "hads" }) }
  ];
  const anxItems4 = [
    { id: 0, title: t("options.option11", { ns: "hads" }) },
    { id: 1, title: t("options.option12", { ns: "hads" }) },
    { id: 2, title: t("options.option13", { ns: "hads" }) },
    { id: 3, title: t("options.option1", { ns: "hads" }) }
  ];
  const anxItems5 = [
    { id: 0, title: t("options.option1", { ns: "hads" }) },
    { id: 1, title: t("options.option14", { ns: "hads" }) },
    { id: 2, title: t("options.option15", { ns: "hads" }) },
    { id: 3, title: t("options.option16", { ns: "hads" }) }
  ];
  const anxItems6 = [
    { id: 0, title: t("options.option1", { ns: "hads" }) },
    { id: 1, title: t("options.option17", { ns: "hads" }) },
    { id: 2, title: t("options.option18", { ns: "hads" }) },
    { id: 3, title: t("options.option19", { ns: "hads" }) }
  ];
  const anxItems7 = [
    { id: 0, title: t("options.option1", { ns: "hads" }) },
    { id: 1, title: t("options.option20", { ns: "hads" }) },
    { id: 2, title: t("options.option15", { ns: "hads" }) },
    { id: 3, title: t("options.option21", { ns: "hads" }) }
  ];

  const depItems1 = [
    { id: 0, title: t("options.option22", { ns: "hads" }) },
    { id: 1, title: t("options.option23", { ns: "hads" }) },
    { id: 2, title: t("options.option24", { ns: "hads" }) },
    { id: 3, title: t("options.option25", { ns: "hads" }) }
  ];

  const depItems2 = [
    { id: 0, title: t("options.option26", { ns: "hads" }) },
    { id: 1, title: t("options.option27", { ns: "hads" }) },
    { id: 2, title: t("options.option28", { ns: "hads" }) },
    { id: 3, title: t("options.option1", { ns: "hads" }) }
  ];

  const depItems3 = [
    { id: 0, title: t("options.option4", { ns: "hads" }) },
    { id: 1, title: t("options.option29", { ns: "hads" }) },
    { id: 2, title: t("options.option13", { ns: "hads" }) },
    { id: 3, title: t("options.option1", { ns: "hads" }) }
  ];

  const depItems4 = [
    { id: 0, title: t("options.option1", { ns: "hads" }) },
    { id: 1, title: t("options.option29", { ns: "hads" }) },
    { id: 2, title: t("options.option16", { ns: "hads" }) },
    { id: 3, title: t("options.option30", { ns: "hads" }) }
  ];

  const depItems5 = [
    { id: 0, title: t("options.option31", { ns: "hads" }) },
    { id: 1, title: t("options.option32", { ns: "hads" }) },
    { id: 2, title: t("options.option33", { ns: "hads" }) },
    { id: 3, title: t("options.option11", { ns: "hads" }) }
  ];

  const depItems6 = [
    { id: 0, title: t("options.option34", { ns: "hads" }) },
    { id: 1, title: t("options.option35", { ns: "hads" }) },
    { id: 2, title: t("options.option36", { ns: "hads" }) },
    { id: 3, title: t("options.option25", { ns: "hads" }) }
  ];

  const depItems7 = [
    { id: 0, title: t("options.option37", { ns: "hads" }) },
    { id: 1, title: t("options.option29", { ns: "hads" }) },
    { id: 2, title: t("options.option13", { ns: "hads" }) },
    { id: 3, title: t("options.option38", { ns: "hads" }) }
  ];

  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState(false);
  const [alerttitle, setAlertTitle] = useState(false);
  const [alertmessage, setAlertMesssage] = useState(false);

  const handleClick = (sever, atitle, amessage) => {
    setSeverity(sever);
    setAlertTitle(atitle);
    setAlertMesssage(amessage);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const action = (
  //   <React.Fragment>
  //     <Button color="secondary" size="small" onClick={handleClose}>
  //       UNDO
  //     </Button>
  //     <IconButton
  //       size="small"
  //       aria-label="close"
  //       color="inherit"
  //       onClick={handleClose}
  //     >
  //       <CloseIcon fontSize="small" />
  //     </IconButton>
  //   </React.Fragment>
  // );

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("activityName" in fieldValues) {
      // temp.activityName =
      //   fieldValues.activityName === "None" ? "This field is required." : "" ;
      temp.activityName = fieldValues.activityName
        ? ""
        : "Value in this field is required.";
    }
    if ("preHR" in fieldValues)
      temp.preHR = fieldValues.preHR ? "" : "Value in this field is required.";
    if ("postHR" in fieldValues)
      temp.postHR = fieldValues.postHR ? "" : "Value in this field is required.";
    if ("results" in fieldValues)
      temp.results = fieldValues.results ? "" : "Value in this field is required.";
    if ("rpeBorg" in fieldValues)
      temp.rpeBorg = fieldValues.rpeBorg ? "" : "Value in this field is required.";
    if ("symptoms" in fieldValues)
      temp.symptoms = fieldValues.symptoms ? "" : "Value in this field is required.";
    setErrors({
      ...temp
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const {
    values,
    // eslint-disable-next-line
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFValues, true, validate);
  //const [errors, setErrors] = useState({});

  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current user name
  //const currusername = localStorage.getItem("username");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Creating axios object for form data insertion
  const axiosInsertFormData = axios.create({
    baseURL: BASE_URL,
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  const handleSubmit = async (e, t) => {
    e.preventDefault();
    var now = new Date(); // Fri Feb 20 2015 19:29:31 GMT+0530 (India Standard Time)
    var isoDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();

    if (validate()) {
      //alert("Validation Success");

      try {
        alert(t("alerts.aConfirm"));

        //Show warning snackbar with alert
        // handleClick(
        //   "warning",
        //   "Warning",
        //   "Data once inserted cannot be deleted or modified again. Are you sure you want to Continue? — <strong>check it out!</strong>"
        // );

        /* For testing/debugging purpose */
        // alert("user id is: " + userid);
        // alert("dateTime is: " + values["dateTime"]);
        // alert("activityName is: " + values["activityName"]);
        // alert("preHR is: " + values["preHR"]);
        // alert("postHR is: " + values["postHR"]);
        // alert("results is: " + values["results"]);
        // alert("rpeBorg is: " + values["rpeBorg"]);
        // alert("symptoms is: " + values["symptoms"]);

        // eslint-disable-next-line
        const response = await axiosInsertFormData
          .post("/inserthads", {
            userid: userid,
            //dateTime: values["dateTime"],
            dateTime: isoDate,
            anx1: values["anx1"],
            anx2: values["anx2"],
            anx3: values["anx3"],
            anx4: values["anx4"],
            anx5: values["anx5"],
            anx6: values["anx6"],
            anx7: values["anx7"],
            dep1: values["dep1"],
            dep2: values["dep2"],
            dep3: values["dep3"],
            dep4: values["dep4"],
            dep5: values["dep5"],
            dep6: values["dep6"],
            dep7: values["dep7"]
          })
          .then(
            (response) => {
              console.log(response);
              //Show success snackbar with alert
              handleClick(
                "success",
                t("snackHead.sSuccess"),
                t("alerts.aSuccess")
              );
              //alert("Data inserted Successfully!");
            },
            (error) => {
              if (error.response) {
                console.log(error.response.data);
                const scode = error.response.status;
                if (scode === 401 || scode === 403 || scode === 404) {
                  localStorage.setItem(
                    "message",
                    "Error in inserting the Data....."
                  );
                }
                console.log(error.response.status);
                console.log(error.response.headers);
              }
              console.log("Error is below: ");
              console.log(error);
              console.log(error.data.message);
              //alert("Activity Insertion Failure!");
              //Show error snackbar with alert
              handleClick(
                "error",
                t("snackHead.sError"),
                t("alerts.aInsertion")
              );
            }
          );
      } catch (err) {
        console.log(err.response);
        console.log(err.message);
        //alert("Insertion failure! " + err);
        //Show error snackbar with alert
        handleClick("error", t("snackHead.sError"), t("alerts.aExcept"));
      }

      resetForm();
    } else {
      //alert("Form validation fails!");
      //Show error snackbar with alert
      handleClick("error", t("snackHead.sError"), t("alerts.aValidation"));
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Form>
        <Grid container>
          {/* Snackbar with alert */}
          <div>
            <Snackbar
              open={open}
              //message="Material UI snackbar"
              TransitionComponent={(props) => (
                <Slide {...props} direction="left" />
              )}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              //action={action}
            >
              <Alert
                severity={severity}
                variant="filled"
                //icon={false}
                onClose={handleClose}
              >
                <AlertTitle>{alerttitle}</AlertTitle>
                {alertmessage}
              </Alert>
            </Snackbar>
          </div>

          {/* <Grid item xs={6}>
          <p align="left">
            <b>Name: {currusername} </b>
          </p>
        </Grid>
        <Grid item xs={6} align="right">
          <Controls.Input
            name="dateTime"
            label="Date/Time of Form Filling"
            value={values.dateTime}
            onChange={handleInputChange}
            InputProps={{
              readOnly: true
            }}
          />
        </Grid> */}

          <Grid item xs={12} align="left">
            <p align="left">
              <b>{t("titleQ", { ns: "hads" })}</b>
            </p>
            <p align="left">
              <b>{t("questions.hAnx", { ns: "hads" })}</b>
            </p>
            <Controls.RadioGroup
              name="anx1"
              label={t("questions.qA1", { ns: "hads" })}
              value={values.anx1}
              onChange={handleInputChange}
              items={anxItems1}
            />

            <Controls.RadioGroup
              name="anx2"
              label={t("questions.qA2", { ns: "hads" })}
              value={values.anx2}
              onChange={handleInputChange}
              items={anxItems2}
            />

            <Controls.RadioGroup
              name="anx3"
              label={t("questions.qA3", { ns: "hads" })}
              value={values.anx3}
              onChange={handleInputChange}
              items={anxItems3}
            />

            <Controls.RadioGroup
              name="anx4"
              label={t("questions.qA4", { ns: "hads" })}
              value={values.anx4}
              onChange={handleInputChange}
              items={anxItems4}
            />

            <Controls.RadioGroup
              name="anx5"
              label={t("questions.qA5", { ns: "hads" })}
              value={values.anx5}
              onChange={handleInputChange}
              items={anxItems5}
            />

            <Controls.RadioGroup
              name="anx6"
              label={t("questions.qA6", { ns: "hads" })}
              value={values.anx6}
              onChange={handleInputChange}
              items={anxItems6}
            />

            <Controls.RadioGroup
              name="anx7"
              label={t("questions.qA7", { ns: "hads" })}
              value={values.anx7}
              onChange={handleInputChange}
              items={anxItems7}
            />
            <p align="left">
              <b>{t("questions.hDep", { ns: "hads" })}</b>
            </p>

            <Controls.RadioGroup
              name="dep1"
              label={t("questions.qD8", { ns: "hads" })}
              value={values.dep1}
              onChange={handleInputChange}
              items={depItems1}
            />

            <Controls.RadioGroup
              name="dep2"
              label={t("questions.qD9", { ns: "hads" })}
              value={values.dep2}
              onChange={handleInputChange}
              items={depItems2}
            />

            <Controls.RadioGroup
              name="dep3"
              label={t("questions.qD10", { ns: "hads" })}
              value={values.dep3}
              onChange={handleInputChange}
              items={depItems3}
            />

            <Controls.RadioGroup
              name="dep4"
              label={t("questions.qD11", { ns: "hads" })}
              value={values.dep4}
              onChange={handleInputChange}
              items={depItems4}
            />

            <Controls.RadioGroup
              name="dep5"
              label={t("questions.qD12", { ns: "hads" })}
              value={values.dep5}
              onChange={handleInputChange}
              items={depItems5}
            />

            <Controls.RadioGroup
              name="dep6"
              label={t("questions.qD13", { ns: "hads" })}
              value={values.dep6}
              onChange={handleInputChange}
              items={depItems6}
            />

            <Controls.RadioGroup
              name="dep7"
              label={t("questions.qD14", { ns: "hads" })}
              value={values.dep7}
              onChange={handleInputChange}
              items={depItems7}
            />
            <div border="2" align="center">
              &nbsp;&nbsp;
              <Controls.Button
                type="submit"
                onClick={(event) => {
                  handleSubmit(event, t);
                }}
                text={t("footer.send", { ns: "hads" })}
              />
              &nbsp;&nbsp;&nbsp;
              <Controls.Button
                text={t("footer.reset", { ns: "hads" })}
                onClick={resetForm}
                color="inherit"
              />
            </div>
          </Grid>
        </Grid>
      </Form>
    </Suspense>
  );
}
