import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//import CREFile from "./downloads/CRE.docx";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
//import classNames from "classnames";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

function showSideBar() {
  const sidebar = document.querySelector('.sidebar');
  sidebar.style.display = 'flex';
  sidebar.style.visibility = 'visible';
}

function hideSideBar() {
  const sidebar = document.querySelector('.sidebar');
  sidebar.style.display = 'none';
  sidebar.style.visibility = 'hidden';
}

// eslint-disable-next-line
const languages = [
  {
    code: "en",
    name: "English",
    country_code: "GB"
  },
  {
    code: "de",
    name: "Hindi",
    country_code: "IN"
  }
];

const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
  </svg>
);

const Navbar = () => {
  const navigate = useNavigate();

  const auth = localStorage.getItem("token");

  // eslint-disable-next-line
  const currentLanguageCode = cookies.get("i18next") || "en";
  //const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  /* For language translations */
  const { t, i18n } = useTranslation(["translation", "navbar"]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    // {localStorage.removeItem("message")};{<LoginNavBar />} {<Login />}
    localStorage.clear();
    alert("Are you sure you want to log out?");
    navigate("/Home");
  };

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     navigate("/Navbar");
  //   }
  // }, [navigate]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
              <Link to="/Home">{t("navbar.navlogo", { ns: "navbar" })}</Link>
            </h1>
          </div>
          <nav id="navbar" className="navbar" >
            {auth ? (
              <>
                { /* ========== Mobile Sidebar =============  */}
                <ul class="sidebar">
                  <li align="center">
                    <u><b>{t("navbar.navWel", { ns: "navbar" })} {localStorage.getItem("username")}</b></u>
                  </li>
                  <li>
                    <Link className="nav-link scrollto active" to="/Dashboard">
                      {t("navbar.navDash", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/AppFeatures">
                      {t("navbar.navAppFeat", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/WhatWeDo">
                      {t("navbar.navWWDo", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/* <li>
                          <Link className="nav-link scrollto" to="/Gallery">
                            Gallery
                          </Link>
                        </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/FAQ">
                      {t("navbar.navFAQ", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/* <li className="dropdown">
                              <Link to="/Home">
                                <span>{t("navbar.navDown", { ns: "navbar" })}</span> <i className="bi bi-chevron-down"></i>
                              </Link>
                              <ul>
                                <li>
                                  <a href={CREFile}>{t("navbar.navDownloads.cre", { ns: "navbar" })}</a>
                                </li>
                                <li className="dropdown">
                                  <Link to="/Home">
                                    <span>{t("navbar.navDownloads.ddd", { ns: "navbar" })}</span>{" "}
                                    <i className="bi bi-chevron-right"></i>
                                  </Link>
                                  <ul>
                                    <li>
                                      <Link to="/Home">{t("navbar.navDownloads.ddd1", { ns: "navbar" })}</Link>
                                    </li>
                                    <li>
                                      <Link to="/Home">{t("navbar.navDownloads.ddd2", { ns: "navbar" })}</Link>
                                    </li>
                                    <li>
                                      <Link to="/Home">{t("navbar.navDownloads.ddd3", { ns: "navbar" })}</Link>
                                    </li>
                                    <li>
                                      <Link to="/Home">{t("navbar.navDownloads.ddd4", { ns: "navbar" })}</Link>
                                    </li>
                                    <li>
                                      <Link to="/Home">{t("navbar.navDownloads.ddd5", { ns: "navbar" })}</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/Home">{t("navbar.navDownloads.dd2", { ns: "navbar" })}</Link>
                                </li>
                                <li>
                                  <Link to="/Home">{t("navbar.navDownloads.dd3", { ns: "navbar" })}</Link>
                                </li>
                                <li>
                                  <Link to="/Home">{t("navbar.navDownloads.dd4", { ns: "navbar" })}</Link>
                                </li>
                              </ul>
                            </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/Contact">
                      {t("navbar.navContUs", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto active" onClick={() => { hideSideBar() }}>
                      {t("navbar.navExit", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="getstarted scrollto"
                      onClick={logout}
                      to="/Home"
                    >
                      {t("navbar.navLogout", { ns: "navbar" })}
                    </Link>
                  </li>
                  <br />
                  <li>
                    <div className="language-select">
                      {/* <div className="d-flex justify-content-end align-items-center language-select-root"> */}
                      <div className="dropdown">
                        &nbsp;&nbsp;&nbsp;
                        <button
                          className="btn btn-link dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <GlobeIcon />
                        </button>

                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <span className="dropdown-item-text">
                              <b> {t("language")} </b>
                            </span>
                          </li>

                          <Link
                            className="list-group-item list-group-item-action"
                            onClick={() => {
                              changeLanguage("en");
                            }}
                            to="#"
                          >
                            English
                          </Link>
                          <Link
                            className="list-group-item list-group-item-action"
                            onClick={() => {
                              changeLanguage("de");
                            }}
                            to="#"
                          >
                            Hindi
                          </Link>

                          <span
                            className={`flag-icon flag-icon-IN`}
                            style={{
                              opacity: 1
                            }}
                          ></span>
                        </ul>
                      </div>
                      {/* </div> */}
                    </div>
                  </li>
                </ul>

                { /* ========== Main Navbar  =============  */}
                <ul>
                  <li>
                    <Link className="nav-link scrollto" to="/Dashboard">{t("navbar.navWel", { ns: "navbar" })} {localStorage.getItem("username")}</Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto active" to="/Dashboard">
                      {t("navbar.navDash", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/AppFeatures">
                      {t("navbar.navAppFeat", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/WhatWeDo">
                      {t("navbar.navWWDo", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/* <li>
              <Link className="nav-link scrollto" to="/Gallery">
                Gallery
              </Link>
            </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/FAQ">
                      {t("navbar.navFAQ", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/* <li className="dropdown">
                  <Link to="/Home">
                    <span>{t("navbar.navDown", { ns: "navbar" })}</span> <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <a href={CREFile}>{t("navbar.navDownloads.cre", { ns: "navbar" })}</a>
                    </li>
                    <li className="dropdown">
                      <Link to="/Home">
                        <span>{t("navbar.navDownloads.ddd", { ns: "navbar" })}</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd1", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd2", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd3", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd4", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd5", { ns: "navbar" })}</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd2", { ns: "navbar" })}</Link>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd3", { ns: "navbar" })}</Link>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd4", { ns: "navbar" })}</Link>
                    </li>
                  </ul>
                </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/Contact">
                      {t("navbar.navContUs", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="getstarted scrollto"
                      onClick={logout}
                      to="/Home"
                    >
                      {t("navbar.navLogout", { ns: "navbar" })}
                    </Link>
                  </li>
                  &nbsp;
                  <li>
                    <div className="language-select">
                      <div className="d-flex justify-content-end align-items-center language-select-root">
                        <div className="dropdown">
                          <button
                            className="btn btn-link dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <GlobeIcon />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <span className="dropdown-item-text">
                                <b> {t("language")} </b>
                              </span>
                            </li>

                            <Link
                              className="list-group-item list-group-item-action"
                              onClick={() => {
                                changeLanguage("en");
                              }}
                              to="#"
                            >
                              English
                            </Link>
                            <Link
                              className="list-group-item list-group-item-action"
                              onClick={() => {
                                changeLanguage("de");
                              }}
                              to="#"
                            >
                              Hindi
                            </Link>

                            <span
                              className={`flag-icon flag-icon-IN`}
                              style={{
                                opacity: 1
                              }}
                            ></span>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle" onClick={() => { showSideBar(); }}></i>
              </>

            ) : (

              <>
                { /* ========== Mobile Sidebar  =============  */}
                <ul class="sidebar">
                  <li>
                    <Link className="nav-link scrollto active" to="/Home">
                      {t("navbar.navHome", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/AppFeatures">
                      {t("navbar.navAppFeat", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/WhatWeDo">
                      {t("navbar.navWWDo", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/*  <li>
                     <Link className="nav-link scrollto" to="/Gallery">
                         Gallery
                      </Link>
                      </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/FAQ">
                      {t("navbar.navFAQ", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/* <li className="dropdown">
                  <Link to="/Home">
                    <span>{t("navbar.navDown", { ns: "navbar" })}</span> <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <a href={CREFile}>{t("navbar.navDownloads.cre", { ns: "navbar" })}</a>
                    </li>
                    <li className="dropdown">
                      <Link to="/Home">
                        <span>{t("navbar.navDownloads.ddd", { ns: "navbar" })}</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd1", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd2", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd3", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd4", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd5", { ns: "navbar" })}</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd2", { ns: "navbar" })}</Link>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd3", { ns: "navbar" })}</Link>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd4", { ns: "navbar" })}</Link>
                    </li>
                  </ul>
                  </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/Contact">
                      {t("navbar.navContUs", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/Signup">
                      {t("navbar.navSignup", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto active" onClick={() => { hideSideBar() }}>
                      {t("navbar.navExit", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="getstarted scrollto" to="/Login">
                      {t("navbar.navLogin", { ns: "navbar" })}
                    </Link>
                  </li>
                  <br />
                  <li>

                    <div className="language-select">
                      {/* <div className="d-flex justify-content-end align-items-center language-select-root"> */}
                      <div className="dropdown">
                        &nbsp;&nbsp;&nbsp;
                        <button
                          className="btn btn-link dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <GlobeIcon />
                        </button>

                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <span className="dropdown-item-text">
                              <b> {t("language")}</b>
                            </span>
                          </li>

                          <Link
                            className="list-group-item list-group-item-action"
                            onClick={() => {
                              changeLanguage("en");
                            }}
                            to="#"
                          >
                            English
                          </Link>
                          <Link
                            className="list-group-item list-group-item-action"
                            onClick={() => {
                              changeLanguage("de");
                            }}
                            to="#"
                          >
                            Hindi
                          </Link>
                          <span
                            className={`flag-icon flag-icon-IN`}
                            style={{
                              opacity: 1
                            }}
                          ></span>
                        </ul>
                      </div>
                      {/* </div> */}
                    </div>
                  </li>
                </ul>

                { /* ========== Main Navbar  =============  */}
                <ul>
                  <li>
                    <Link className="nav-link scrollto active" to="/Home">
                      {t("navbar.navHome", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/AppFeatures">
                      {t("navbar.navAppFeat", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/WhatWeDo">
                      {t("navbar.navWWDo", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/*  <li>
                   <Link className="nav-link scrollto" to="/Gallery">
                       Gallery
                    </Link>
                  </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/FAQ">
                      {t("navbar.navFAQ", { ns: "navbar" })}
                    </Link>
                  </li>
                  {/* <li className="dropdown">
                  <Link to="/Home">
                    <span>{t("navbar.navDown", { ns: "navbar" })}</span> <i className="bi bi-chevron-down"></i>
                  </Link>
                  <ul>
                    <li>
                      <a href={CREFile}>{t("navbar.navDownloads.cre", { ns: "navbar" })}</a>
                    </li>
                    <li className="dropdown">
                      <Link to="/Home">
                        <span>{t("navbar.navDownloads.ddd", { ns: "navbar" })}</span>{" "}
                        <i className="bi bi-chevron-right"></i>
                      </Link>
                      <ul>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd1", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd2", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd3", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd4", { ns: "navbar" })}</Link>
                        </li>
                        <li>
                          <Link to="/Home">{t("navbar.navDownloads.ddd5", { ns: "navbar" })}</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd2", { ns: "navbar" })}</Link>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd3", { ns: "navbar" })}</Link>
                    </li>
                    <li>
                      <Link to="/Home">{t("navbar.navDownloads.dd4", { ns: "navbar" })}</Link>
                    </li>
                  </ul>
                  </li> */}
                  <li>
                    <Link className="nav-link scrollto" to="/Contact">
                      {t("navbar.navContUs", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link scrollto" to="/Signup">
                      {t("navbar.navSignup", { ns: "navbar" })}
                    </Link>
                  </li>
                  <li>
                    <Link className="getstarted scrollto" to="/Login">
                      {t("navbar.navLogin", { ns: "navbar" })}
                    </Link>
                  </li>
                  &nbsp;
                  <li>
                    <div className="language-select">
                      <div className="d-flex justify-content-end align-items-center language-select-root">
                        <div className="dropdown">
                          <button
                            className="btn btn-link dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <GlobeIcon />
                          </button>

                          <ul
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <span className="dropdown-item-text">
                                <b> {t("language")}</b>
                              </span>
                            </li>

                            <Link
                              className="list-group-item list-group-item-action"
                              onClick={() => {
                                changeLanguage("en");
                              }}
                              to="#"
                            >
                              English
                            </Link>
                            <Link
                              className="list-group-item list-group-item-action"
                              onClick={() => {
                                changeLanguage("de");
                              }}
                              to="#"
                            >
                              Hindi
                            </Link>
                            <span
                              className={`flag-icon flag-icon-IN`}
                              style={{
                                opacity: 1
                              }}
                            ></span>
                          </ul>

                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle" onClick={() => { showSideBar(); }}></i>
              </>
            )}

          </nav>
        </div>
      </Suspense>
    </>
  );
};

export default Navbar;
