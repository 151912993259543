import React, { useState, useEffect } from "react";
import useTable from "../../components/useTable";
import axios from "axios";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment
} from "@mui/material";
import ConfigData from "../../components/config/Config.json";
import Controls from "../../components/controls/Controls";
import { makeStyles } from "@mui/styles";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const BASE_URL = `${ConfigData.protoipaport}/activitiesdiary`;

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3)
  },
  searchInput: {
    width: "100%"
  }
}));

const headCells = [
  { id: "dtTime", label: "Date/Time" },
  { id: "activityName", label: "Activity" },
  { id: "preHR", label: "PreHR" },
  { id: "postHR", label: "PostHR" },
  { id: "results", label: "Results", disableSorting: true },
  { id: "rpeBorg", label: "RPE/BORG" },
  { id: "symptoms", label: "Comments", disableSorting: true }
];

export default function ViewActivityForm() {
  const classes = useStyles();

  //Activities data
  const [records, setRecords] = useState([]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    }
  });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting
  } = useTable(records, headCells, filterFn);

  //Current user id
  const curruserid = localStorage.getItem("userid");

  //Current JWT Token
  const token = localStorage.getItem("token"); //jwt security

  //States for activity insertion
  // eslint-disable-next-line
  const [userid, setUserId] = useState(curruserid);

  //Creating axios object for getting all the activity types
  const axiosGetActivityRecords = axios.create({
    baseURL: BASE_URL,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}` //jwt security
    }
  });

  //Activity Records
  useEffect(() => {
    const getactivityrecords = async () => {
      const res = await axiosGetActivityRecords.get(
        `/getuseractivitiesuid/${userid}`
      );

      const getActRec = await res.data;
      setRecords(await getActRec);
    };

    getactivityrecords();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter((x) =>
            x.activityName.toLowerCase().includes(target.value)
          );
      }
    });
  };

  return (
    <>
      <Toolbar>
        <Controls.Input
          label="Search Activities"
          className={classes.searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon //color="secondary"
                />
              </InputAdornment>
            )
          }}
          onChange={handleSearch}
        />
      </Toolbar>

      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((item) => (
            <TableRow key={item.id}>
              <TableCell>
                {new Date(item.dtTime).toLocaleDateString("en-IN")}
              </TableCell>
              <TableCell>{item.activityName}</TableCell>
              <TableCell>{item.preHR}</TableCell>
              <TableCell>{item.postHR}</TableCell>
              <TableCell>{item.results}</TableCell>
              <TableCell>{item.rpeBorg}</TableCell>
              <TableCell>{item.symptoms}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <TblPagination />
    </>
  );
}
