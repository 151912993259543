import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
    <div>
        <b>Please Wait something is still loading...</b>
    </div>
);

const CRExplained = () => {
    const { t } = useTranslation(["translation", "crexplained"]);

    return (
        <Suspense fallback={<Loader />}>

            <>
                <section id="features" className="features">
                    <div className="container">
                        <div className="section-title">
                            <h2>{t("header.h2", { ns: "crexplained" })}</h2>
                            {/* <p>
                {t("para.p1", { ns: "crexplained" })}
              </p> */}
                        </div>

                        <div className="row no-gutters">
                            <div className="col-xl-7 d-flex align-items-stretch order-2 order-lg-1">
                                <div className="content d-flex flex-column justify-content-center">
                                    <div className="row">
                                        <div className="col-md-12 icon-box" data-aos="fade-up">
                                            <i className="bx bx-receipt"></i>
                                            <h4>{t("header.h4a", { ns: "crexplained" })}</h4>
                                            <p>
                                                {t("para.p1", { ns: "crexplained" })}
                                            </p>
                                            <p> {t("para.p2", { ns: "crexplained" })}</p>
                                            <p> {t("para.p3", { ns: "crexplained" })}</p>
                                            <p> {t("para.p4", { ns: "crexplained" })}</p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                        >
                                            <i className="bx bx-shield"></i>
                                            <h4>{t("header.h4b", { ns: "crexplained" })}
                                            </h4>
                                            <h6> &nbsp;&nbsp;&nbsp;
                                                {t("header.h6a", { ns: "crexplained" })}
                                            </h6>

                                            <p> {t("para.p5", { ns: "crexplained" })} </p>
                                            <p> {t("para.p6", { ns: "crexplained" })} </p>
                                            <p> {t("para.p7", { ns: "crexplained" })} </p>
                                            <p> {t("para.p8", { ns: "crexplained" })} </p>
                                            <p> {t("para.p9", { ns: "crexplained" })} </p>
                                        </div>

                                        <div
                                            className="col-md-12 icon-box"
                                            data-aos="fade-up"
                                            data-aos-delay="200"
                                        >
                                            <i className="bx bx-atom"></i>
                                            <h4>
                                                {t("header.h4c", { ns: "crexplained" })}
                                            </h4>
                                            <p>
                                                {t("para.p10", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p11", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p12", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p13", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p14", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p15", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p16", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p17", { ns: "crexplained" })}
                                            </p>
                                            <p>
                                                {t("para.p18", { ns: "crexplained" })}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="image col-xl-5 d-flex align-items-stretch justify-content-center order-1 order-lg-2"
                                data-aos="fade-left"
                                data-aos-delay="100"
                            >
                                <img src="assets/img/features.svg" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </Suspense>

    );
};

export default CRExplained;
