import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const Testimonials = () => {
  const { t } = useTranslation(["translation", "testimonials"]);

  return (
    <Suspense fallback={<Loader />}>
      <>
        <section id="testimonials" className="testimonials section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{t("header.h2", { ns: "testimonials" })}</h2>
              {/* <p>
                {t("header.p1", { ns: "testimonials" })}
              </p> */}
            </div>

            <div
              className="testimonials-slider swiper"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div
              //className="swiper-wrapper"
              >
                {/* Dr. Sudhir Rathore */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/chs/DrSR_UPD.png"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>{t("testSR.tNameQ", { ns: "testimonials" })}</h3>
                    <h4>{t("testSR.tDesg", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblEmail", { ns: "testimonials" })}&nbsp;{t("testSR.tEmail", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblLinkedin", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testSR.tLinkedin", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testSR.tLinkedin", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testSR.tLinkedin", { ns: "testimonials" })}
                        </a>
                      </h7>
                    </h4>
                    <h4>{t("header.lblTwitter", { ns: "testimonials" })}&nbsp;
                      <h7>

                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testSR.tTwitter", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testSR.tTwitter", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testSR.tTwitter", { ns: "testimonials" })}
                        </a>
                      </h7>
                    </h4>

                    {/* <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Dr. Sudhir has over three decades of rich experience in clinical cardiology and clinical research in India, Japan and UK. He is a visiting faculty to multiple cardiac teaching hospitals in India and working as Interventional Cardiologist at NHS Foundation, Surrey, UK.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p> */}
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {t("testSR.tText", { ns: "testimonials" })}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>

                {/* Jeet Kumar */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/chs/JK.bmp"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>{t("testJK.tNameQ", { ns: "testimonials" })}</h3>
                    <h4>{t("testJK.tDesg", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblEmail", { ns: "testimonials" })}&nbsp;{t("testJK.tEmail", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblLinkedin", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testJK.tLinkedin", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testJK.tLinkedin", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testJK.tLinkedin", { ns: "testimonials" })}
                        </a>
                      </h7></h4>
                    {/* <h4>{t("header.lblTwitter", { ns: "testimonials" })}&nbsp;
                      <h7>

                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href="https://www.twitter.com/JeetK/"
                          onClick={() =>
                            window.open(
                              "https://www.twitter.com/JeetK/",
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testJK.tTwitter", { ns: "testimonials" })}
                        </a>
                      </h7>
                    </h4> */}

                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {t("testJK.tText", { ns: "testimonials" })}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>

                {/* Deepak Rathore  */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/chs/DR.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>{t("testDR.tNameQ", { ns: "testimonials" })}</h3>
                    <h4>{t("testDR.tDesg", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblEmail", { ns: "testimonials" })}&nbsp;{t("testDR.tEmail", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblLinkedin", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testDR.tLinkedin", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testDR.tLinkedin", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testDR.tLinkedin", { ns: "testimonials" })}
                        </a>
                      </h7></h4>
                      <h4>{t("header.lblTwitter", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testDR.tTwitter", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testDR.tTwitter", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testDR.tTwitter", { ns: "testimonials" })}
                        </a>
                      </h7>
                    </h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {t("testDR.tText", { ns: "testimonials" })}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>

                {/* Abhay Kumar Rana  */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/chs/ASR.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>{t("testAKR.tNameQ", { ns: "testimonials" })}</h3>
                    <h4>{t("testAKR.tDesg", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblEmail", { ns: "testimonials" })}&nbsp;{t("testAKR.tEmail", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblLinkedin", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testAKR.tLinkedin", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testAKR.tLinkedin", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testAKR.tLinkedin", { ns: "testimonials" })}
                        </a>
                      </h7></h4>
                    {/* <h4>{t("header.lblTwitter", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testAKR.tTwitter", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testAKR.tTwitter", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testAKR.tTwitter", { ns: "testimonials" })}
                        </a>
                      </h7>
                    </h4> */}
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {t("testAKR.tText", { ns: "testimonials" })}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>

                {/* Anupam Kumar Chauhan  */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/chs/AC.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    {/* <h4>Sr. Developer &amp; Consultant</h4> */}
                    <h3>{t("testAC.tNameQ", { ns: "testimonials" })}</h3>
                    <h4>{t("testAC.tDesg", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblEmail", { ns: "testimonials" })}&nbsp;{t("testAC.tEmail", { ns: "testimonials" })}</h4>
                    <h4>{t("header.lblLinkedin", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testAC.tLinkedin", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testAC.tLinkedin", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testAC.tLinkedin", { ns: "testimonials" })}
                        </a>
                      </h7></h4>
                    <h4>{t("header.lblTwitter", { ns: "testimonials" })}&nbsp;
                      <h7>
                        <a
                          target="_Myblank"
                          rel="noopener noreferrer"
                          href={t("testAC.tTwitter", { ns: "testimonials" })}
                          onClick={() =>
                            window.open(
                              t("testAC.tTwitter", { ns: "testimonials" }),
                              "_Myblank",
                              "width=1200, height=950"
                            )
                          }
                        >
                          {t("testAC.tTwitter", { ns: "testimonials" })}
                        </a>
                      </h7>
                    </h4>
                    <p>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {t("testAC.tText", { ns: "testimonials" })}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                  </div>
                </div>

                {/* <div className="swiper-slide">
                <div className="testimonial-item">
                  <img
                    src="assets/img/testimonials/testimonials-5.jpg"
                    className="testimonial-img"
                    alt=""
                  />
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Quis quorum aliqua sint quem legam fore sunt eram irure
                    aliqua veniam tempor noster veniam enim culpa labore duis
                    sunt culpa nulla illum cillum fugiat legam esse veniam culpa
                    fore nisi cillum quid.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div> */}
              </div>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </section>
      </>
    </Suspense>
  );
};

export default Testimonials;
