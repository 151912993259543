import React from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//import { MyDatePicker } from "@mui/x-date-pickers/DatePicker";
//import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from "@material-ui/pickers";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker
// } from "@mui/x-date-pickers";

//import DateFnsUtils from "@date-io/date-fns";

export default function DatePicker(props) {
  // const { name, label, value, onChange } = props;

  // const convertToDefEventPara = (name, value) => ({
  //   target: {
  //     name,
  //     value
  //   }
  // });

  return (
    // <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //   <KeyboardDatePicker
    //     disableToolbar
    //     variant="inline"
    //     inputVariant="outlined"
    //     label={label}
    //     format="MMM/dd/yyyy"
    //     name={name}
    //     value={value}
    //     onChange={(date) => onChange(convertToDefEventPara(name, date))}
    //   />
    // </MuiPickersUtilsProvider>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={["DatePicker", "TimePicker", "DateTimePicker"]}
      >
        <DemoItem>
          <DateTimePicker
            selected={new Date()}
            showTimeSelect
            //defaultValue={dayjs("2023-04-07T07:08:43.000+00:00")}
            //format="DD-MM-YYYY HH:MM"
            defaultValue={dayjs(new Date().toLocaleString())}
            //views={["year", "day", "hours", "minutes", "seconds"]}
            //readOnly
          />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
