import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import CRExplained from "./CRExplained";
import CRHome from "./CRHome";
import KYHHealth from "./KYHHealth";
import SOHAilment from "./SOHAilment";

// loading component for suspense fallback
const Loader = () => (
  <div>
    <b>Please Wait something is still loading...</b>
  </div>
);

const WhatWeDo = () => {
  const { t } = useTranslation(["translation", "wwdo"]);

  return (
    <Suspense fallback={<Loader />}>
      <>
        <section id="wwd" className="faq section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>{t("header.h2", { ns: "wwdo" })}</h2>
            </div>

            <div className="accordion-list">
              <ul line-height="30px">

                <li data-aos="fade-up">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    className="collapsed"
                    data-bs-target="#accordion-list-1"
                    href="/Home"
                  >
                    {t("dlink.d1", { ns: "wwdo" })}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-1"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {<CRExplained />}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="100">
                  <i className="bx bx-help-circle icon-help"></i>
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-2"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("dlink.d2", { ns: "wwdo" })} <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-2"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {<CRHome />}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="200">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-3"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("dlink.d3", { ns: "wwdo" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-3"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {
                        <KYHHealth />
                      }
                    </p>
                  </div>
                </li>

                <li data-aos="fade-up" data-aos-delay="300">
                  <i className="bx bx-help-circle icon-help"></i>{" "}
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target="#accordion-list-4"
                    className="collapsed"
                    href="/Home"
                  >
                    {t("dlink.d4", { ns: "wwdo" })}{" "}
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                  <div
                    id="accordion-list-4"
                    className="collapse"
                    data-bs-parent=".accordion-list"
                  >
                    <p>
                      {
                        <SOHAilment />
                      }
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </>
    </Suspense>
  );
};

export default WhatWeDo;
